import React, { useState } from "react";
import "../scss/components/ForgotPassword.scss";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [validate, setValidate] = useState({});
  const { t } = useTranslation();
  const validateforgotPassword = () => {
    let isValid = true;
    let validator = Form.validator({
      email: {
        value: email,
        isRequired: true,
        isEmail: true,
      },
    });
    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });
      isValid = false;
    }
    return isValid;
  };

  const forgot = (e) => {
    e.preventDefault();
    const validate = validateforgotPassword();
    if (validate) {
      alert("Reset password link is sent to " + email);
      setValidate({});
      setEmail("");
    }
  };
  return (
    <div className="forgotpasswordcontainer padding-bottom-3x mb-2 mt-5">
      <div className="ForgotContainer">
        <div className="col-lg-8 col-md-10">
          <form className="card mt-4" method="POST" onSubmit={forgot}>
            <div className="card-body">
              <div className="form-group">
                <h2>{t("FORGOTPASSWORD.Portal")} </h2>
                <h4>{t("FORGOTPASSWORD.Title")}</h4>
                <h3>{t("FORGOTPASSWORD.Explanation")}</h3> <br></br>
                <h3>{t("FORGOTPASSWORD.Email")}</h3>
                <input
                  className={`form-control ${
                    validate.validate && validate.validate.email
                      ? "is-invalid "
                      : ""
                  }`}
                  id="email"
                  name="email"
                  value={email}
                  placeholder=""
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div
                  className={`invalid-feedback text-start ${
                    validate.validate && validate.validate.email
                      ? "d-block"
                      : "d-none"
                  }`}
                >
                  {validate.validate && validate.validate.email
                    ? validate.validate.email[0]
                    : ""}
                </div>
              </div>
            </div>
            <div className="card-footer">
              <Button type="">{t("BTN__RESET")}</Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
