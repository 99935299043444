export const setFilter = (fid, fvalue, tableid) => {
    return {
        type: 'filters/setFilter',
        payload: {
            id: fid, 
            value: fvalue,
            table: tableid
        }
    }
}

export const removeFilter = (fid, fvalue, tableid) => {
    return {
        type: 'filters/removeFilter',
        payload: {
            id: fid, 
            value: fvalue,
            table: tableid
        }
    }
}