import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthHandler } from '../utils/AuthHandler';
import { Container, Row, Col/*, Form, Button*/ } from 'react-bootstrap';

import person from './../assets/AdminPage/person_img.svg';
import double_person from './../assets/AdminPage/double_person.svg';
import apartment from './../assets/AdminPage/apartment.svg';
import pricebook from '../assets/AdminPage/pricebook.svg'
import settings from '../assets/AdminPage/settings.svg'

import '../scss/components/Admin.scss';

const Admin = () => {
  const { t } = useTranslation();
  const userData = AuthHandler.getUserData();

  return (
    <Container fluid>
      <Row className='mb-5'>
        <h2 className="Admin_Title">{t('ADMIN.MainHeading')}</h2>
      </Row>
      {
        userData.role[0].toLowerCase() === 'admin' && (
          <Row className="mb-5">
            <Col md={4}>
              <Link to="/admin/managecompany" className="Link__delete_underline">
                <div className="AdminCard py-5">
                  <h2 className="title py-3">{t('ADMIN.ManageCompany')}</h2>
                  <img src={apartment} alt="Company" className="image py-3" />
                  <p className="description py-3">{t('ADMIN.ManageCompany_description')}</p>
                </div>
              </Link>
            </Col>
            <Col md={4}>
              <Link to="/admin/managepricebooks" className="Link__delete_underline">
                <div className="AdminCard py-5">
                    <h2 className="title py-3">{t('ADMIN.ManagePricebook')}</h2>
                      <img src={pricebook} alt="Pricebook" className="image py-3" />
                    <p className="description py-3">{t('ADMIN.ManagePricebook_description')}</p>
                </div>
              </Link>
            </Col>
            <Col md={4}>
              <Link to="/admin/configuration" className="Link__delete_underline">
                <div className="AdminCard py-5">
                    <h2 className="title py-3">Portal Configuration</h2>
                      <img src={settings} alt="Settings" className="image py-3" />
                    <p className="description py-3">Manage the portal configurations.</p>
                </div>
              </Link>
            </Col>
          </Row>
        )
      }
      <Row className='mb-5'>
        <Col md={6}>
          <Link to="/admin/newuser" className="Link__delete_underline">
            <div className="AdminCard py-5">
              <h2 className="title py-3">{t('ADMIN.AddMember')}</h2>
              <img src={person} alt="Person" className="image py-3" />
              <p className="description py-3">{t('ADMIN.AddMember_description')}</p>
            </div>
          </Link>
        </Col>
        <Col md={6}>
          <Link to="/admin/teammembers" className="Link__delete_underline">
            <div className="AdminCard py-5">
              <h2 className="title py-3">{t('ADMIN.ManageTeam')}</h2>
              <img src={double_person} alt="Team" className="image py-3" />
              <p className="description py-3">{t('ADMIN.ManageTeam_description')}</p>
            </div>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Admin;
