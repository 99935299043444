import React, { Fragment } from 'react';
import '../scss/views/Login.scss';
import Login from '../components/Login';
import { useTranslation } from 'react-i18next';
import { Row, Col, Container } from 'react-bootstrap';

// JSX

const LoginContainer = () => {
  const { t } = useTranslation();

  return (
    <Container fluid style={{margin: '0px', padding: '0px'}}>
      <div className="login-container">
        <Row>
          <Col>
            <div className="title">
              <h4>{t('LOGIN.LEFTTEXT.FirstLine')}</h4>
              <h1>{t('LOGIN.LEFTTEXT.SecondLine')}</h1>
              <h3>{t('LOGIN.LEFTTEXT.ThirdLine')}</h3>
            </div>
          </Col>
          <Col>
            <div>
              <Login
                username="Name"
                titlepanel={`${t('LOGIN.Portal')}`}
                passwordlabel={`${t('LOGIN.Password')}`}
                incorrect={`${t('LOGIN.Incorrect')}`}
                res={`${t('LOGIN.Res')}`}
                log={`${t('LOGIN.Log')}`}
                forg={`${t('LOGIN.Forgot_Password')}`}
                NC={`${t('LOGIN.Question_of_Partnership')}`}
                regist={`${t('LOGIN.Register')}`}
              />
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};
export default LoginContainer;
