import React, { useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { RestService } from '../utils';
import ConfigHandler from '../config';
import { Redirect, useLocation, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import '../scss/views/portalConfiguration.scss'
import Utils from '../utils/Utils';

function PortalConfiguration() {
    const location = useLocation();
    const { t } = useTranslation();

    const [validated, setValidated] = useState();


    const HandleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault(); 

        if (form.checkValidity() === false) {
            event.stopPropagation();
        }

        RestService.put({
            url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getConfigs}`,
            body: [
                {key: 'SHOW_PRICEBOOK_PRICE', value: form.showPricebook.value}
            ]
        }).then((response) => response.json().then((data) => {
            if(response.status > 199 && response.status < 300){
                toast.success(t('CONFIGURATIONS.SuccessNotification'));
                Utils.getConfigFromDb();
            } else {
                toast.error(t('NOTIFICATIONS.NotificationSomethingWrong'))
            }
        }))

        
        setValidated(true);
    }

    return (
        <Container fluid className='portalConfigurationWrapper'> 
            <Row className='my-3'>
                <h2 className='fadedText'>{t('CONFIGURATIONS.Title')}</h2>
            </Row>
            <Row>
            <Form noValidate validated={validated} onSubmit={HandleSubmit}>
                    <Row className='mb-3 mx-3 configItem'>
                        <Col className='labelBox'>
                            {t('CONFIGURATIONS.SHOW_PRICEBOOK_PRICE_label')} (<code>SHOW_PRICEBOOK_PRICE</code>)
                        </Col>
                        <Col className='valueBox'>
                            <Form.Select size="lg" id="showPricebook" defaultValue={localStorage.getItem('SHOW_PRICEBOOK_PRICE')}>
                                <option value="true">{t('CONFIGURATIONS.SHOW_PRICEBOOK_PRICE_opt1')}</option>
                                <option value="false">{t('CONFIGURATIONS.SHOW_PRICEBOOK_PRICE_opt2')}</option>
                            </Form.Select>
                        </Col>
                    </Row>
                    
                    <Row className='text-center mt-5'>
                        <Col md={{ span: 4, offset: 4 }}>
                            <button type="submit" className="fadedButton btn">
                                {t('BUTTONS.SAVE')}
                            </button>
                        </Col>
                    </Row>
                </Form>
            </Row>
        </Container>
    )
}

export default PortalConfiguration