import React from 'react';
import RouteWithSubRoutes from '../components/RouteWithSubRoutes';
import { Container, Row, Col } from 'react-bootstrap';
import Sidebar from '../components/Sidebar';
import FooterResponsive from './FooterResponsive';


function Navigator() {
  return (
    <Container fluid>
      <Row className='p-2'>
        <Col className="navSidebar" md={2}>
          <Sidebar />
        </Col>
        <Col className="componentsContainer" md={12} lg={10} sm={12}>
          <div className="componentsPage" >
            <RouteWithSubRoutes />
          </div>
          <FooterResponsive />
        </Col>
      </Row>
    </Container>
  );
}

export default Navigator;
