import ConfigHandler from '../config';
import { RestService } from '../utils';
import { useTranslation } from "react-i18next";
import { AuthHandler } from './AuthHandler';
import Icon from '@mui/material/Icon'

let delay = null;

const Utils = {

  handleInputSearch: (text) => {
    if (text.length >= ConfigHandler.getConfig().defaults.minInputLength || text.length == 0) {
      return new Promise((resolve, reject) => {
        clearTimeout(delay);
        delay = setTimeout(function () {
          resolve(text);
        }, ConfigHandler.getConfig().defaults.inputDelay);
      });
    } else {
      clearTimeout(delay);
    }
  },

  checkVat: (code, number) => {
    return RestService.get({
      url: `${ConfigHandler.getConfig().api.baseUrl}${
        ConfigHandler.getConfig().api.path.checkVat
      }?country=${code}&number=${number}`
    })
    .then((response) => response.json()
    .then((data) => {
        if (data.Body.checkVatResponse) {
          if (data.Body.checkVatResponse.valid === 'true') {
            return true;
          } else if (data.Body.checkVatResponse.valid === 'false') {
            return false;
          }
        } else {
          return false;
        }
    }));
  },

  localizeDate: (value) => {
    var date = new Date(`${value} UTC`)
    var localeDate = date.toLocaleString();

    if(localeDate == 'Invalid Date')
      return null;
    else return localeDate;
  },

  QuoteStatus: (value) => {
    const { t } = useTranslation();

    switch (value) {
      case 0:
        return (
          <span className="fadedText" style={{ fontSize: '14px' }}>
            <Icon
              style={{
                fontSize: '18px',
                display: 'inline-block',
                verticalAlign: 'middle'
              }}>
              donut_small
            </Icon>{' '}
            {t('QuoteStatus.Draft')}
          </span>
        );
      case 1:
        return (
          <span style={{ fontSize: '14px', color: 'darkgreen' }}>
            <Icon
              style={{
                fontSize: '18px',
                display: 'inline-block',
                verticalAlign: 'middle'
              }}>
              add_task
            </Icon>{' '}
            {t('QuoteStatus.Submitted')}
          </span>
        );
      case 2:
        return (
          <span style={{ fontSize: '14px', color: 'orange' }}>
            <Icon
              style={{
                fontSize: '18px',
                display: 'inline-block',
                verticalAlign: 'middle'
              }}>
              rotate_right
            </Icon>{' '}
            {t('QuoteStatus.Processing')}
          </span>
        );
      case 3:
        return (
          <span style={{ fontSize: '14px', color: '#0CA6F5' }}>
            <Icon
              style={{
                fontSize: '18px',
                display: 'inline-block',
                verticalAlign: 'middle'
              }}>
              error
            </Icon>{' '}
            {t('QuoteStatus.OnHold')}
          </span>
        );
      case 4:
        return (
          <span style={{ fontSize: '14px', color: 'green' }}>
            <Icon
              style={{
                fontSize: '18px',
                display: 'inline-block',
                verticalAlign: 'middle'
              }}>
              check_circle
            </Icon>{' '}
            {t('QuoteStatus.Accepted')}
          </span>
        );
      case 5:
        return (
          <span style={{ fontSize: '14px', color: 'red' }}>
            <Icon
              style={{
                fontSize: '18px',
                display: 'inline-block',
                verticalAlign: 'middle'
              }}>
              remove_circle
            </Icon>{' '}
            {t('QuoteStatus.Rejected')}
          </span>
        );
        case 6:
        return (
          <span style={{ fontSize: '14px', color: '#f55b0c' }}>
            <Icon
              style={{
                fontSize: '18px',
                display: 'inline-block',
                verticalAlign: 'middle'
              }}>
              error
            </Icon>{' '}
            {t('QuoteStatus.Cancelled')}
          </span>
        );
      case 8:
        return (
          <span style={{ fontSize: '14px', color: 'blue' }}>
            <Icon
              style={{
                fontSize: '18px',
                display: 'inline-block',
                verticalAlign: 'middle'
              }}>
              swap_vert
            </Icon>{' '}
            {t('QuoteStatus.Provisioning')}
          </span>
      );
      default:
        return '';
        break;
    }
  }, 

  getConfigFromDb: () => {
    if(AuthHandler.isAuth()){
      RestService.get({
        url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getConfigs}`,
      }).then((response) => response.json().then((data) => {
        var configs = data;

        data.map((key) => {
          localStorage.setItem(key.key, key.value)
        })
      }))
    }
  }



};

export default Utils;
