import React, { useState, useMemo/*, useCallback */} from 'react';
import '../scss/components/DataTables.scss';
import { Container, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DataTable from '../components/DataTable';
import { useSelector, useDispatch } from 'react-redux';
import { AuthHandler } from '../utils/AuthHandler';
import { RestService } from '../utils';
import ConfigHandler from '../config';
import Icon from '@mui/material/Icon';
import Utils from '../utils/Utils';
import { Link } from 'react-router-dom';
import { t } from 'i18next';

function TeamMembers() {
  /*const state = */useSelector((state) => state);

  //Needed Variables
  const [listData, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [sortDirection, setSortDir] = useState(false);
  const [sortVal, setSortVal] = useState('id:desc');
  const [numofData, setnumofData] = useState(0);
  const [sortIcon, setSortIcon] = useState('id');
  //let dataReturn = [];

  //Search Functionalities
  const [inputText, setInputText] = useState('');
  /*const dispatch =*/ useDispatch();

  /* Fetch new table data when needed. A function that is called when the page is changed. */
  const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
    const startRow = pageSize * pageIndex;
    let dataReturn = [];
    //const endRow = startRow + pageSize;
    dataReturn = RestService.get({
      url: `${ConfigHandler.getConfig().api.baseUrl}${
        ConfigHandler.getConfig().api.path.getUsers
      }?q=${inputText}&offset=${startRow}&limit=${pageSize}&order=${sortVal}`,
    }).then((response) => response.json()
      .then((data) => ({ data: data }))
      .then((res) => {
        dataReturn = res.data.data;
        if (dataReturn !== undefined) {
          setData(dataReturn);
        } else if (dataReturn === undefined) {
          document.getElementsByClassName(`TH-${sortIcon}`)[0].classList.remove('sortedTabAsc', 'sortedTabDesc');
        }
        setnumofData(res.data.total);
        setPageCount(Math.ceil(res.data.total / pageSize));
      })
    );
    /*const userData = */ AuthHandler.getUserData();
  },[inputText, sortIcon, sortVal]);

  //Get user search data
  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    Utils.handleInputSearch(lowerCase).then((text) => {
      setInputText(text);
    });
  };

  /**
   * It removes the class of the previous sort icon, then adds the class of the new sort icon.
   * @param val - the value of the column header that was clicked
   */
  const sortHandler = (val) => {
    document.getElementsByClassName(`TH-${sortIcon}`)[0].classList.remove('sortedTabAsc', 'sortedTabDesc');
    if (sortDirection) {
      setSortVal(`${val}:asc`);
      setSortDir(false);
      document.getElementsByClassName(`TH-${val}`)[0].classList.add('sortedTabAsc');
      setSortIcon(val);
    } else {
      setSortVal(`${val}:desc`);
      setSortDir(true);
      document.getElementsByClassName(`TH-${val}`)[0].classList.add('sortedTabDesc');
      setSortIcon(val);
    }
  };

  //Columns
  const columns = useMemo(
    () => [
      {
        Header: t('FORMS.NameLabel'),
        accessor: 'name',
        Cell: (props) => `${props.row.original.name} ${props.row.original.surname}`,
      },
      {
        Header: t('FORMS.Username'),
        accessor: 'username',
      },
      {
        Header: t('FORMS.CreationDateLabel'),
        accessor: 'dateInserted',
        Cell: ({ value }) => {
          return(
            Utils.localizeDate(value)
          )
        }
      },
      {
        Header: t('FORMS.RoleLabel'),
        accessor: 'role.description',
        sortable: false,
      },
      {
        Header: t('FORMS.StatusLabel'),
        accessor: 'enabled',
        sortable: false,
        Cell: ({ value }) => {
          return (
            <div className="tabIcon">
              <span className={value === false ? 'tabRedDot' : 'tabGreenDot'}>
                <Icon>fiber_manual_record</Icon> {value === false ? t('OTHERS.StatusInactive') : t('OTHERS.StatusActive')}
              </span>
            </div>
          );
        },
      },
      {
        Header: ' ',
        accessor: 'id',
        sortable: false,
        Cell: ({ row }) => {
          return (
            <div className="tabIcon">
              <span onClick={() => {}}>
                <Link
                  to={{
                    pathname: '/admin/newuser',
                    state: { data: row.original },
                  }}>
                    <OverlayTrigger overlay={<Tooltip>{t('TOOLTIPS.Edit')}</Tooltip>}>
                      <Icon>edit_note</Icon>
                    </OverlayTrigger>
                </Link>
              </span>
            </div>
          );
        },
      },
    ],
    [],
  );

  if (listData === '') {
    const data = [
      {
        name: t('OTHERS.NoDataMessage'),
        surname: '',
        username: '',
        dateInserted: '',
        status: null,
      },
    ];

    setData(data);
  }

  if (listData === '')
    return (
      <Container fluid>
        <Row>{t('OTHERS.NoDataMessage')}</Row>
      </Container>
    );

  return (
    <Container fluid>
      <Row className="teammembers">
        {listData.length && (
          <DataTable
            columns={columns}
            data={listData}
            heading={true}
            searchBar={true}
            numofData={numofData}
            tableHeading={t('TEAMMEMBERS.TableHeading')}
            searchMessage={t('OTHERS.Search')}
            filterButton={false}
            downloadButton={false}
            newValueButton={true}
            newValueText={t('TEAMMEMBERS.AddMemberButton')}
            newValueLink="/admin/newuser"
            inputHandler={inputHandler}
            fetchData={fetchData}
            pageCount={pageCount}
            sortHandler={sortHandler}
            sortValue={sortVal}
            inputText={inputText}
            paginationItemsStartingPoint={ConfigHandler.getConfig().datatables.defaultStartingPoint}
            paginationItemsCount={ConfigHandler.getConfig().datatables.defaultPaginationCount}
          />
        )}
      </Row>
    </Container>
  );
}

export default TeamMembers;
