import React from 'react';
import ConfigHandler from '../config';

const BrandCarousel = () => {
  const generationLoop = () => {
    const loop = [];
    for (let i = 0; i < ConfigHandler.getConfig().carousel.blocks.length; i++) {
      var image = require(`../assets/${ConfigHandler.getConfig().carousel.blocks[i].path}.png`);
      loop.push(
        <div className="slide" key={`brandcarousel-${i}`}>
          <a href={ConfigHandler.getConfig().carousel.blocks[i].link}>
            <img src={image} height="" width="" alt="" className={ConfigHandler.getConfig().carousel.blocks[i].path} />
          </a>
        </div>,
      );
    }
    return loop;
  };
  return (
    <div className="slider">
      <div className="slide-track">
        <div className="slide"></div>
        {generationLoop()}
      </div>
    </div>
  );
};

export default BrandCarousel;
