import React from "react";
import "../scss/components/FilterIcons.scss";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";

function FilterIcons({ message }) {
  return (
    <div className="filterButton">
      {message} <DoDisturbOnIcon className="filterIcon" />
    </div>
  );
}

export default FilterIcons;
