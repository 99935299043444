import React, { useState, useMemo, useCallback, useEffect } from 'react';
import '../scss/components/DataTables.scss';
import { Container, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';
import DataTable from '../components/DataTable';
import { useSelector, useDispatch } from 'react-redux';
import { AuthHandler } from '../utils/AuthHandler';
import { RestService } from '../utils';
import ConfigHandler from '../config';
import Icon from '@mui/material/Icon';
import Utils from '../utils/Utils';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function CompanyUsers(companyId) {
  const state = useSelector((state) => state);
  const { t } = useTranslation();

  //Needed Variables
  const [listData, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [sortDirection, setSortDir] = useState(false);
  const [sortVal, setSortVal] = useState('id:desc');
  const [numofData, setnumofData] = useState(0);
  const [sortIcon, setSortIcon] = useState('id');
  let dataReturn = [];

  //Search Functionalities
  const [inputText, setInputText] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    document.getElementsByClassName('tableHeadHR')[0].style.display = 'none';
    document.getElementById('tableTH').style.backgroundColor = '#efefef';
  }, []);

  //Fetch new table data when needed
  const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
    const startRow = pageSize * pageIndex;
    const endRow = startRow + pageSize;
    dataReturn = RestService.get({
      url: `${ConfigHandler.getConfig().api.baseUrl}${
        ConfigHandler.getConfig().api.path.getUsers
      }?q=${inputText}&offset=${startRow}&limit=${pageSize}&order=${sortVal}&partner=${companyId.companyId}`,
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
        }))
        .then((res) => {
          dataReturn = res.data.data;
          if (dataReturn != undefined) {
            setData(dataReturn);
          } else if (dataReturn === undefined) {
            document.getElementsByClassName(`TH-${sortIcon}`)[0].classList.remove('sortedTabAsc', 'sortedTabDesc');
          }
          setnumofData(res.data.total);
          setPageCount(Math.ceil(res.data.total / pageSize));
        }),
    );
    const userData = AuthHandler.getUserData();
  });

  //Get user search data
  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    Utils.handleInputSearch(lowerCase).then((text) => {
      setInputText(text);
    });
  };

  //Handle sorting
  const sortHandler = (val) => {
    document.getElementsByClassName(`TH-${sortIcon}`)[0].classList.remove('sortedTabAsc', 'sortedTabDesc');
    if (sortDirection) {
      setSortVal(`${val}:asc`);
      setSortDir(false);
      document.getElementsByClassName(`TH-${val}`)[0].classList.add('sortedTabAsc');
      setSortIcon(val);
    } else {
      setSortVal(`${val}:desc`);
      setSortDir(true);
      document.getElementsByClassName(`TH-${val}`)[0].classList.add('sortedTabDesc');
      setSortIcon(val);
    }
  };

  //Columns
  const columns = useMemo(
    () => [
      {
        Header: `${t('USERSCOMPANY.TH_Name')}`,
        accessor: 'name',
        Cell: (props) => `${props.row.original.name} ${props.row.original.surname}`,
      },
      {
        Header: `${t('USERSCOMPANY.TH_Username')}`,
        accessor: 'username',
      },
      {
        Header: `${t('USERSCOMPANY.TH_Role')}`,
        accessor: 'role.description',
        sortable: false,
      },
      {
        Header: `${t('USERSCOMPANY.TH_Status')}`,
        accessor: 'enabled',
        sortable: false,
        Cell: ({ value }) => {
          return (
            value != null && (
              <div className="tabIcon">
                <span className={value === false ? 'tabRedDot' : 'tabGreenDot'}>
                  <Icon>fiber_manual_record</Icon> {value === false ? t('OTHERS.StatusInactive') : t('OTHERS.StatusActive')}
                </span>
              </div>
            )
          );
        },
      },
      {
        Header: ' ',
        accessor: 'id',
        sortable: false,
        Cell: ({ row }) => {
          return (
            row.original.enabled != null && (
              <div className="tabIcon">
                <span onClick={() => {}}>
                  <Link
                    to={{
                      pathname: '/admin/newuser',
                      state: { data: row.original },
                    }}>
                    <OverlayTrigger overlay={<Tooltip>{t('TOOLTIPS.Edit')}</Tooltip>}>
                      <Icon>edit_note</Icon>
                    </OverlayTrigger>
                  </Link>
                </span>
              </div>
            )
          );
        },
      },
    ],
    [],
  );

  if (listData == '') {
    const data = [
      {
        name: t('OTHERS.NoDataMessage'),
        surname: '',
        email: null,
        role: null,
        status: null,
      },
    ];

    setData(data);
  }

  return (
    <Container fluid>
      <Row className="teammembers">
        {listData.length && (
          <DataTable
            columns={columns}
            data={listData}
            heading={true}
            searchBar={false}
            numofData={numofData}
            tableHeading={t('USERSCOMPANY.TH_Heading')}
            searchMessage={t('OTHERS.Search')}
            filterButton={false}
            downloadButton={false}
            newValueButton={true}
            newValueText={t('USERSCOMPANY.AddValueButton')}
            newValueLink="/admin/newuser"
            inputHandler={inputHandler}
            fetchData={fetchData}
            pageCount={pageCount}
            sortHandler={sortHandler}
            sortValue={sortVal}
            inputText={inputText}
            paginationItemsStartingPoint={ConfigHandler.getConfig().datatables.defaultStartingPoint}
            paginationItemsCount={ConfigHandler.getConfig().datatables.defaultPaginationCount}
          />
        )}
      </Row>
    </Container>
  );
}

export default CompanyUsers;
