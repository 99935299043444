import { useTable, usePagination, useLayoutEffect } from 'react-table';
import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Button, Row, Col, Form, InputGroup } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import listenForOutsideClick from './listen-for-outside-clicks';
import { setFilter, removeFilter } from '../store/actions/filters';
import { Link } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import '../scss/components/DataTables.scss';
//icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import FilterIcons from '../components/FilterIcons';

 /*
  * This file is the template of the datatable. By modifying this file you will modify all tables of the portal.
  */

function DataTable({
  columns,
  data,
  heading,
  numofData,
  searchMessage,
  searchBar,
  filterButton,
  downloadButton,
  newValueButton,
  inputHandler,
  tableHeading,
  newValueText,
  newValueLink,
  fetchData,
  sortValue,
  sortHandler,
  inputText,
  customTrigger, 
  paginationItemsStartingPoint,
  paginationItemsCount,
  pageCount: controlledPageCount,
}) {
  //State to Show or Hide filters dropdown
  const [show, setShow] = useState(false);

  //States for Outside of Div click of filters
  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  useEffect(listenForOutsideClick(listening, setListening, menuRef, setShow));

  //Initialize store variables
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  //Handle the filters Checkbox
  function handleCheckbox(value, e) {
    if (e.target.checked) {
      dispatch(setFilter(value.id, value.value, tableHeading));
    } else if (!e.target.checked) {
      dispatch(removeFilter(value.id, value.value, tableHeading));
    }
  }

  //Remove filters from filter X icon
  function remFilt(filtID, filtVal) {
    dispatch(removeFilter(filtID, filtVal));
  }

  //Set checkbox to checked if already selected
  function isFilterChecked(val) {
    const indexFilt = state.filters.map((object) => object.value).indexOf(val);

    if (indexFilt > -1) {
      if (state.filters[indexFilt].table == tableHeading) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  //Download Button
  const downloadTxtFile = () => {
    const element = document.createElement('a');
    const file = new Blob([JSON.stringify(data)], {
      type: 'text/plain',
    });
    element.href = URL.createObjectURL(file);
    element.download = 'myFile.txt';
    document.body.appendChild(element);
    element.click();
  };

  //Functions for HR under Table Header
  function handleResize() {
    if(document.getElementById('tableTH')){
      var currentSize = document.getElementById('tableTH').clientHeight; 
      document.getElementById('tableHeadHR').style.marginTop = `${currentSize}px`;
    }
  }

  window.addEventListener('resize', handleResize)

  //Initializing the table
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination,
  );

  //Function to generate Pagination
  const generatePagination = () => {
    return (
      <Fragment>
        <button
          key={`pgn-${pageIndex - 1}`}
          onClick={() => gotoPage(pageIndex - 1)}
          disabled={!canPreviousPage}
          className="paginationTransparentButton">
          {' '}
          <ChevronLeftIcon />{' '}
        </button>

        {pageIndex > 1 ? (
          <span>
            <button
              key={`pgn-0`}
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              className="paginationTransparentButton">
              {' '}
              1{' '}
            </button>
            <button key={`pgn-dots`} className="paginationTransparentButton">
              {' '}
              ...{' '}
            </button>
          </span>
        ) : null}

        {generateButtons()}

        {pageCount >= pageIndex + 3 ? (
          <span>
            <button key={`pgn-dots`} className="paginationTransparentButton">
              {' '}
              ...{' '}
            </button>
            <button
              key={`pgn-${pageCount - 1}`}
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              className="paginationTransparentButton">
              {' '}
              {pageCount}{' '}
            </button>
          </span>
        ) : null}
        <button
          key={`pgn-next`}
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="paginationTransparentButton">
          {' '}
          <ChevronRightIcon />{' '}
        </button>
      </Fragment>
    );
  };

  //Dynamic paginations middle position
  const generateButtons = () => {
    var buttons = [];
    const paIn = pageIndex;

    for (var pIndex = paginationItemsStartingPoint; pIndex < paginationItemsCount; pIndex++) {
      const pagePosition = paIn + pIndex;
      if (pagePosition + 1 > 0 && pagePosition + 1 <= pageCount) {
        buttons.push(
          <button
            key={`pgn-${pagePosition}`}
            onClick={() => gotoPage(pagePosition)}
            className={
              pagePosition == pageIndex ? 'active paginationTransparentButton' : 'paginationTransparentButton'
            }>
            {' '}
            {pagePosition + 1}
          </button>,
        );
      }
    }

    return buttons;
  };

  //Generate Heading of tables
  const generateHeading = () => {
    if (heading) {
      return (
        <Fragment>
          <Row className="headingRow">
            <Col md={3} className="text-left">
              <h2 className="fadedText">{tableHeading}</h2>
            </Col>
            {searchBar ? (
              <Col md={4}>
                <InputGroup>
                  <InputGroup.Text className="searchIcon">
                    <SearchIcon />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder={searchMessage}
                    type="text"
                    className="head_searchBar"
                    name="tab_searchBar"
                    onChange={(e) => { inputHandler(e); gotoPage(0); }}
                  />
                </InputGroup>
              </Col>
            ) : null}
            <Col>
              <Row className="item-right">
                {filterButton ? (
                  <Col md="auto" ref={menuRef}>
                    <Button variant="" className="transparentButton" onClick={() => setShow(!show)}>
                      <FilterListIcon /> Filters <ArrowDropUpIcon className={show ? 'flipIcon' : null} />
                    </Button>
                    {show ? (
                      <div className="FilterDropdownBox">
                        <ul>
                          <li>
                            <h4 className="filterHead">Service</h4>
                          </li>
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                id="1"
                                name="test1"
                                value="LAN"
                                onChange={(e) => handleCheckbox(e.target, e)}
                                checked={isFilterChecked('LAN')}
                              />{' '}
                              <span className="filterOptions">LAN</span>
                            </label>
                          </li>
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                id="2"
                                name="test2"
                                value="WLAN"
                                onChange={(e) => handleCheckbox(e.target, e)}
                                checked={isFilterChecked('WLAN')}
                              />{' '}
                              <span className="filterOptions">WLAN</span>
                            </label>
                          </li>
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                id="3"
                                name="test3"
                                value="Fiber"
                                onChange={(e) => handleCheckbox(e.target, e)}
                                checked={isFilterChecked('Fiber')}
                              />{' '}
                              <span className="filterOptions">FIBER</span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    ) : null}
                  </Col>
                ) : null}
                {downloadButton ? (
                  <Col md="auto">
                    <Button
                      variant=""
                      className="transparentButton"
                      onClick={() => {
                        downloadTxtFile();
                      }}>
                      <FileDownloadOutlinedIcon /> Download
                    </Button>
                  </Col>
                ) : null}
                {newValueButton ? (
                  <Col md="auto">
                    <Link to={newValueLink}>
                      <Button variant="" className="fadedButton">
                        <AddIcon /> {newValueText}
                      </Button>
                    </Link>
                  </Col>
                ) : null}
              </Row>
            </Col>
          </Row>
          {filterButton ? (
            <Row className="filtersRow">
              <Col>
                {state.filters.map(function (item, i) {
                  if (item.table === tableHeading) {
                    return (
                      <span
                        onClick={() => {
                          remFilt(item.id, item.value);
                        }}>
                        {' '}
                        <FilterIcons message={item.value} />{' '}
                      </span>
                    );
                  }
                })}
              </Col>
            </Row>
          ) : null}
        </Fragment>
      );
    }
  };

  useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [pageIndex, pageSize, sortValue, inputText, customTrigger]);

  const startRow = pageSize * pageIndex;
  const endRow = startRow + pageSize;

  return (
    <div>
      {generateHeading()}
      <Row>
        <Col>
          <hr
            className="tableHeadHR"
            id="tableHeadHR"
            style={{
              marginTop: document.getElementById('tableTH') ? document.getElementById('tableTH').clientHeight : 0,
            }}
          />
          <Table {...getTableProps()} className="DataTables">
            <Thead>
              <Tr id="tableTH">
                {headerGroups.map((headerGroup) =>
                  headerGroup.headers.map((column) => (
                    <Th
                      className={`TH-${column.id}`}
                      {...column.getHeaderProps()}
                      onClick={() => (column.sortable != false ? sortHandler(column.id) : null)}
                      style={column.customWidth ? {width: column.customWidth} : null}
                    >
                      {column.render('Header')}
                    </Th>
                  )),
                )}
              </Tr>
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>;
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Col>
      </Row>

      <Row className="paginationRow d-flex align-items-center">
        <Col md={8}>
          <p>
            Rows per page:{' '}
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}>
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </p>
        </Col>
        <Col md={4}>
          <Row className="centeredcontent">
            <Col md="auto">
              <p className="text-right">
                {startRow}-{endRow > numofData ? numofData : endRow} of {numofData}
              </p>
            </Col>
            <Col md="auto">{generatePagination()}</Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default DataTable;
