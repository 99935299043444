import { Button, Modal, Alert  } from 'react-bootstrap';
import React, { useState } from 'react';
import '../scss/components/Modal.scss';

function Modale({ titleContent, bodyContent, vatValid, vatMessage }) {
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(true);

  function handleShow() {
    setShow(true);
  }

  return (
    <>
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header className="Modal__header" closeButton></Modal.Header>
        <Modal.Body className="Modal__body">
          <p className="h3 Modal__title">{titleContent}</p>
          <div className="Modal__body_content p-3 text-center">{bodyContent}</div>

          {
            (!vatValid)&&(
              <Alert key="vatMessage" variant="warning" style={{width: '60%', textAlign: 'center'}}>
                {vatMessage}
              </Alert>
            )
          }

          <center>
            <p>
              <a href="https://www.fibertelecom.com/" className="fadedText">
                www.fibertelecom.com
              </a>
            </p>
          </center>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Modale;
