import React, { useEffect, useState } from 'react';
import { Container, Stack, Row, Col, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { RestService } from '../utils';
import ConfigHandler from '../config';
import { useTranslation } from 'react-i18next';
import Icon from '@mui/material/Icon';
import { Link } from 'react-router-dom';

import '../scss/views/managepricebooks.scss'


function ManagePricebooks() {
    const { t } = useTranslation();


    const downloadPricebook = () => {
        RestService.get({
            url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.pricebookDownload}`
            })
            .then((response) => {
                return response.blob();
            })
            .then((res) => {
                const blobURL = window.URL.createObjectURL(res);

                var today = new Date().toISOString().slice(0, 10);
                const tempLink = document.createElement('a');
                    tempLink.style.display = 'none';
                    tempLink.href = blobURL;
                    tempLink.setAttribute('download', `pricebook_${today}.csv`);


                if (typeof tempLink.download === 'undefined') {
                    tempLink.setAttribute('target', '_blank');
                }

                tempLink.click();

                document.body.appendChild(tempLink);
                document.body.removeChild(tempLink);

                setTimeout(() => {
                    window.URL.revokeObjectURL(blobURL);
                }, 100);
            });
    };


  return (
    <Container fluid>
        <Row className="mb-5">
            <Col md={6}>
                <h2 className='fadedText'>{t('ADMIN.ManagePricebook')}</h2>
            </Col>
            <Col md={6} className='text-right'>
            </Col>
        </Row>
        <Row className='mt-5 mb-3'>
            <Col md={6}>
                <div className="PricebookCard py-5">
                    <h2 className="title py-3">{t('PRICEBOOK.VIEWTABLE.title')}</h2>
                    <Icon className='icon'>format_list_bulleted</Icon>
                    <p className="description py-3">{t('PRICEBOOK.VIEWTABLE.description')}</p>
                    <Link to="/admin/pricebooktable">
                        <button className='fadedButton btn'>{t('PRICEBOOK.VIEWTABLE.button')}</button>
                    </Link>
                </div>
            </Col>
            <Col md={6}>
                <div className="PricebookCard py-5">
                <h2 className="title py-3">{t('PRICEBOOK.VIEWHISTORY.title')}</h2>
                <Icon className='icon'>history</Icon>
                <p className="description py-3">{t('PRICEBOOK.VIEWHISTORY.description')}</p>
                    <Link to="/admin/pricebookhistory">
                        <button className='fadedButton btn'>{t('PRICEBOOK.VIEWHISTORY.button')}</button>
                    </Link>
                </div>
            </Col>
        </Row>
        <Row className='mb-5'>
            <Col md={6}>
                <div className="PricebookCard py-5">
                <h2 className="title py-3">{t('PRICEBOOK.DownloadHeading')}</h2>
                    <Icon className='icon'>download</Icon>
                <p className="description py-3">{t('PRICEBOOK.DownloadMessage')}</p>
                <button className='fadedButton btn'  onClick={() => downloadPricebook()}>{t('PRICEBOOK.DownloadButton')}</button>
                </div>
            </Col>
            <Col md={6}>
                <div className="PricebookCard py-5">
                <h2 className="title py-3">{t('PRICEBOOK.UploadHeading')}</h2>
                <Icon className='icon'>upload</Icon>
                <p className="description py-3">{t('PRICEBOOK.UploadMessage')}</p>
                    <Link to={{
                        pathname: '/admin/pricebookupload',
                        state: { uploadPartnerPricebook: null }
                      }}>
                        <button className='fadedButton btn'>{t('PRICEBOOK.UploadButton')}</button>
                    </Link>
                </div>
            </Col>
        </Row>
    </Container>
  )
}

export default ManagePricebooks