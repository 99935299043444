import React, { useState, useEffect } from 'react';
import '../scss/components/DataTables.scss';
import { Container, Row, Col, InputGroup, Form, Button } from 'react-bootstrap';
import DataTable from '../components/DataTable';
import { useSelector, useDispatch } from 'react-redux';
import { AuthHandler } from '../utils/AuthHandler';
import { RestService } from '../utils';
import ConfigHandler from '../config';
import { useTranslation } from 'react-i18next';
import Icon from '@mui/material/Icon';
import Utils from '../utils/Utils';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';

import SearchIcon from '@mui/icons-material/Search';
import { toast } from 'react-toastify';

function PricebookTable() {
  const state = useSelector((state) => state);
  const { t } = useTranslation();

  const [ selectedPartner, setSelectedPartner ] = useState(null);

  //Needed Variables
  const [listData, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [sortDirection, setSortDir] = useState(false);
  const [sortVal, setSortVal] = useState('portalCode:asc');
  const [numofData, setnumofData] = useState(0);
  let dataReturn = [];

  //Search Functionalities
  const [inputText, setInputText] = useState('');
  const dispatch = useDispatch();

  //Fetch new table data when needed
  const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
    const startRow = pageSize * pageIndex;
    const endRow = startRow + pageSize;
    dataReturn = RestService.get(
      selectedPartner != null 
        ? {url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.pricebokHistoryTable}?q=${inputText}&offset=${startRow}&limit=${pageSize}&order=${sortVal}&partnerId=${selectedPartner.id}`}
        : {url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.pricebokHistoryTable}?q=${inputText}&offset=${startRow}&limit=${pageSize}&order=${sortVal}`} 
    ).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
        }))
        .then((res) => {
          dataReturn = res.data.data;
          if (dataReturn != undefined) {
            setData(dataReturn);
          }
          setnumofData(res.data.total);
          setPageCount(Math.ceil(res.data.total / pageSize));
        }),
    );

    const userData = AuthHandler.getUserData();
  });

  //Get user search data
  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    Utils.handleInputSearch(lowerCase).then((text) => {
      setInputText(text);
    });
  };

  //Handle sorting
  const sortHandler = (val) => {
    if (sortDirection) {
      setSortVal(`${val}:asc`);
      setSortDir(false);
    } else {
      setSortVal(`${val}:desc`);
      setSortDir(true);
    }
  };

    //Custom Styles for React-Select
    const customStyles = {
      menu: (provided, state) => ({
        ...provided,
        border: '1px solid  rgb(168, 168, 168)',
        alignText: 'left !important',
      }),
      control: (base, state) => ({
        ...base,
        boxShadow: 'none',
        border: state.isFocused && 'none',
        '&:focus-within': {
          border: '1px solid #ed3a7f',
          boxShadow: '0 0 0 3px rgba(255, 211, 198, 0.38)',
        },
        '&:invalid': {
          border: '1px solid green',
        },
      }),
    };

    const loadPartners = (inputValue, callback) => {
        return RestService.get({url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getPartners}?q=${inputValue}&offset=0&limit=50`,})
          .then((response) => response.json()
          .then((data) => ({data}))
          .then((res) => { 
            callback(res.data.data); 
            return res.data.data; 
          }));
    };

    const handlePartner = (value) => {
      setSelectedPartner(value);
    };
  

  //Columns
  const columns = 
    [
        {
          Header: t('PRICEBOOK.TABLEHEADS.provider'),
          accessor: 'provider',
          customWidth: '101px',
          sortable: false,
          Cell: (props) => {
              if(props.row.original.provider != null){
                  return (
                  <div>
                      <img src={`${ConfigHandler.getConfig().api.baseUrl}/provider/image?name=${props.row.original.provider}`} className='img-fluid' alt="value"/>
                  </div>
                  )
              } else {
                if(props.row.original.id != 'No data found'){
                  return "N/A"
                } else {
                  return ''
                }
              }
          }
        },
        {
          Header: t('PRICEBOOK.TABLEHEADS.id'),
          accessor: 'id',
          sortable: false
        },
        {
            Header: t('PRICEBOOK.TABLEHEADS.portalCode'),
            accessor: 'portalCode',
            sortable: false
        },
        {
            Header: t('PRICEBOOK.TABLEHEADS.code'),
            accessor: 'code',
            sortable: false,
            Cell: (props) => {
                if(props.row.original.code != null){
                    return props.row.original.code
                } else {
                  if(props.row.original.id != 'No data found'){
                    return '-'
                  } else {
                    return ''
                  }
                }
            }
        },
        {
            Header: t('PRICEBOOK.TABLEHEADS.description'),
            accessor: 'description',
            customWidth: '20%',
            sortable: false
        },
        {
            Header: t('PRICEBOOK.TABLEHEADS.sapNrcCode'),
            accessor: 'sapNrcCode',
            sortable: false
        },
        {
            Header: t('PRICEBOOK.TABLEHEADS.nrc'),
            accessor: 'nrc',
            customWidth: '100px',
            sortable: false,
            Cell: (props) => {
                if(props.row.original.nrc != null){
                    return props.row.original.nrc.toLocaleString('it-IT', {
                        style: 'currency', 
                        currency: 'EUR', 
                        minimumFractionDigits: 2 
                    });
                } else {
                    return ""
                }
            }
        },
        {
            Header: t('PRICEBOOK.TABLEHEADS.mrc'),
            accessor: 'mrc',
            customWidth: '100px',
            sortable: false,
            Cell: (props) => {
                if(props.row.original.mrc != null){
                    return props.row.original.mrc.toLocaleString('it-IT', {
                        style: 'currency', 
                        currency: 'EUR', 
                        minimumFractionDigits: 2 
                    });
                } else {
                    return ""
                }
            }
        },
        {
            Header: t('PRICEBOOK.TABLEHEADS.dateFrom'),
            accessor: 'dateFrom',
            sortable: false,
            Cell: (props) => {
                return Utils.localizeDate(props.row.original.dateFrom);
            }
        },
        {
          Header: t('PRICEBOOK.TABLEHEADS.dateTo'),
          accessor: 'dateTo',
          sortable: false,
          Cell: (props) => {
              return Utils.localizeDate(props.row.original.dateTo);
          }
      },
    ]

  if(listData.length < 0 || listData == ''){
    var data = [
      {
        id: t('OTHERS.NoDataMessage'),
      }
    ]
    setData(data);
  }

  return (
    <Container fluid>
        <Row className='mb-5'>
          <Row className='mb-3'>
            <Col md={4}>
              {
                selectedPartner != null 
                  ? <h3 className='fadedText'> {t('PRICEBOOK.TABLETEXT.HistoryHeadingForCompany', { companyName: selectedPartner.name})} </h3>
                  : <h3 className='fadedText'> {t('PRICEBOOK.TABLETEXT.HistoryHeadingDefault')} </h3>
              }
            </Col>
            <Col md={8}>
              <Row>
                  <Col md={6}>
                    <InputGroup>
                      <InputGroup.Text className="searchIcon">
                        <SearchIcon />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder={t('OTHERS.Search')}
                        type="text"
                        className="head_searchBar"
                        name="tab_searchBar"
                        onChange={(e) => inputHandler(e)}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      isClearable={true}
                      value={selectedPartner}
                      noOptionsMessage={() => t('OTHERS.NoDataMessage')}
                      loadOptions={loadPartners}
                      onChange={handlePartner}
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.name}
                      placeholder={t('PRICEBOOK.COMPANY.SelectPartner')}
                    />
                  </Col>
                </Row>
              </Col>
          </Row>
        </Row>
      <Row>
        {listData && (
          <DataTable
            columns={columns}
            data={listData}
            heading={false}
            searchBar={true}
            numofData={numofData}
            tableHeading={t('ADMIN.ManagePricebook')}
            searchMessage={t('OTHERS.Search')}
            filterButton={false}
            downloadButton={false}
            newValueButton={true}
            newValueText="Add Item"
            newValueLink="/clients/newclient"
            inputHandler={inputHandler}
            fetchData={fetchData}
            pageCount={pageCount}
            sortHandler={sortHandler}
            sortValue={sortVal}
            inputText={inputText}
            paginationItemsStartingPoint={ConfigHandler.getConfig().datatables.defaultStartingPoint}
            paginationItemsCount={ConfigHandler.getConfig().datatables.defaultPaginationCount}
            customTrigger={selectedPartner}
          />
        )}
      </Row>
    </Container>
  );
}

export default PricebookTable;
