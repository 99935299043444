import React, { /*useState, */Fragment } from "react";
import { useSelector } from "react-redux";
import { useHistory/*, useLocation*/ } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../scss/components/Breadcrumbs.scss";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function Breadcrumbs() {
  const state = useSelector((state) => state);
  /*let history = */useHistory();

  const generateBreadCrumbs = () => {
    if (state.root.sidebar.active_slug !== null) {
      return (
        <Fragment>
          <div className="firstIcon"  onClick={() => { window.history.back(); }}>
            <ArrowBackIcon />
          </div>
          <div className="firstValue">{state.root.sidebar.active_title}</div>
          {(state.root.sidebar.sub_slug != null)&&(
            <Fragment>
              <div className="secondIcon">
                <ArrowForwardIosIcon className="secondIcon" />
              </div>
              <div className="secondValue">{state.root.sidebar.sub_title}</div>
            </Fragment>
          )}
        </Fragment>
      )
    }
  }

  return <div className="BreadCrumbs">{generateBreadCrumbs()}</div>
}

export default Breadcrumbs;
