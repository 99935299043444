import React, { /*useEffect, */useState } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import '../scss/components/ChangePassword.scss';
import validator from 'validator';
import { RestService } from '../utils';
import ConfigHandler from '../config';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useTranslation } from 'react-i18next';

const ChangePassword = ({ setModalShow }) => {
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [validateNewPassword, setValidateNewPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  /* ## add an Eye to make password visible ## */
  const [visible, setVisible] = useState(false);
  const [visibletwo, setVisibletwo] = useState(false);

  const toggleVisible = () => {
    setVisible(!visible);
  };
  const toggleVisibletwo = () => {
    setVisibletwo(!visibletwo);
  };
  const HandleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.NewPassword.value === form.ConfirmPassword.value && validateNewPassword) {
      RestService.post({
        url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.changePassword}`,
        headers: { 'Content-Type': 'application/json' },
        body: {
          oldPassword: form.OldPassword.value,
          newPassword: form.NewPassword.value,
        },
      }).then((res) => {
        if (res.status > 199 && res.status < 300) {
          setValidateNewPassword(true);
          setModalShow(false);
          toast.success(t('CHANGE_PASSWORD.NotificationSuccess'));
        } else {
          res.json().then((statusText) => {
            toast.error(
              <>
                {t('NOTIFICATIONS.NotificationSomethingWrong')} <br />
                <small>
                  <i>{statusText.error}</i>
                </small>
              </>,
            );
          });
        }
      });
    } else {
      setValidateNewPassword(false);
      toast.error(t('CHANGE_PASSWORD.NotificationIncorrectPassword'));
    }
  };

  const validatePassword = (value) => {
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setErrorMessage(<i className="Password__Strong">{t('CHANGE_PASSWORD.Strong')}</i>);
      setValidateNewPassword(true);
    } else {
      setErrorMessage(t('CHANGE_PASSWORD.Weak'));
      setValidateNewPassword(false);
    }
  };

  return (
    <div className="ChangePasswordForm ">
      <Form className="ChangePasswordForm_container" validated={validated} onSubmit={HandleSubmit}>
        {/* <p className="h3 ChangePasswordForm_container_subtitle">Change password</p> */}
        <Form.Group className="mb-3 " md="3" controlId="OldPassword">
          <Form.Label as={Col} className="ChangePasswordForm_container_label float-start">
            {t('CHANGE_PASSWORD.Current')}
          </Form.Label>
          <Col>
            <Form.Control type="password" required className="border border-top-0 border-end-0 border-start-0" />
          </Col>
        </Form.Group>
        <Form.Group className="mb-3 " md="3" controlId="NewPassword">
          <Form.Label as={Col} className="ChangePasswordForm_container_label float-start">
            {t('CHANGE_PASSWORD.New')}
          </Form.Label>
          <Col>
            <Form.Control
              type={visible ? 'text' : 'password'}
              required
              //controlid="inputPassword5"
              className="border border-top-0 border-end-0 border-start-0"
              onChange={(e) => validatePassword(e.target.value)}
              isValid={validated}
            />
            <div className="Eye__Icon">
              {visible === false ? (
                <VisibilityOffIcon onClick={() => toggleVisible()} />
              ) : (
                <RemoveRedEyeIcon onClick={() => toggleVisible()} />
              )}
            </div>
            <p className="ChangePasswordForm__control_feedback">{errorMessage}</p>
          </Col>
        </Form.Group>

        <Form.Group className="mb-3 " md="3" controlId="ConfirmPassword">
          <Form.Label as={Col} className="ChangePasswordForm_container_label float-start">
            {t('CHANGE_PASSWORD.Confirm')}
          </Form.Label>
          <Col>
            <Form.Control
              type={visibletwo ? 'text' : 'password'}
              required
              className="border border-top-0 border-end-0 border-start-0"
              //onChange={(e) => validatePassword(e.target.value)}
              //isValid={validated}
            />
            <div className="Eye__Icon">
              {visibletwo === false ? (
                <VisibilityOffIcon onClick={() => toggleVisibletwo()} />
              ) : (
                <RemoveRedEyeIcon onClick={() => toggleVisibletwo()} />
              )}
            </div>
          </Col>
        </Form.Group>

        <div className="Button_container">
          <Button type="submit" variant="btn Button_container_color">
            {t('BUTTONS.UPDATE')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ChangePassword;
