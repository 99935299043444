import React from 'react';
import { Container, Row, Col, Badge } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import '../scss/components/DataTables.scss';
import { useTranslation } from 'react-i18next';
import Icon from '@mui/material/Icon';
import { toast } from 'react-toastify';
import { RestService } from '../utils';
import ConfigHandler from '../config';
import Utils from '../utils/Utils';

function ConfirmQuote() {
  const { t } = useTranslation();
  const location = useLocation();

  const downloadQuote = (value) => {
    RestService.get({
      url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getDocument}?id=${value}`
    })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          toast.warning(`${t('QUOTELIST.NotificationNoDocumentFoundLabel')} ${value}`);
        }
      })
      .then((res) => {
        const blobURL = window.URL.createObjectURL(res);
        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', `Quote_${value}.pdf`);
        if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank');
        }
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        setTimeout(() => {
          window.URL.revokeObjectURL(blobURL);
        }, 100);
      });
  };

  const generateSites = () => {
    var divsArray = [];

    if (location.state) {
      for (var i = 0; i < location.state.quote.quoteDetails.length; i++) {
        var quoteDetails = location.state.quote.quoteDetails[i];

        divsArray.push(
          <div className="siteWrapper" key={`container_site-${i}`}>
            <div className="form__Middle_SiteOne mt-5">
              <div className="form__Middle_SiteOne_left p-2 m-1">SITE {i + 1}</div>
              <div className="form__Middle_SiteOne_right m-1">
                <p>
                  {quoteDetails.city}, {quoteDetails.street} ({quoteDetails.streetNumber})
                </p>
              </div>
            </div>
            <table className="DataTables ol-details-variant">
              <tbody>
                <tr>
                  <td>{t('FORMS.PlanLabel')}</td>
                  <td>{quoteDetails.comunicaInfo.listino[0].descrizione}</td>
                </tr>
                <tr>
                  <td>{t('FORMS.FeasibilityLabel')}</td>
                  <td>{quoteDetails.comunicaInfo.listino[0].fattibilita ? t('NEWQUOTE.COVERAGE.Needed') : t('NEWQUOTE.COVERAGE.NotNeeded')}</td>
                </tr>
                <tr>
                  <td>{t('FORMS.CoveragePlan')}</td>
                  <td>
                    {quoteDetails.comunicaInfo.tipoServizioCopertura.descrizione} (
                    {quoteDetails.comunicaInfo.tipoServizioCopertura.id})
                  </td>
                </tr>
                <tr>
                  <td>{t('FORMS.StatusLabel')}</td>
                  <td>{quoteDetails.comunicaInfo.statoCoperturaServizio.descrizione}</td>
                </tr>
                <tr>
                  <td>{t('FORMS.NetworkType')}</td>
                  <td>{quoteDetails.comunicaInfo.statoCoperturaServizio.parametri['Tipo elemento di rete']}</td>
                </tr>
                <tr>
                  <td>{t('FORMS.NetworkName')}</td>
                  <td>{quoteDetails.comunicaInfo.statoCoperturaServizio.parametri['LOGGED-Nome elemento di rete']}</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      }
    }

    return divsArray;
  };


  return (
    <Container fluid>
      <Row style={{ marginBottom: '50px' }}>
        <Col md={6}>
          <h4 className="ol-details-heading">
            {t('QUOTELIST.VIEWQUOTE.PageTitle')} {location.state && location.state.quote.client.companyName}
          </h4>
        </Col>
        <Col className="item-center">
          <Row>
            <Col>
              <Badge pill bg="info">
                {t('FORMS.CRMOpportunityLabel')}
              </Badge>
              <p>{location.state && location.state.quote.crmOpportunityId}</p>
            </Col>
            <Col>
              <Badge pill bg="info">
              {t('FORMS.ExpirationDateLabel')}
              </Badge>
              <p>{location.state && location.state.quote.expairingDate}</p>
            </Col>
            <Col>
              <Badge pill bg="info">
              {t('FORMS.CodeLabel')}
              </Badge>
              <p>{location.state && location.state.quote.code}</p>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ marginBottom: '10px', backgroundColor: '#f7f7f7', padding: '2rem' }}>
        <h6 className="ol-details-heading">{t('QUOTELIST.VIEWQUOTE.QuoteStatusLabel')}</h6>
        <hr className="styledHR" />
        <Col className="item-center">
          <table className="DataTables ol-details-variant">
            <tbody>
              <tr>
                <td>{t('FORMS.StatusLabel')}</td>
                <td>{Utils.QuoteStatus(location.state.quote.status)}</td>
              </tr>
              <tr>
                <td>{t('FORMS.CommentsLabel')}</td>
                <td>
                  {location.state &&
                    (location.state.quote.statusMessage == null
                      ? t('QUOTELIST.VIEWQUOTE.DefaultStatus')
                      : location.state.quote.statusMessage)}
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>

      <Row style={{ marginBottom: '50px' }}>
        <h6 className="ol-details-heading">{t('QUOTELIST.VIEWQUOTE.QuoteDetailsLabel')}</h6>
        <hr className="styledHR" />
        <Row style={{ marginBottom: '25px' }}>
          <Col>
            <table className="DataTables ol-details-variant">
              <tbody>
                <tr>
                  <td>{t('FORMS.CompanyNameLabel')}</td>
                  <td> {location.state && location.state.quote.client.companyName} </td>
                </tr>
                <tr>
                  <td>{t('FORMS.VatLabel')}</td>
                  <td>{location.state && location.state.quote.client.vatNumber}</td>
                </tr>
                <tr>
                  <td>{t('FORMS.AsnPlaceholder')}</td>
                  <td>{location.state && location.state.quote.client.asNumber}</td>
                </tr>
                <tr>
                  <td>{t('FORMS.ReferenceNameLabel')}</td>
                  <td>{location.state && location.state.quote.referenceName}</td>
                </tr>
                <tr>
                  <td>{t('FORMS.ReferencePhoneLabel')}</td>
                  <td>{location.state && location.state.quote.referencePhone}</td>
                </tr>
                <tr>
                  <td>{t('FORMS.LocationLabel')}</td>
                  <td>
                    {location.state && location.state.quote.client.street}{' '}
                    {location.state && location.state.quote.client.streetNumber},{' '}
                    {location.state && location.state.quote.client.city}
                  </td>
                </tr>
                <tr>
                  <td>{t('FORMS.CommentsLabel')}</td>
                  <td>{location.state && location.state.quote.note}</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>

        <Row>{generateSites()}</Row>
      </Row>

      {location.state.quote.status === 4 && (
        <Row>
          <Col className="text-right">
            <button class="btn fadedButton" onClick={() => downloadQuote(location.state.quote.crmOpportunityId)}>
              {t('BUTTONS.DOWNLOAD')}
            </button>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default ConfirmQuote;
