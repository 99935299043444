import React, { useEffect } from "react";
import "../scss/components/Fx.scss";

const Fx = () => {
  useEffect(() => {
    var canvas = document.getElementById("Fx");
    var ctx = canvas.getContext("2d");
    ctx.strokeStyle = "#c11c43";
    ctx.fillStyle = "#c11c43";

    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(300, 0);
    ctx.lineTo(0, 70);
    ctx.fill();
  }, []);

  return <canvas id="Fx"></canvas>;
};
export default Fx;
