import { Button, Modal, Row, Col, Badge, Alert } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { RestService } from '../utils';
import ConfigHandler from '../config';
import { toast } from 'react-toastify';
import { useTranslation, Trans } from 'react-i18next';
import '../scss/components/Modal.scss';

function DisablePricebookItemModal({ setShowModal, data, partner }) {
  const [show, setShow] = useState(true);
  const { t } = useTranslation();

  const deleteRecord = () => {
    RestService.post(
        data?.partnerId 
            ? {
                url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.disablePricebook}/?partnerId=${data.partnerId}`,
                body: data
            }
            : {
                url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.disablePricebook}`,
                body: data
            }
    ).then((response) => response.json().then((res) => {
        if(response.status == 200){
            toast.warning( data.portalCode +' was disabled!');
            setShow(false); 
            setShowModal(false);
        }else{
            toast.error(t('NOTIFICATIONS.NotificationSomethingWrong'));
            setShow(false); 
            setShowModal(false);
        }
    }))
  };

  return (
    <>
      <Modal show={show} fullscreen={false} onHide={() => { setShow(false); setShowModal(false); }} className="NewQuoteConfirmModal">
        <Modal.Header closeButton>
          <span>{t('MODALS.ConfirmationHeading')}</span>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <p>
                <Trans i18nKey="PRICEBOOK.MODAL.ConfirmationMessage" components={{ b: <b />, item: data.portalCode + (data?.code != null ? ' ('+data.code+')' : '' ) }}/>
              </p>
            </Col>
          </Row>
              {
                (data?.partnerId == null) && (
                  <Row className='p-1'>
                    <p className="small">
                      <Badge bg="warning">{t('NEWQUOTE.CONFIRMMODAL.AttentionLabel')}</Badge> <Trans i18nKey="PRICEBOOK.MODAL.InfoMessageForDefaultItem" components={{ b: <b />, }} />
                    </p>
                  </Row>  
                )
              }
          <Row>
            <Col>
              <button
                className="fadedBorderButton p-2"
                onClick={() => {
                  setShow(false);
                  setShowModal(false);
                }}>
                {t('MODALS.BackButton')}
              </button>
            </Col>
            <Col>
              <button className="fadedButton p-2" onClick={() => deleteRecord()}>
                {t('MODALS.ProceedButton')}
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DisablePricebookItemModal;
