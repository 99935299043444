import React, { Fragment, useState, useEffect } from "react";
import { RestService } from '../utils';
import ConfigHandler from '../config';
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from 'react-bootstrap';
import Icon from '@mui/material/Icon'

import '../scss/views/dashboard.scss'

const Dashboard = () => {
  const { t } = useTranslation();
  const [ userData, setUserData ] = useState(null);
  const [ totalUser, setTotalUser ] = useState(0);
  const [ totalCustomer, setTotalCustomer ] = useState(0);
  const [ pendingQuotes, setPendingQuotes ] = useState(0);
  const [ confimedQuotes, setConfimedQuotes ] = useState(0);

  useEffect(() => {
    getUserData();
    getPartnerUsers();
    getClients();
    getDraftQuote();
    getConfirmedQuote();
  }, [])
  
  const getUserData = () => {
    RestService.get({
      url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getMe}`,
    }).then((response) => {
      response.json().then((data) => {
        setUserData(data);       
      })
    })
  }

  const getPartnerUsers = () => {
    RestService.get({
      url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getUsers}?q=&offset=0&limit=1&order=id:desc`,
    }).then((response) => {
      response.json().then((data) => {
        setTotalUser(data.total)   
      })
    })
  }

  const getClients = () => {
    RestService.get({
      url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getClientof}?q=&offset=0&limit=1&order=id:desc`,
    }).then((response) => {
      response.json().then((data) => {
        setTotalCustomer(data.total)   
      })
    })
  }

  const getDraftQuote = () => {
    RestService.get({
      url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getDraftQuote}?q=&offset=0&limit=1&order=dateInserted:desc`,
    }).then((response) => {
      response.json().then((data) => {
        setPendingQuotes(data.total)   
      })
    })
  }

  const getConfirmedQuote = () => {
    RestService.get({
      url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getOrderQuote}?q=&offset=0&limit=1&order=dateInserted:desc`,
    }).then((response) => {
      response.json().then((data) => {
        setConfimedQuotes(data.total)   
      })
    })

  }

  return (
    <Container fluid className="dashboardPage">
      <Row className="mb-3 mt-0">
        <h1 className="title">{t("DASHBOARD.pageTitle")} <span className="fadedText">{userData && (userData.partner.name)}</span></h1>
      </Row>

      {/* 
        ************ V1 CARDS *****************
      <Row className="g-3 mb-3">
        <Col md={4} lg={3}>
          <div className="statsCardContainer fiberTelecom">
            <div className="content">
              <h5>{t('DASHBOARD.cardUser')}</h5>
              <h3>{totalUser}</h3>
            </div>
            <div className="icon">
              <Icon>account_box</Icon>
            </div>
          </div>
        </Col>
        <Col md={4} lg={3}>
          <div className="statsCardContainer outline">
            <div className="content">
              <h5>{t('DASHBOARD.cardCustomer')}</h5>
              <h3>{totalCustomer}</h3>
            </div>
            <div className="icon">
              <Icon>group</Icon>
            </div>
          </div>
        </Col>
        <Col md={4} lg={3}>
          <div className="statsCardContainer fiberTelecom">
            <div className="content">
              <h5>{t('DASHBOARD.cardPendingQuote')}</h5>
              <h3>{pendingQuotes}</h3>
            </div>
            <div className="icon">
              <Icon>pending_actions</Icon>
            </div>
          </div>
        </Col>
        <Col md={4} lg={3}>
          <div className="statsCardContainer outline">
            <div className="content">
              <h5>{t('DASHBOARD.cardConfirmedQuote')}</h5>
              <h3>{confimedQuotes}</h3>
            </div>
            <div className="icon">
              <Icon>assignment</Icon>
            </div>
          </div>
        </Col>
      </Row> */}

      <Row className="g-3">
        <Col md={4} lg={3}>
          <div className="dashboardCard">
            <div className="headerPart">
              <Icon>person</Icon> 
              <h3>
                {t('DASHBOARD.cardUser')}
              </h3>
            </div>
            <div className="contentPart">
              <h3>{totalUser}</h3>
            </div>
          </div>
        </Col>
        <Col md={4} lg={3}>
          <div className="dashboardCard">
            <div className="headerPart">
              <Icon>group</Icon> 
              <h3>
              {t('DASHBOARD.cardCustomer')}
              </h3>
            </div>
            <div className="contentPart">
              <h3>{totalCustomer}</h3>
            </div>
          </div>
        </Col>
        <Col md={4} lg={3}>
          <div className="dashboardCard">
            <div className="headerPart">
              <Icon>pending_actions</Icon> 
              <h3>
              {t('DASHBOARD.cardPendingQuote')}
              </h3>
            </div>
            <div className="contentPart">
              <h3>{pendingQuotes}</h3>
            </div>
          </div>
        </Col>
        <Col md={4} lg={3}>
          <div className="dashboardCard">
            <div className="headerPart">
              <Icon>assignment</Icon> 
              <h3>
                {t('DASHBOARD.cardConfirmedQuote')}
              </h3>
            </div>
            <div className="contentPart">
              <h3>{confimedQuotes}</h3>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
