import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

function pathType(){
  if(process.env.REACT_APP_ENV == "loc"){
    return '/store/locales/{{lng}}/{{ns}}.json'
  } else {
    return "/langs/{{lng}}/{{ns}}.json"
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    lng: localStorage.getItem("i18nextLng") || 'en',
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    allowMultiLoading: true,
    backend: {
      loadPath: pathType(),
      addPath: pathType()
    }
  });

export default i18n;
