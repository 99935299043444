const countries = [
        {
            "value": "AF",
            "label": "AF"
        },
        {
            "value": "AX",
            "label": "AX"
        },
        {
            "value": "AL",
            "label": "AL"
        },
        {
            "value": "DZ",
            "label": "DZ"
        },
        {
            "value": "AS",
            "label": "AS"
        },
        {
            "value": "AD",
            "label": "AD"
        },
        {
            "value": "AO",
            "label": "AO"
        },
        {
            "value": "AI",
            "label": "AI"
        },
        {
            "value": "AQ",
            "label": "AQ"
        },
        {
            "value": "AG",
            "label": "AG"
        },
        {
            "value": "AR",
            "label": "AR"
        },
        {
            "value": "AM",
            "label": "AM"
        },
        {
            "value": "AW",
            "label": "AW"
        },
        {
            "value": "AU",
            "label": "AU"
        },
        {
            "value": "AT",
            "label": "AT"
        },
        {
            "value": "AZ",
            "label": "AZ"
        },
        {
            "value": "BS",
            "label": "BS"
        },
        {
            "value": "BH",
            "label": "BH"
        },
        {
            "value": "BD",
            "label": "BD"
        },
        {
            "value": "BB",
            "label": "BB"
        },
        {
            "value": "BY",
            "label": "BY"
        },
        {
            "value": "BE",
            "label": "BE"
        },
        {
            "value": "BZ",
            "label": "BZ"
        },
        {
            "value": "BJ",
            "label": "BJ"
        },
        {
            "value": "BM",
            "label": "BM"
        },
        {
            "value": "BT",
            "label": "BT"
        },
        {
            "value": "BO",
            "label": "BO"
        },
        {
            "value": "BQ",
            "label": "BQ"
        },
        {
            "value": "BA",
            "label": "BA"
        },
        {
            "value": "BW",
            "label": "BW"
        },
        {
            "value": "BV",
            "label": "BV"
        },
        {
            "value": "BR",
            "label": "BR"
        },
        {
            "value": "IO",
            "label": "IO"
        },
        {
            "value": "BN",
            "label": "BN"
        },
        {
            "value": "BG",
            "label": "BG"
        },
        {
            "value": "BF",
            "label": "BF"
        },
        {
            "value": "BI",
            "label": "BI"
        },
        {
            "value": "KH",
            "label": "KH"
        },
        {
            "value": "CM",
            "label": "CM"
        },
        {
            "value": "CA",
            "label": "CA"
        },
        {
            "value": "CV",
            "label": "CV"
        },
        {
            "value": "KY",
            "label": "KY"
        },
        {
            "value": "CF",
            "label": "CF"
        },
        {
            "value": "TD",
            "label": "TD"
        },
        {
            "value": "CL",
            "label": "CL"
        },
        {
            "value": "CN",
            "label": "CN"
        },
        {
            "value": "CX",
            "label": "CX"
        },
        {
            "value": "CC",
            "label": "CC"
        },
        {
            "value": "CO",
            "label": "CO"
        },
        {
            "value": "KM",
            "label": "KM"
        },
        {
            "value": "CG",
            "label": "CG"
        },
        {
            "value": "CD",
            "label": "CD"
        },
        {
            "value": "CK",
            "label": "CK"
        },
        {
            "value": "CR",
            "label": "CR"
        },
        {
            "value": "CI",
            "label": "CI"
        },
        {
            "value": "HR",
            "label": "HR"
        },
        {
            "value": "CU",
            "label": "CU"
        },
        {
            "value": "CW",
            "label": "CW"
        },
        {
            "value": "CY",
            "label": "CY"
        },
        {
            "value": "CZ",
            "label": "CZ"
        },
        {
            "value": "DK",
            "label": "DK"
        },
        {
            "value": "DJ",
            "label": "DJ"
        },
        {
            "value": "DM",
            "label": "DM"
        },
        {
            "value": "DO",
            "label": "DO"
        },
        {
            "value": "EC",
            "label": "EC"
        },
        {
            "value": "EG",
            "label": "EG"
        },
        {
            "value": "SV",
            "label": "SV"
        },
        {
            "value": "GQ",
            "label": "GQ"
        },
        {
            "value": "ER",
            "label": "ER"
        },
        {
            "value": "EE",
            "label": "EE"
        },
        {
            "value": "ET",
            "label": "ET"
        },
        {
            "value": "FK",
            "label": "FK"
        },
        {
            "value": "FO",
            "label": "FO"
        },
        {
            "value": "FJ",
            "label": "FJ"
        },
        {
            "value": "FI",
            "label": "FI"
        },
        {
            "value": "FR",
            "label": "FR"
        },
        {
            "value": "GF",
            "label": "GF"
        },
        {
            "value": "PF",
            "label": "PF"
        },
        {
            "value": "TF",
            "label": "TF"
        },
        {
            "value": "GA",
            "label": "GA"
        },
        {
            "value": "GM",
            "label": "GM"
        },
        {
            "value": "GE",
            "label": "GE"
        },
        {
            "value": "DE",
            "label": "DE"
        },
        {
            "value": "GH",
            "label": "GH"
        },
        {
            "value": "GI",
            "label": "GI"
        },
        {
            "value": "GR",
            "label": "GR"
        },
        {
            "value": "GL",
            "label": "GL"
        },
        {
            "value": "GD",
            "label": "GD"
        },
        {
            "value": "GP",
            "label": "GP"
        },
        {
            "value": "GU",
            "label": "GU"
        },
        {
            "value": "GT",
            "label": "GT"
        },
        {
            "value": "GG",
            "label": "GG"
        },
        {
            "value": "GN",
            "label": "GN"
        },
        {
            "value": "GW",
            "label": "GW"
        },
        {
            "value": "GY",
            "label": "GY"
        },
        {
            "value": "HT",
            "label": "HT"
        },
        {
            "value": "HM",
            "label": "HM"
        },
        {
            "value": "VA",
            "label": "VA"
        },
        {
            "value": "HN",
            "label": "HN"
        },
        {
            "value": "HK",
            "label": "HK"
        },
        {
            "value": "HU",
            "label": "HU"
        },
        {
            "value": "IS",
            "label": "IS"
        },
        {
            "value": "IN",
            "label": "IN"
        },
        {
            "value": "ID",
            "label": "ID"
        },
        {
            "value": "IR",
            "label": "IR"
        },
        {
            "value": "IQ",
            "label": "IQ"
        },
        {
            "value": "IE",
            "label": "IE"
        },
        {
            "value": "IM",
            "label": "IM"
        },
        {
            "value": "IL",
            "label": "IL"
        },
        {
            "value": "IT",
            "label": "IT"
        },
        {
            "value": "JM",
            "label": "JM"
        },
        {
            "value": "JP",
            "label": "JP"
        },
        {
            "value": "JE",
            "label": "JE"
        },
        {
            "value": "JO",
            "label": "JO"
        },
        {
            "value": "KZ",
            "label": "KZ"
        },
        {
            "value": "KE",
            "label": "KE"
        },
        {
            "value": "KI",
            "label": "KI"
        },
        {
            "value": "KP",
            "label": "KP"
        },
        {
            "value": "KR",
            "label": "KR"
        },
        {
            "value": "XK",
            "label": "XK"
        },
        {
            "value": "KW",
            "label": "KW"
        },
        {
            "value": "KG",
            "label": "KG"
        },
        {
            "value": "LA",
            "label": "LA"
        },
        {
            "value": "LV",
            "label": "LV"
        },
        {
            "value": "LB",
            "label": "LB"
        },
        {
            "value": "LS",
            "label": "LS"
        },
        {
            "value": "LR",
            "label": "LR"
        },
        {
            "value": "LY",
            "label": "LY"
        },
        {
            "value": "LI",
            "label": "LI"
        },
        {
            "value": "LT",
            "label": "LT"
        },
        {
            "value": "LU",
            "label": "LU"
        },
        {
            "value": "MO",
            "label": "MO"
        },
        {
            "value": "MK",
            "label": "MK"
        },
        {
            "value": "MG",
            "label": "MG"
        },
        {
            "value": "MW",
            "label": "MW"
        },
        {
            "value": "MY",
            "label": "MY"
        },
        {
            "value": "MV",
            "label": "MV"
        },
        {
            "value": "ML",
            "label": "ML"
        },
        {
            "value": "MT",
            "label": "MT"
        },
        {
            "value": "MH",
            "label": "MH"
        },
        {
            "value": "MQ",
            "label": "MQ"
        },
        {
            "value": "MR",
            "label": "MR"
        },
        {
            "value": "MU",
            "label": "MU"
        },
        {
            "value": "YT",
            "label": "YT"
        },
        {
            "value": "MX",
            "label": "MX"
        },
        {
            "value": "FM",
            "label": "FM"
        },
        {
            "value": "MD",
            "label": "MD"
        },
        {
            "value": "MC",
            "label": "MC"
        },
        {
            "value": "MN",
            "label": "MN"
        },
        {
            "value": "ME",
            "label": "ME"
        },
        {
            "value": "MS",
            "label": "MS"
        },
        {
            "value": "MA",
            "label": "MA"
        },
        {
            "value": "MZ",
            "label": "MZ"
        },
        {
            "value": "MM",
            "label": "MM"
        },
        {
            "value": "NA",
            "label": "NA"
        },
        {
            "value": "NR",
            "label": "NR"
        },
        {
            "value": "NP",
            "label": "NP"
        },
        {
            "value": "NL",
            "label": "NL"
        },
        {
            "value": "AN",
            "label": "AN"
        },
        {
            "value": "NC",
            "label": "NC"
        },
        {
            "value": "NZ",
            "label": "NZ"
        },
        {
            "value": "NI",
            "label": "NI"
        },
        {
            "value": "NE",
            "label": "NE"
        },
        {
            "value": "NG",
            "label": "NG"
        },
        {
            "value": "NU",
            "label": "NU"
        },
        {
            "value": "NF",
            "label": "NF"
        },
        {
            "value": "MP",
            "label": "MP"
        },
        {
            "value": "NO",
            "label": "NO"
        },
        {
            "value": "OM",
            "label": "OM"
        },
        {
            "value": "PK",
            "label": "PK"
        },
        {
            "value": "PW",
            "label": "PW"
        },
        {
            "value": "PS",
            "label": "PS"
        },
        {
            "value": "PA",
            "label": "PA"
        },
        {
            "value": "PG",
            "label": "PG"
        },
        {
            "value": "PY",
            "label": "PY"
        },
        {
            "value": "PE",
            "label": "PE"
        },
        {
            "value": "PH",
            "label": "PH"
        },
        {
            "value": "PN",
            "label": "PN"
        },
        {
            "value": "PL",
            "label": "PL"
        },
        {
            "value": "PT",
            "label": "PT"
        },
        {
            "value": "PR",
            "label": "PR"
        },
        {
            "value": "QA",
            "label": "QA"
        },
        {
            "value": "RE",
            "label": "RE"
        },
        {
            "value": "RO",
            "label": "RO"
        },
        {
            "value": "RU",
            "label": "RU"
        },
        {
            "value": "RW",
            "label": "RW"
        },
        {
            "value": "BL",
            "label": "BL"
        },
        {
            "value": "SH",
            "label": "SH"
        },
        {
            "value": "KN",
            "label": "KN"
        },
        {
            "value": "LC",
            "label": "LC"
        },
        {
            "value": "MF",
            "label": "MF"
        },
        {
            "value": "PM",
            "label": "PM"
        },
        {
            "value": "VC",
            "label": "VC"
        },
        {
            "value": "WS",
            "label": "WS"
        },
        {
            "value": "SM",
            "label": "SM"
        },
        {
            "value": "ST",
            "label": "ST"
        },
        {
            "value": "SA",
            "label": "SA"
        },
        {
            "value": "SN",
            "label": "SN"
        },
        {
            "value": "RS",
            "label": "RS"
        },
        {
            "value": "CS",
            "label": "CS"
        },
        {
            "value": "SC",
            "label": "SC"
        },
        {
            "value": "SL",
            "label": "SL"
        },
        {
            "value": "SG",
            "label": "SG"
        },
        {
            "value": "SX",
            "label": "SX"
        },
        {
            "value": "SK",
            "label": "SK"
        },
        {
            "value": "SI",
            "label": "SI"
        },
        {
            "value": "SB",
            "label": "SB"
        },
        {
            "value": "SO",
            "label": "SO"
        },
        {
            "value": "ZA",
            "label": "ZA"
        },
        {
            "value": "GS",
            "label": "GS"
        },
        {
            "value": "SS",
            "label": "SS"
        },
        {
            "value": "ES",
            "label": "ES"
        },
        {
            "value": "LK",
            "label": "LK"
        },
        {
            "value": "SD",
            "label": "SD"
        },
        {
            "value": "SR",
            "label": "SR"
        },
        {
            "value": "SJ",
            "label": "SJ"
        },
        {
            "value": "SZ",
            "label": "SZ"
        },
        {
            "value": "SE",
            "label": "SE"
        },
        {
            "value": "CH",
            "label": "CH"
        },
        {
            "value": "SY",
            "label": "SY"
        },
        {
            "value": "TW",
            "label": "TW"
        },
        {
            "value": "TJ",
            "label": "TJ"
        },
        {
            "value": "TZ",
            "label": "TZ"
        },
        {
            "value": "TH",
            "label": "TH"
        },
        {
            "value": "TL",
            "label": "TL"
        },
        {
            "value": "TG",
            "label": "TG"
        },
        {
            "value": "TK",
            "label": "TK"
        },
        {
            "value": "TO",
            "label": "TO"
        },
        {
            "value": "TT",
            "label": "TT"
        },
        {
            "value": "TN",
            "label": "TN"
        },
        {
            "value": "TR",
            "label": "TR"
        },
        {
            "value": "TM",
            "label": "TM"
        },
        {
            "value": "TC",
            "label": "TC"
        },
        {
            "value": "TV",
            "label": "TV"
        },
        {
            "value": "UG",
            "label": "UG"
        },
        {
            "value": "UA",
            "label": "UA"
        },
        {
            "value": "AE",
            "label": "AE"
        },
        {
            "value": "GB",
            "label": "GB"
        },
        {
            "value": "US",
            "label": "US"
        },
        {
            "value": "UM",
            "label": "UM"
        },
        {
            "value": "UY",
            "label": "UY"
        },
        {
            "value": "UZ",
            "label": "UZ"
        },
        {
            "value": "VU",
            "label": "VU"
        },
        {
            "value": "VE",
            "label": "VE"
        },
        {
            "value": "VN",
            "label": "VN"
        },
        {
            "value": "VG",
            "label": "VG"
        },
        {
            "value": "VI",
            "label": "VI"
        },
        {
            "value": "WF",
            "label": "WF"
        },
        {
            "value": "EH",
            "label": "EH"
        },
        {
            "value": "YE",
            "label": "YE"
        },
        {
            "value": "ZM",
            "label": "ZM"
        },
        {
            "value": "ZW",
            "label": "ZW"
        }
]

export default countries; 