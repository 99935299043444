import { Button, Modal, Row, Col, Badge } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import "../scss/components/Modal.scss";
import { useTranslation } from 'react-i18next';

function NewQuoteConfirmModal({
  setShowConfirmationModal,
  setIsDraft,
  HandleSubmit,
  formData,
}) {
  const [show, setShow] = useState(true);
  const { t } = useTranslation();

  const saveForm = () => {
    HandleSubmit(formData);
    setShowConfirmationModal(false);
  };

  return (
    <>
      <Modal
        show={show}
        fullscreen={false}
        onHide={() => {
          setShow(false);
          setShowConfirmationModal(false);
        }}
        className="NewQuoteConfirmModal"
      >
        <Modal.Header closeButton>
          <span>
            {t('NEWQUOTE.CONFIRMMODAL.ModalHeading')}
          </span>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col></Col>
          </Row>
          <Row>
            <Col>
              <p>
                <Badge bg="warning">{t('NEWQUOTE.CONFIRMMODAL.AttentionLabel')}</Badge> {t('NEWQUOTE.CONFIRMMODAL.AttentionText')}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <button
                className="fadedBorderButton p-2"
                onClick={() => saveForm()}
                onMouseDown={() => setIsDraft(0)}
              >
                {t('NEWQUOTE.CONFIRMMODAL.DraftButton')}
              </button>
            </Col>
            <Col>
              <button
                className="fadedButton p-2"
                onClick={() => saveForm()}
                onMouseDown={() => setIsDraft(1)}
              >
                {t('NEWQUOTE.CONFIRMMODAL.SubmitButton')}
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default NewQuoteConfirmModal;
