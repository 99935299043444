import React, { useState } from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import config from '../config/loc.json';
import { useTranslation } from 'react-i18next';
import { AuthHandler } from '../utils/AuthHandler';
import { Container, Row, Col } from 'react-bootstrap';
import '../scss/views/Footer.scss';

const FooterResponsive = () => {
  const { t } = useTranslation();
  const [input, setInput] = useState('');
  const [submittedInput, setSubmittedInput] = useState('');

  return (
    <Container fluid className='p-0 m-0'>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <hr className="hr-faded" />
              <div className="footer__top_title">
                <h3>Fiber Telecom S.p.A.</h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div className="footer__content_emails">
                <a href={`mailto:${config.email.sales}`} style={{ width: 'fit-content' }}>
                  {config.email.sales}
                </a>
                <a href={`mailto:${config.email.support}`} style={{ width: 'fit-content' }}>
                  {config.email.support}
                </a>
                <a href={`mailto:${config.email.administrative}`} style={{ width: 'fit-content' }}>
                  {config.email.administrative}
                </a>
              </div>
            </Col>
            <Col md={6}>{/* SPACE FOR NEWSLETTER SUBSCRIPTION */}</Col>
            <Col
              md={2}
              className="item-center footer__content_icons_container_socialmedia footer__content_icons_container">
              <Row>
                <Col>
                  <a href={config.url.linkedIn} target="_blank">
                    <i className="linkedIn">
                      <LinkedInIcon />
                    </i>
                  </a>
                </Col>
                <Col>
                  <a href={config.url.twitter} target="_blank">
                    <i className="twitter">
                      <TwitterIcon />
                    </i>
                  </a>
                </Col>
                <Col>
                  <a href={config.url.facebook} target="_blank">
                    <i className="facebook">
                      <FacebookIcon />
                    </i>
                  </a>
                </Col>
                <Col>
                  <a href={config.url.youtube} target="_blank">
                    <i className="youtube">
                      <YouTubeIcon />
                    </i>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="footer__bottom">
            <ul className="nav">
              <li>
                <a href={config.url.cookiePolicy} target="_blank">
                  {t('FOOTER.CookieLabel')}
                </a>
              </li>
              <li>
                <a href={config.url.legalInformation} target="_blank">
                  {t('FOOTER.LegalLabel')}
                </a>
              </li>
              <li>
                <a href={config.url.privacyPolicy} target="_blank">
                  {t('FOOTER.PrivacyLabel')}
                </a>
              </li>
              <li>
                <a href={config.url.securityInformation} target="_blank">
                  {t('FOOTER.SecurityLabel')}
                </a>
              </li>
            </ul>
          </Row>
        </Col>
      </Row>
    </Container>

    // <div className="footer">
    //   <div className="footer__blank">
    //     <hr className="hr-faded" />
    //     <div className="footer__top">
    //       <div className="footer__top_title">
    //         <h3>Fiber Telecom S.p.A</h3>
    //       </div>
    //     </div>
    //     <div className="footer__content">
    // <div className="footer__content_emails">
    //   <a href={`mailto:${config.email.sales}`} style={{width: 'fit-content'}}>{config.email.sales}</a>
    //   <a href={`mailto:${config.email.support}`} style={{width: 'fit-content'}}>{config.email.support}</a>
    //   <a href={`mailto:${config.email.administrative}`} style={{width: 'fit-content'}}>{config.email.administrative}</a>
    // </div>

    //   <div className="footer__content_icons_container">
    //     <div className="footer__content_icons_container_socialmedia">
    //       <a href={config.url.linkedIn} target="_blank">
    //         <i className="linkedIn">
    //           <LinkedInIcon />
    //         </i>
    //       </a>
    //       <a href={config.url.twitter} target="_blank">
    //         <i className="twitter">
    //           <TwitterIcon />
    //         </i>
    //       </a>
    //       <a href={config.url.facebook} target="_blank">
    //         <i className="facebook">
    //           <FacebookIcon />
    //         </i>
    //       </a>
    //       <a href={config.url.youtube} target="_blank">
    //         <i className="youtube">
    //           <YouTubeIcon />
    //         </i>
    //       </a>
    //     </div>
    //   </div>
    // </div>
    //     <div className="footer__bottom">
    // <ul className="nav">
    //   <li>
    //     <a href={config.url.cookiePolicy} target="_blank">
    //       {t('FOOTER.Cookie')}
    //     </a>
    //   </li>
    //   <li>
    //     <a href={config.url.legalInformation} target="_blank">
    //       {t('FOOTER.Legal')}
    //     </a>
    //   </li>
    //   <li>
    //     <a href={config.url.privacyPolicy} target="_blank">
    //       {t('FOOTER.Privacy')}{' '}
    //     </a>
    //   </li>
    //   <li>
    //     <a href={config.url.securityInformation} target="_blank">
    //       {t('FOOTER.Security')}
    //     </a>
    //   </li>
    // </ul>
    //     </div>
    //   </div>
    // </div>
  );
};

export default FooterResponsive;
