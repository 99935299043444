import { AuthHandler } from '../utils/AuthHandler';
import ConfigHandler from '../config';
import { setLoading } from '../store/actions/isLoading';

const call = (params) => {
  if (!params.headers) {
    params.headers = {};
  }

  params.headers['Content-Type'] = 'application/json';

  if (AuthHandler.isAuth()) {
    params.headers['Authorization'] = AuthHandler.getToken();
  }

  let resStatus = 0;
  let messsage = '';

  (params.showLoader)&&(dispatcher(setLoading(true)));

  try {
    return fetch(params.url, {
      method: params.method,
      headers: params.headers,
      body: JSON.stringify(params.body),
    })
      .then((res) => {
        resStatus = res.status;
        return res;
      })
      .then((res) => {
        dispatcher(setLoading(false));
        switch (resStatus) {
          case 200:
            return res;
          case 403:
            messsage = '403 Forbidden';
            console.error(messsage);
            return RefreshToken(params).then((response) => {
              return call(params);
            });
          case 401:
            messsage = '401 Unauthorized';
            console.error(messsage);
            return res;
          case 404:
            messsage = '404 Not found';
            console.error(messsage);
            return messsage;
          default:
            console.info(`Response Status: ${resStatus}`);
            return res;
        }
      })
      .catch((err) => {
        dispatcher(setLoading(false));
        AuthHandler.clearAuth();
        console.error(err);
      });
  } catch (err) {
    throw new Error(err);
  }
};

const AutoRefreshToken = () => {
  const token = AuthHandler.getRefreshToken();

  fetch(ConfigHandler.getConfig().api.baseUrl + ConfigHandler.getConfig().api.path.refreshToken, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token: token }),
  }).then((response) =>
    response
      .json()
      .then((data) => ({
        data: data,
        status: response.status,
      }))
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          AuthHandler.setAuth(res.data);
        } else {
          AuthHandler.clearAuth();
        }
      }),
  );
};

function RefreshToken(reParams) {
  const objBody = AuthHandler.getRefreshToken();
  return fetch(ConfigHandler.getConfig().api.baseUrl + ConfigHandler.getConfig().api.path.refreshToken, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token: objBody }),
  })
    .then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            AuthHandler.setAuth(res.data);
            return call(reParams);
          } else {
            dispatcher(setLoading(false));
            AuthHandler.clearAuth();
          }
        }),
    )
    .catch((error) => {
      AuthHandler.clearAuth();
      throw Error(error);
    });
}

//Variabile di classe per settare il loading
let dispatcher = null;

export const RestService = {
  init: (dispatch) => {
    dispatcher = dispatch;
  },
  post: (params) => {
    params.method = 'POST';
    params.showLoader = params.hasOwnProperty('showLoader') ? params.showLoader : true; 
    return call(params);
  },
  get: (params) => {
    params.method = 'GET';
    params.showLoader = params.hasOwnProperty('showLoader') ? params.showLoader : true; 
    return call(params);
  },
  put: (params) => {
    params.method = 'PUT';
    params.showLoader = params.hasOwnProperty('showLoader') ? params.showLoader : true; 
    return call(params);
  },
  delete: (params) => {
    params.method = 'DELETE';
    params.showLoader = params.hasOwnProperty('showLoader') ? params.showLoader : true; 
    return call(params);
  },
  autoRefresh: (params) => {
    return AutoRefreshToken(params);
  },
  call: call,
};
