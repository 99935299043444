import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ConfigHandler from './config';
import { RestService } from './utils';
import { /*BrowserRouter as Router, */Redirect, useLocation } from 'react-router-dom';
import { AuthHandler } from './utils/AuthHandler';
import Header from './containers/Header';
import NoAuthHeader from './containers/NoAuthHeader';
import Spinner from './components/Spinner';
import NoAutHomeContainer from './views/NoAuthHome';
import { ToastContainer/*, toast*/ } from 'react-toastify';
import Navigator from './containers/Navigator';
import ForgotPasswordLanding from './components/ForgotPasswordLanding';
import i18n from './components/i18n';
//import FooterResponsive from './containers/FooterResponsive';
import FirstLogin from './components/FirstLogin';
import Utils from './utils/Utils';
import './components/i18n';

//Styles&Icons
import '../src/scss/app/App.scss';
import 'react-toastify/dist/ReactToastify.css';

ConfigHandler.init(process.env);

function App() {
  let location = useLocation();
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  RestService.init(dispatch);

  /* Calling the function `Utils.getConfigFromDb()` when the component is mounted. */
  useEffect(() => {
    Utils.getConfigFromDb();
  }, [])
  

  /* Setting the default language for the user & starting the timer to refresh the authentication token each X time */
  useEffect(() => {
    var timerId;
    if (AuthHandler.isAuth()) { 
      timerId = setInterval(() => {
        /*var refresh = */RestService.autoRefresh();
      }, ConfigHandler.getConfig().defaults.refreshTimeFrame);

      RestService.get({url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getMe}`})
      .then((response) => response.json()
      .then((data) => ({ data }))
      .then((res) => {
        if(res.data.language != null){
          i18n.changeLanguage(res.data.language.toLowerCase());
        }
      }));
    } else {
      clearInterval(timerId);
    }

    return () => {
      clearInterval(timerId);
    }
  }, []);

  /* Checking if the user is authenticated or not. If the user is not authenticated, it will check if the
  user is on the forgot password page. If the user is on the forgot password page, it will render the
  forgot password page. If the user is not on the forgot password page, it will render the home page.
  If the user is authenticated, it will check if the user is on the first login page. If the user is
  on the first login page, it will render the first login page. If the user is not on the first login
  page, it will render the main page. */
  
  if (!AuthHandler.isAuth()) {
    if(location.pathname === "/forgot/password"){
        return( 
          <> 
          <NoAuthHeader /> 
          <ForgotPasswordLanding /> 
          </> 
        )
    } else {
        return (
          <div className="App">
            {state.isLoading ? <Spinner /> : null}
            <NoAuthHeader />
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <NoAutHomeContainer />
            <Redirect to="/home" />
          </div>
        );
    }
  } else {
      if(location.pathname === "/firstlogin"){
        return (
          <>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <FirstLogin />
          </>
        )
      } else {
        if(!AuthHandler.getFirstLogin()){
          return(
            <Redirect to="/firstlogin" />
          )
        } else {
          return (
            <div className="App">
              {state.loading.isLoading ? <Spinner /> : null}
              <Header />
              <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
              <Navigator />
            </div>
          );
        }
      }
    }
}

export default App;
