import React, { useState } from 'react';
import { Badge, Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ConfigHandler from '../config';
import '../scss/components/Modal.scss';
import { RestService } from '../utils';

function MultiDisablePricebookItemModal({ setShowModal, data, partner, refreshTable, disableRefresher }) {
  const [show, setShow] = useState(true);
  const [ selectedItems, setSelectedItems ] = useState([]);
  const [ selectedItemsDisplay, setSelectedItemsDisplay ] = useState([]);
  const [ showConfirmationModal, setShowConfirmationModal ] = useState(false);
  const [ selectAllStatus, setSelectAllStatus ] = useState(false);

  const { t } = useTranslation();

  const selectItem = (value) => {
    var currentArr = [...selectedItems];
    var currentDisplayArray = [...selectedItemsDisplay];

    if(currentArr.indexOf(value) >= 0){
      currentArr.splice(currentArr.indexOf(value), 1)
      currentDisplayArray.splice(currentDisplayArray.indexOf(value.portalCode), 1)

    } else {
      currentArr.push(value);
      currentDisplayArray.push(value.portalCode);
    }

    setSelectedItems(currentArr);
    setSelectedItemsDisplay(currentDisplayArray);
  }

  const generateTableRows = () => {
    let tableRow = [];

    data.map(value => {
      tableRow.push(
        <tr id={value.id} key={value.id}>
          <td style={{textAlign: 'center'}}> <input type="checkbox" onChange={() => selectItem(value)} id={`ch-${value.id}`} /> </td>
          <td> {value.portalCode} </td>
          <td> {value.description} </td>
          <td> {value.code} </td>
        </tr>
      )
    })

    return tableRow;
  }

  const selectAll = () => {
    var currentArr = [...selectedItems];
    var currentDisplayArray = [...selectedItemsDisplay];

    if(selectAllStatus){
      data.map(value => {
        document.getElementById(`ch-${value.id}`).checked = true;
        currentArr.push(value);
        currentDisplayArray.push(value.portalCode);
      })
    } else {
      data.map(value => {
        document.getElementById(`ch-${value.id}`).checked = false;
        currentArr = [];
        currentDisplayArray = [];
      })
    }

    setSelectedItems(currentArr);
    setSelectedItemsDisplay(currentDisplayArray);
  }


  const deleteRecord = () => {
    RestService.post(
      partner?.id 
          ? {
              url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.disableMultiplePricebook}/?partnerId=${partner.id}`,
              body: selectedItems
          }
          : {
              url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.disableMultiplePricebook}`,
              body: selectedItems
          }
    ).then((response) => response.json().then((res) => {
        if(response.status == 200){
            toast.warning( 'Items disabled!');
            setShow(false); 
            setShowModal(false);
        } else {
            toast.error(t('NOTIFICATIONS.NotificationSomethingWrong'));
            setShow(false); 
            setShowModal(false);
        }
      
      setTimeout(() => {
        refreshTable(!disableRefresher)
        setShowConfirmationModal(false);
        setShow(false);
        setShowModal(false);
      }, 2000);
    }))
  }

  return (
    <>
      <Modal show={show} fullscreen={true} onHide={() => { setShow(false); setShowModal(false); }} className="pricebookMultiDisable">
        {
          showConfirmationModal &&(
          <>
            <div className='overlay'>
            </div>
            <div className='confirmationDiv'>
              <h3 className='title'> Are you sure? </h3>
              <p className='mb-1'> All selected items will be deactivated immediately</p>
              <p className="small my-0">
                <Badge bg="danger">{t('NEWQUOTE.CONFIRMMODAL.AttentionLabel')}</Badge> <b>This action is not reversible!</b>
              </p>
              <Row className='mt-3'>
                <Col md={6}>
                  <button
                    className="fadedBorderButton p-2"
                    onClick={() => {setShowConfirmationModal(false)}}
                  >
                    {t('MODALS.BackButton')}
                  </button>
                </Col>
                <Col md={6}>
                  <button className="fadedButton p-2" onClick={() => deleteRecord()}>
                    {t('MODALS.ProceedButton')}
                  </button>
                </Col>
              </Row>
            </div>
          </>
          )
        }

        <Modal.Header closeButton>
          <span>
            Disable Multiple Pricebook Items 
            <span className='fadedText'>
              {
                partner != null 
                ? ` (${partner.name})`
                : " (Default)"
              }
            </span>
          </span>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <p>Select all items that you want to disable (only item currently visible in the previous table will be listed here).</p>
          </Row>
          <Row className='my-2'>
            <Col md={12}>
              <div className='selectedBox'>
                <div className='title'>Items selected</div>
                <div className='body'>
                  {
                    selectedItemsDisplay.length > 0 ? (
                      selectedItemsDisplay.map(value => {
                        return <div className='selectedItems' key={`selected-${value}`}>{value}</div>
                      })
                    )
                    : <h5 style={{textAlign: 'center'}}>No items selected</h5>
                  }
                </div>
                <div className='bottom'>
                  {selectedItemsDisplay.length > 0 && (
                    <button className='fadedButton' onClick={() => {setShowConfirmationModal(true)}}>
                      Disable
                    </button>
                  )}
                </div>
            </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="tableContainer">
              <table>
                <thead>
                  <tr>
                    <th style={{width: '10%'}} className="text-center">
                      <button className='fadedButton' style={{maxWidth: "100px", borderRadius: '25px'}} onClick={() =>  selectAll()} onMouseDown={() => setSelectAllStatus(!selectAllStatus)}>{ selectAllStatus ? "Deselect All" : "Select All"}
                      </button>
                    </th>
                    <th style={{width: '10%'}}> Portal Code </th>
                    <th style={{width: '60%'}}> Description </th>
                    <th style={{width: '20%'}}> Code </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    generateTableRows()
                  }
                </tbody>
              </table>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MultiDisablePricebookItemModal;
