import React, { useState, useEffect } from 'react';
import { Container, Form, Row, Col, Badge, Spinner, Alert } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import ConfigHandler from '../config';
import { RestService } from '../utils';
import { useTranslation } from 'react-i18next';
import { AuthHandler } from '../utils/AuthHandler';
import QuoteCoverageTable from './QuoteCoverageTable';
import Icon from '@mui/material/Icon';
import Search from '../assets/Search.svg';
import Close from '../assets/delete.svg';
import Utils from '../utils/Utils';
import { toast } from 'react-toastify';
import '../scss/views/NewQuote.scss';

  const QuoteSite = ({ keyID, removeSite, addSite, calculateTotal, editingMode, datas, view, quoteID, partner }) => {
  const [locationMethod, setLocationMethod] = useState('address');
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedStreet, setSelectedStreet] = useState(null);
  const [hasBuildingNumbers, setHasBuildingNumbers] = useState(false);
  const [selectedStreetNumber, setSelectedStreetNumber] = useState(null);
  const [selectedBuildingNumber, setSelectedBuildingNumber] = useState({codiceScalaPalazzina: "", civico: {civico:""}, denominazione: ""});
  const [validated, setValidated] = useState(false);
  const [selectedService, setSelectedService ] = useState(null);
  const [isLoadingContent, setIsLoadingContent] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [tableParams, setTableParams] = useState({istat: null, egon: null, civico: null});
  const [listData, setData] = useState(null);
  const { t } = useTranslation();
  let dataReturn = {};

  useEffect(() => { resetFields(); }, [partner] )
  useEffect(() => {
    if (view === undefined || view === null) { view = false; }
    if (editingMode) {
      if (datas != undefined) {
        setIsLoadingContent(true);
        setSelectedCity({ codiceIstat: datas.istatCode, denominazione: datas.city });
        setSelectedStreet({ codiceEgon: datas.egonCode, denominazione: datas.street });
        setSelectedStreetNumber({ civico: datas.streetNumber });
        setSelectedBuildingNumber({ codiceScalaPalazzina: datas.buildingNumber, civico: {civico: datas.streetNumber}, denominazione: datas.buildingNumber });
        var buttonToClick = document.getElementById(`searchBut-${keyID}`);
        setTimeout(`document.getElementById('searchBut-${keyID}').click()`, 500);
        if (view) {
          setTimeout(`document.getElementById('searchBut-${keyID}').disabled = true`, 600);
        }
      }
    }
  }, []);

  //Custom Styles for React-Select
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      border: '1px solid  rgb(168, 168, 168)',
      alignText: 'left !important',
    }),
    control: (base, state) => ({
      ...base,
      boxShadow: 'none',
      border: state.isFocused && 'none',
      '&:focus-within': {
        border: '1px solid #ed3a7f',
        boxShadow: '0 0 0 3px rgba(255, 211, 198, 0.38)',
      },
      '&:invalid': {
        border: '1px solid green',
      },
    }),
  };

  // handleSelections
  const handleCity = (value) => {
    setSelectedBuildingNumber(null);
    setSelectedStreetNumber(null);
    setSelectedStreet('');
    setSelectedCity(value);

    setData(null);
    setSelectedService(null);
    localStorage.removeItem(`SITE-${keyID}`);
    localStorage.removeItem(`SITE-SELECTED-${keyID}`);
  };

  const handleStreet = (value) => {
    setSelectedBuildingNumber(null);
    setSelectedStreetNumber(null);
    setSelectedStreet(value);

    setData(null);
    setSelectedService(null);
    localStorage.removeItem(`SITE-${keyID}`);
    localStorage.removeItem(`SITE-SELECTED-${keyID}`);
  };

  const handleStreetNumber = (value) => {
    setSelectedBuildingNumber(null);
    setSelectedStreetNumber(value);

    setData(null);
    setSelectedService(null);
    localStorage.removeItem(`SITE-${keyID}`);
    localStorage.removeItem(`SITE-SELECTED-${keyID}`);
  };

  const handleBuildingNumber = (value) => {
    setSelectedBuildingNumber(value);

    setData(null);
    setSelectedService(null);
    localStorage.removeItem(`SITE-${keyID}`);
    localStorage.removeItem(`SITE-SELECTED-${keyID}`);
  };

  const loadMunicipality = (inputValue, callback) => {
//console.log('loadMunicipality',inputValue);
    if (inputValue.length >= ConfigHandler.getConfig().defaults.minInputLength) {
      return Utils.handleInputSearch(inputValue).then((text) => { 
        return RestService.get({ 
          url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getMunicipality}?q=${text}`,
          showLoader: false
        }).then((response) =>
          response.json()
            .then((data) => ({ data }))
            .then((res) => {
              if(response.status == 200){
                callback(res.data);
                return res.data;
              } else {
                setErrorMessage(true);
                return []
              }
            }),
        )
      });
    }
  };

  const loadStreets = (inputValue, callback) => {
//console.log('loadStreets',inputValue);
    if (inputValue.length >= ConfigHandler.getConfig().defaults.minInputLength) {
      if (selectedCity != null) {
        return Utils.handleInputSearch(inputValue).then((text) => { 
          return RestService.get({
            url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getStreet}?istatCode=${selectedCity.codiceIstat}&q=${inputValue}`,
            showLoader: false
          }).then((response) =>
            response.json()
              .then((data) => ({ data, }))
              .then((res) => {
                if(response.status == 200){
//console.log(res.data, inputValue);
//                  res.data=res.data.filter((item) => { return item.denominazione.toUpperCase().startsWith(inputValue.toUpperCase()); });
/*
                  res.data=res.data.sort((a,b) => { 
                    return 10 * (
                                  a.denominazione.toUpperCase().indexOf(inputValue.toUpperCase())
                                  >
                                  b.denominazione.toUpperCase().indexOf(inputValue.toUpperCase()
                                ) ? 1 : -1)
                         +  1 * (a.denominazione < b.denominazione ? 1 : - 1);
                  });
*/
                  callback(res.data);
                  return res.data;
                } else {
                  setErrorMessage(true);
                  return []
                }
              }),
          );
        });
      }
    }
  };

  const loadStreetNumber = (inputValue, callback) => {
//console.log('loadStreetNumber',inputValue);
    if (selectedCity != null) {
      return RestService.get({
        url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getStreetNumber}?istatCode=${selectedCity.codiceIstat}&egonCode=${selectedStreet.codiceEgon}&q=${inputValue}`,
        showLoader: false
      }).then((response) =>
        response.json()
          .then((data) => ({ data, }))
          .then((res) => {
            if(response.status == 200){
              res.data=res.data.filter((item) => { return item.civico.startsWith(inputValue); });
              callback(res.data);
              return res.data;
            } else {
              setErrorMessage(true);
              return []
            }
          }),
      );
    }
  };

  const loadBuildingNumber = (inputValue, callback) => {
    setHasBuildingNumbers(false);
    if (selectedStreetNumber != null) {
      return RestService.get({
        url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getBuildingNumber}?istatCode=${selectedCity.codiceIstat}&egonCode=${selectedStreet.codiceEgon}&streetNumber=${selectedStreetNumber.civico}&q=${inputValue}`,
        showLoader: false
      }).then((response) =>
        response.json()
          .then((data) => ({ data, }))
          .then((res) => {
            if(response.status == 200){
              res.data=res.data.filter((item) => { return item.denominazione.startsWith(inputValue); });
              setHasBuildingNumbers(res.data.length>0);
              callback(res.data);
              return res.data;
            } else {
              setErrorMessage(true);
              return []
            }
          }),
      );
    }
  };

  const HandleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false || selectedCity === null || selectedStreet === null) {
      event.stopPropagation();
      setValidated(false);
      setData(null);
      return null;
    }

    setValidated(true);
    setTableParams({istat: selectedCity.codiceIstat, egon: selectedStreet?selectedStreet.codiceEgon:'', civico: selectedStreetNumber?selectedStreetNumber.civico:''});

//bisogna creare la stringa corretta senza null e senza undefined
    let restCmd = `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getCoverage}?`;
/*
    restCmd += 'istatCode='+(!selectedCity?'':selectedCity.codiceIstat)+'&';
    restCmd += 'egonCode='+(!selectedStreet?'undefined':selectedStreet.codiceEgon)+'&';
    restCmd += 'streetNumber='+(!selectedStreetNumber?'undefined':selectedStreetNumber.civico)+'&';
    restCmd += 'buildingNumber='+(!selectedBuildingNumber?'undefined':selectedBuildingNumber.denominazione)+'&';
*/
    if(!!selectedCity            && !!selectedCity.codiceIstat)              restCmd += `istatCode=${selectedCity.codiceIstat}&`;
    if(!!selectedStreet          && !!selectedStreet.codiceEgon)             restCmd += `egonCode=${selectedStreet.codiceEgon}&`;
    if(!!selectedStreetNumber    && !!selectedStreetNumber.civico)           restCmd += `streetNumber=${selectedStreetNumber.civico}&`;
    //if(!!selectedBuildingNumber  && !!selectedBuildingNumber.denominazione)  restCmd += `buildingNumber=${selectedBuildingNumber.denominazione}&`;
    if(!!selectedBuildingNumber  && !!selectedBuildingNumber.denominazione)  restCmd += "buildingNumber=" + encodeURI(selectedBuildingNumber.denominazione) + "&";
    if(!!partner                 && !!partner.id)                            restCmd += `partnerId=${partner.id}&`;
//console.log(restCmd);
    RestService.get(
        { url: restCmd }
      ).then((response) =>
      response.json()
        .then((data) => ({ data: data, }))
        .then((res) => {
          dataReturn = res.data.servizi;
          setData(dataReturn);
        }),
    );

    localStorage.setItem(`SITE-${keyID}`, JSON.stringify({ SITE: keyID, istat: selectedCity.codiceIstat }));
  };

  const options = [
    {
      label: 'Address',
      value: 'address',
      selectedBackgroundColor: '#fee8e6',
      selectedFontColor: '#ff6033',
    },
    {
      label: 'Coordinates',
      value: 'coordinates',
      selectedBackgroundColor: '#fee8e6',
      selectedFontColor: '#ff6033',
    },
  ];

  const initialSelectedIndex = options.findIndex(({ value }) => value === 'address');

  const onChange = (newValue) => {
    setLocationMethod(newValue);
  };

  const resetFields = () => {
    setSelectedStreet(null);
    setSelectedCity(null);
    setSelectedStreetNumber(null);
    //setSelectedBuildingNumber({codiceScalaPalazzina: "", civico: {civico:""}, denominazione: ""});
    setSelectedBuildingNumber(null);
    setData(null);
    setSelectedService(null);
    localStorage.removeItem(`SITE-${keyID}`);
    localStorage.removeItem(`SITE-SELECTED-${keyID}`);
  }

  return (
    <Container fluid className='my-3 quoteSiteContainer'>
      <Row>
        <Row className='heading' style={{margin: 'auto'}}>
          <Col md={3} sm={12} className="left">
            <div className='siteHeadingTitle'>
              {(keyID > 0) && (
                <div className="iconBox"  onClick={() => { removeSite(keyID) }}>
                  {view === false && (<Icon>delete</Icon>)}
                </div>
              )}
              <div>
                SITE {keyID + 1}
              </div>
            </div>
          </Col>
          <Col md={6} className="middle item-center">
               {(selectedService != null) && (
                  <>
                    <Icon className='fadedText'>assignment_turned_in</Icon> <b style={{verticalAlign: 'top'}}>{selectedService}</b> 
                  </>
                )}
          </Col>
          <Col md={3} className="right">
            <div className='siteHeadingAdd' onClick={() => { addSite() }}> 
              {(view == false) && (
                  <div className='iconBox'>
                    <Icon>add</Icon>
                  </div>
              )}
              <div className='textBox'>
                {t('NEWQUOTE.SITE.AddSiteLabel')}
              </div>
            </div>
          </Col>
        </Row>
        <Row className='content pb-3' style={{margin: 'auto'}}>
          {(isLoadingContent) && (
              <div className='isLoading item-center'>
                <Spinner animation="grow" variant="light" />
              </div>
          )}
          <Col md={12} className="px-1 py-3">
            <Row className='my-2'>
              <Col md={12}>
                {((partner != null) && (AuthHandler.getUserData().role == 'ADMIN')) && (
                  <Badge bg="danger" style={{width: '100%'}}>* Coverage informations with Custom Pricebook of <b>{partner?.name}</b></Badge>  
                )}
              </Col>
            </Row>
            <Form noValidate validated={validated} onSubmit={HandleSubmit} className="AdminNewUSer" id="coverageParams-form">
              <Row className='p-1'>
                <Form.Group as={Col} md={3} controlId="CitySelection" >
                  <p className='label-form'>
                    {t('FORMS.City')}
                  </p>

                  <AsyncSelect
                    className="AdminNewUSer__AsyncSelect_Company"
                    cacheOptions
                    //defaultValue=''
                    //isClearable
                    value={selectedCity}
                    noOptionsMessage={() => t('OTHERS.Min3CharLabel')}
                    loadOptions={loadMunicipality}
                    onChange={handleCity}
                    getOptionLabel={(e) => e.denominazione}
                    getOptionValue={(e) => e.codiceIstat}
                    styles={customStyles}
                    placeholder={t('NEWQUOTE.SITE.SelectCity')}
                    isDisabled={view ? true : false}
                  />
                </Form.Group>
                <Form.Group as={Col} md={4} controlId="StreetSelection">
                  <p className='label-form'>
                    {t('FORMS.Street')}
                  </p>
                  <AsyncSelect
                    className="AdminNewUSer__AsyncSelect_Company"
                    cacheOptions
                    //defaultValue=''
                    //isClearable
                    noOptionsMessage={() => t('OTHERS.Min3CharLabel')}
                    value={selectedStreet}
                    loadOptions={loadStreets}
                    onChange={handleStreet}
                    getOptionLabel={(e) => e.particellaToponomastica ? `${e.particellaToponomastica} ${e.denominazione}` : e.denominazione }
                    getOptionValue={(e) => e.codiceIstat}
                    styles={customStyles}
                    placeholder={t('NEWQUOTE.SITE.SelectStreet')}
                    isDisabled={!selectedCity || view === true ? true : false}
                  />
                </Form.Group>
                <Form.Group as={Col} md={2} controlId="StreetNumber">
                  <p className='label-form'>
                    {t('FORMS.StreetNumLabel')}
                  </p>
                  <AsyncSelect
                    className="AdminNewUSer__AsyncSelect_Company"
                    cacheOptions
                    //defaultValue=''
                    //isClearable
                    //key={selectedStreet == null ? 'key_StreetNumber' : selectedStreet.codiceIstat}
                    key={!selectedStreet ? null : 'key_Street_'+selectedStreet.codiceEgon}
                    defaultOptions
                    noOptionsMessage={() => t('OTHERS.MinCharLabel')}
                    value={selectedStreetNumber}
                    loadOptions={loadStreetNumber}
                    onChange={handleStreetNumber}
                    getOptionLabel={(e) => e.civico}
                    getOptionValue={(e) => e.civico}
                    styles={customStyles}
                    classNamePrefix="asyncselect"
                    placeholder={t('NEWQUOTE.SITE.SelectStreetNumber')}
                    isDisabled={!selectedStreet || view === true ? true : false}
                />
                </Form.Group>
                <Form.Group as={Col} md={2} controlId="BuildingNumber">
                  <p className='label-form'>
                    {t('FORMS.BuildingNumberLabel')}
                  </p>
                  <AsyncSelect
                    className="AdminNewUSer__AsyncSelect_Company"
                    cacheOptions
                    //defaultValue=''
                    //isClearable
                    //key={selectedStreetNumber == null ? 'key_BuildingNumber' : selectedStreetNumber}
                    key={!selectedStreetNumber ? null : 'key_StreetNumber_'+selectedStreetNumber.civico}
                    defaultOptions
                    noOptionsMessage={() => t('OTHERS.NoDataMessage')}
                    value={selectedBuildingNumber}
                    loadOptions={loadBuildingNumber}
                    onChange={handleBuildingNumber}
                    getOptionLabel={(e) => e.denominazione}
                    getOptionValue={(e) => e.codiceScalaPalazzina}
                    classNamePrefix="asyncselect"
                    styles={customStyles}
                    placeholder={t('NEWQUOTE.SITE.SelectBuildingNumber')}
                    isDisabled = { !hasBuildingNumbers || view === true ? true : false}
                    //isDisabled={!selectedStreetNumber || !selectedStreetNumber.Civico || !hasBuildingNumbers || view === true ? true : false}
                />
                </Form.Group>
                <Form.Group as={Col} md={1} className="item-center">
                  <Row>
                    <p className='label-form'>
                      <br />
                    </p>
                    <Col md={6}>
                      <button id={`searchBut-${keyID}`} type="submit" className="btn searchButton">
                        <img src={Search} />
                      </button>
                    </Col>
                    <Col md={6}>
                      {(selectedStreetNumber != null && selectedStreet) && (
                        <button id={`resetBut-${keyID}`} onClick={() => resetFields()} className="btn searchButton">
                          <img src={Close} />
                        </button>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
              </Row>
            </Form>
          </Col>
          <Row>
          {listData != null ? (
            <QuoteCoverageTable
              istat={tableParams.istat}
              egon={tableParams.egon}
              civico={tableParams.civico}
              data={listData}
              keyID={keyID}
              city={selectedCity.denominazione}
              particellaToponomastica={selectedStreet.particellaToponomastica}
              street={!selectedStreet?'':selectedStreet.denominazione}
              snumber={!selectedStreetNumber?'':selectedStreetNumber.civico}
              bnumber={!selectedBuildingNumber?'':selectedBuildingNumber.denominazione}
              calculateTotal={calculateTotal}
              key={`QuoteCoverageTable-${keyID}`}
              editingMode={editingMode}
              serviceID={editingMode === true && datas != undefined ? datas : null}
              view={view}
              quoteID={quoteID}
              partner={partner}
              setSelectedService={setSelectedService}
              setIsLoadingContent={setIsLoadingContent}
            />
          ) : (
            <Col className="item-center">{validated ? <p>{t('NEWQUOTE.COVERAGE.NoServices')}</p> : null}</Col>
          )}
        </Row>
        </Row>
      </Row>
    </Container>
  );
};

export default QuoteSite;
