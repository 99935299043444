import jwt_decode from 'jwt-decode';

const KEYS = {
  AUTH_CHUNK: 'ac',
  ACCESS: 'accessToken',
  TOKEN_TYPE: 'tokenType',
  REFRESH: 'refreshToken',
  FIRSTLOGIN: 'firstLogin',
};

function getKey(key) {
  let data = localStorage.getItem('ac');
  return JSON.parse(atob(data))[key];
}

export const AuthHandler = {
  setAuth: (obj) => {
    const keyJsonify = btoa(JSON.stringify(obj).toString());
    localStorage.setItem(KEYS.AUTH_CHUNK, keyJsonify);
  },
  getToken: () => {
    return getKey(KEYS.TOKEN_TYPE) + ' ' + getKey(KEYS.ACCESS);
  },
  getRefreshToken: () => {
    return getKey(KEYS.REFRESH);
  },
  getUserData: () => {
    const userToken = getKey(KEYS.ACCESS);
    const decoded = jwt_decode(userToken);
    const userData = decoded.user;
    return JSON.parse(userData);
  },
  isAuth: () => {
    return Boolean(localStorage.getItem(KEYS.AUTH_CHUNK));
  },
  clearAuth: () => {
    localStorage.removeItem(KEYS.AUTH_CHUNK);
    window.location.reload();
  },
  getTokenData: () => {
    const userToken = getKey(KEYS.ACCESS);
    const decoded = jwt_decode(userToken);
    return decoded;
  },
  getFirstLogin: () => {
    return getKey(KEYS.FIRSTLOGIN);
  }
};
