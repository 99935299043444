import React, { Fragment, useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import LoginResponsive from "../containers/LoginResponsive";
import Registration from "../components/Registration";
import BrandCarousel from "../components/BrandCarousel";
import FooterResponsive from "../containers/FooterResponsive";
import { useTranslation } from 'react-i18next';
import ForgotPasswordModal from "../components/ForgotPasswordModal";

import "../scss/views/NoAutHome.scss";
import {default as SupportIMG} from '../assets/support.svg'
import {default as ResourceIMG} from '../assets/resources.svg'
import {default as ReportIMG }from '../assets/report.svg'


const NoAutHomeContainer = () => {
  const { t } = useTranslation();
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);

  return (
    <Container fluid style={{margin: '0px', padding: '0px'}}>
      { (showForgotPasswordModal) && (<ForgotPasswordModal setShowForgotPasswordModal={setShowForgotPasswordModal} />)}
      <div className="MainContainer">
        <Row>
          <Col md={12}>
            <LoginResponsive setShowForgotPasswordModal={setShowForgotPasswordModal} setShowRegistration={setShowRegistration} />
          </Col>
        </Row>
        <Row>
          <div className="UnderLogin">
            <h2 style={{marginTop: '20%'}}>DEMO</h2>
            <h3>FT Partner Portal</h3>
          </div>
        </Row>

        <Row>
          <div className="MiddleComponent">
            <Row>
              <Col className="mb-5">
                <h2 className="title">{t('MIDDLESECTION.MiddleHeading')}</h2>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 6, offset: 3 }}>
                <Row>
                  <Col md={4}>
                    <h3 className="subTitle">{t('MIDDLESECTION.SupportLabel')}</h3>
                    <div className="imageIcon">
                      <img src={SupportIMG} style={{width: '30px'}}/>
                    </div>
                    <p>
                    {t('MIDDLESECTION.SupportDescription')}
                    </p>
                  </Col>
                  <Col md={4}>
                    <h3 className="subTitle">{t('MIDDLESECTION.ResourceLabel')}</h3>
                    <div className="imageIcon">
                      <img src={ResourceIMG} style={{width: '30px'}}/>
                    </div>
                    <p>
                      {t('MIDDLESECTION.ResourceDescription')} 
                    </p>
                  </Col>
                  <Col md={4}>
                    <h3 className="subTitle">{t('MIDDLESECTION.ReportingLabel')}</h3>
                    <div className="imageIcon">
                      <img src={ReportIMG} style={{width: '30px'}}/>
                    </div>
                    <p>
                      {t('MIDDLESECTION.ReportingDescription')}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Row>
        <Row>
        <div className="RegContainer">
          {
            (showRegistration)&&(
              <div id="reg" className="regCont">
                <Registration />
              </div>
            )
          }
        </div>
        </Row>
        <Row>
          <div className="CarouselContainer">
            <BrandCarousel />
          </div>
        </Row>
      </div>
      <Row className="m-4">
        <Col md={12}>
          <FooterResponsive />
        </Col>
      </Row>
    </Container>
  );
};
export default NoAutHomeContainer;
