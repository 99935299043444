import React, { /*Fragment, */useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../scss/views/Header.scss';
import Logoheader from '../assets/Logoheader.svg';
import Fx from '../components/Fx';
//import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import listenForOutsideClick from '../components/listen-for-outside-clicks';
//import ConfigHandler from '../config';
import Icon from '@mui/material/Icon';
//import { RestService } from '../utils';
import FlagIT from '../assets/flags/it.svg';
import FlagEN from '../assets/flags/gb.svg';
//import ResponsiveMenu from '../components/ResponsiveMenu';

const NoAuthHeader = ({ show, onHide }) => {
  const { t, i18n } = useTranslation();
  const [isOpen, setShow] = useState(false);

  /* to write it down to console */
/*
  const [input, setInput] = useState('');
  const [SubmittedInput, setSubmittedInput] = useState('');
  const [openPassword, setOpenPassword] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const dispatch = useDispatch();
  const [showResponsiveMenu, setShowResponsiveMenu] = useState(false);
*/
  const [languageDropdown, setLanguageDropdown] = useState(false);

  
  //States for Outside of Div click of filters
  const headerRef = useRef(null);
  const langSwitchRef = useRef(null);
  const [listening, setListening] = useState(false);
  useEffect(listenForOutsideClick(listening, setListening, headerRef, setShow));

  /* listen for outsedi clicks for notification bell */
  const [listentLangChange, setListentLangChange] = useState(false);
  useEffect(listenForOutsideClick(listentLangChange, setListentLangChange, langSwitchRef, setLanguageDropdown));

  const handleLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div>
      <div className="header NoAuthHeader">
        {/* ##### Header Col sinistra ####### */}
        <div className="header__col_sx">
          <div className="header__col_sx_logo">
            <a href="https://fibertelecom.it/">
              <img src={Logoheader} alt="" />
            </a>
          </div>

          {/* #### Left side Brandname #### */}
          <span>
            <div className="header__col_sx_brandname">
              <span className="brandname_text-style">FT</span> PARTNER PORTAL (dev)
            </div>
          </span>
        </div>

        {/* ###### Column destra ##### */}

        <div className="col__dx">
        <div>
            <Link to="/home">
              <Icon
                style={{
                  fontSize: '30px',
                  marginRight: '10px',
                  color: '#fff'
                }}>
                home
              </Icon>
            </Link>
        </div>
          <div
            className="languageselector"
            onClick={(e) => {
              setLanguageDropdown(!languageDropdown);
              e.stopPropagation();
            }}
            ref={langSwitchRef}>
            <div className="trigger">
              <Icon
                style={{
                  fontSize: '30px',
                  marginRight: '10px'
                }}>
                language
              </Icon>
              <span style={{ textTransform: 'uppercase' }}>{i18n.language}</span>
            </div>
            {languageDropdown && (
              <div className="subdiv">
                <ul>
                  <li onClick={() => handleLanguage('it')} style={{display: 'flex'}}>
                    <img alt="italiano" src={FlagIT} style={{width: '1.33em', borderRadius: '50%'}} /> {t('LANGUAGES.ItalianLabel')}
                  </li>
                  <li onClick={() => handleLanguage('en')} style={{display: 'flex'}}>
                  <img alt="english" src={FlagEN} style={{width: '1.33em', borderRadius: '50%'}} />  {t('LANGUAGES.EnglishLabel')}
                  </li>
                </ul>
              </div>
            )}
          </div>
          
          {/* ## right side Fiber telecom written part ## */}
          <div
            className="col__dx_brandname_container"
            onClick={(e) => {
              setShow(!isOpen);
              e.stopPropagation();
            }}>
          </div>
        </div>
        <Fx />
      </div>
    </div>
  );
};

export default NoAuthHeader;
