import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import { AuthHandler } from "../utils/AuthHandler";
import { useDispatch } from "react-redux";
import { setSidebarStatus } from "../store/actions/sidebar";
import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import Unauthorized from "./403";

const ComponentRouteWithSubRoutes = (route) => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    
    useEffect(() => {
        (route.breadcrumbs) && (dispatch(setSidebarStatus(route.breadcrumbs.mainSlug, route.breadcrumbs.mainTitle[i18n.language], route.breadcrumbs.subSlug, route.breadcrumbs.subTitle[i18n.language] )))
    }, [])

    /* Checking if the user is logged in and if the user is logged in it is checking if the user has
    the right role to access the route. */
    if(AuthHandler.isAuth()){
        const userRole = AuthHandler.getUserData();
        if((route.roles != undefined) || (route.roles != null)){
            if(route.roles.toString().toLowerCase().includes(userRole.role[0].toLowerCase())){
            }
            else{
                return(<Unauthorized />)
            }
        }
    }
    
    if(route?.exact){
        return (
            <Route
                path={route.path}
                exact={route.exact}
                render={(props) => (
                    <route.component {...props} routes={route.routes} />
                )}
            />    
        );
    } else {
        return (
            <Route
                path={route.path}
                render={(props) => (
                    <route.component {...props} routes={route.routes} />
                )}
            />    
        );
    }
};

const RouteWithSubRoutes = ({ routes }) => {
    
    if (!routes)
        routes = require('../routes').default
        
    return (
        <Switch>
            {routes.map((route, i) => (
                <ComponentRouteWithSubRoutes key={i} {...route} />
            ))}
        </Switch>
    );
};
export default RouteWithSubRoutes;