const Sidebar = (state = {
    active_slug: null,
    active_title: null,
    sub_slug: null,
    sub_title: null
}, action) => {
    switch (action.type) {
        case 'sidebar/setSidebarStatus':
            return {
                ...state,
                active_slug: action.mSlug,
                active_title: action.mTitle,
                sub_slug: action.sSlug,
                sub_title: action.sTitle
            }
        default: return state
    }
}

export default Sidebar