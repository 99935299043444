import React, { /*Fragment, */useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../scss/views/Header.scss';
import Chevron from '../assets/Chevron.png';
import Chevron_down from '../assets/Chevron_down.svg';
import Logoheader from '../assets/Logoheader.svg';
import Fx from '../components/Fx';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
//import { useDispatch } from 'react-redux';
import { AuthHandler } from '../utils/AuthHandler';
import Breadcrumbs from '../components/Breadcrumbs';
import ModalPasswordChange from '../components/ModalPasswordChange';
import { useTranslation } from 'react-i18next';
import listenForOutsideClick from '../components/listen-for-outside-clicks';
//import ConfigHandler from '../config';
import Icon from '@mui/material/Icon';
//import { RestService } from '../utils';
import FlagIT from '../assets/flags/it.svg';
import FlagEN from '../assets/flags/gb.svg';
import ResponsiveMenu from '../components/ResponsiveMenu';

const Header = ({ show, onHide }) => {
  const { t, i18n } = useTranslation();
  const [isOpen, setShow] = useState(false);
  /* to write it down to console */
//  const [input, setInput] = useState('');
//  const [SubmittedInput, setSubmittedInput] = useState('');
  const [openPassword, setOpenPassword] = useState(false);
//  const [isNotification, setIsNotification] = useState(false);
//  const dispatch = useDispatch();
  const [languageDropdown, setLanguageDropdown] = useState(false);
  const [showResponsiveMenu, setShowResponsiveMenu] = useState(false);

  const userData = AuthHandler.getUserData();
  const handleLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  
  //States for Outside of Div click of filters
  const headerRef = useRef(null);
  const langSwitchRef = useRef(null);
  const [listening, setListening] = useState(false);
  useEffect(listenForOutsideClick(listening, setListening, headerRef, setShow));

  /* listen for outsedi clicks for notification bell */
  const [listentLangChange, setListentLangChange] = useState(false);
  useEffect(listenForOutsideClick(listentLangChange, setListentLangChange, langSwitchRef, setLanguageDropdown));

  return (
    <div>
      {openPassword && <ModalPasswordChange show={true} setOpenPassword={setOpenPassword} />}
      {/* ##### Header Container ###### */}
      <div className="header">
        {/* ##### Header Col sinistra ####### */}
        <div className="header__col_sx">
          <div className="header__col_sx_logo">
            <a href="https://fibertelecom.it/">
              <img src={Logoheader} alt="" />
            </a>
          </div>

          {/* #### Left side Brandname #### */}
          <span>
            <div className="header__col_sx_brandname">
              <span className="brandname_text-style">FT</span> PARTNER PORTAL (v.24h28a.dev)
            </div>
          </span>
        </div>

        {/* ###### Column destra ##### */}

        <div className="col__dx">
          {/* ## consist of search input and search icin ## 
        <div className="col__dx_search_container">
          <input
            type="search"
            id="gsearch"
            name="gsearch"
            placeholder="Search"
            className="search"
            value={input}
            onChange={(e)=>{setInput(e.target.value)}}
          />
          
          //search Icon 
          <img src={search} alt="" onClick={()=>{setSubmittedInput(input)}} />
        </div> */}

          {/* notification icon */}
          {/* <div
            className="col__dx_notification_icon"
            onClick={(e) => {
              setIsNotification(!isNotification);
              e.stopPropagation();
            }}>
            <img src={Notification} alt="" />
          </div> */}
          {/* Notification information comes when clicked! Later we can use it */}
          {/* {isNotification && 
        <div className="col__dx_notification_icon_onClick" ref={headerRef}></div>
        } */}

          {/* Pipe
          <div className="col__dx_pipe"></div> */}
          <div className='burgermenu' onClick={(e) => { setShowResponsiveMenu(true); e.stopPropagation(); }}>
            <Icon style={{fontSize: '30px'}}>menu</Icon>
          </div>
          <div
            className="languageselector"
            onClick={(e) => {
              setLanguageDropdown(!languageDropdown);
              e.stopPropagation();
            }}
            ref={langSwitchRef}>
            <div className="trigger">
              <Icon
                style={{
                  fontSize: '30px',
                  marginRight: '10px'
                }}>
                language
              </Icon>
              <span style={{ textTransform: 'uppercase' }}>{i18n.language}</span>
            </div>
            {languageDropdown && (
              <div className="subdiv">
                <ul>
                  <li onClick={() => handleLanguage('it')} style={{display: 'flex'}}>
                    <img alt="italiano" src={FlagIT} style={{width: '1.33em', borderRadius: '50%'}} /> {t('LANGUAGES.ItalianLabel')}
                  </li>
                  <li onClick={() => handleLanguage('en')} style={{display: 'flex'}}>
                    <img alt="english" src={FlagEN} style={{width: '1.33em', borderRadius: '50%'}} />  {t('LANGUAGES.EnglishLabel')}
                  </li>
                </ul>
              </div>
            )}
          </div>
          
          {/* ## right side Fiber telecom written part ## */}
          <div
            className="col__dx_brandname_container"
            onClick={(e) => {
              setShow(!isOpen);
              e.stopPropagation();
            }}>
            <div className="col__dx_brandname_container_first">
              <Icon
                style={{
                  fontSize: '30px',
                  display: 'inline-block',
                  verticalAlign: 'sub',
                  marginRight: '10px'
                }}>
                account_circle
              </Icon>
              <div className="col__dx_brandname_container_first_brand">
                <span>{userData && AuthHandler.isAuth() ? userData.username : 'Null'}</span>
              </div>

              <div className="col__dx_brandname_container_first_icon">
                <img src={isOpen ? Chevron_down : Chevron} alt="" />
              </div>
            </div>

            {isOpen && (
              <div className="col__dx_brandname_container_isOpen_bg">
                <div className="col__dx_brandname_container_Onclick" ref={headerRef}>
                  <Link to="/user/profile" className="col__dx_brandname_container_Onclick_parag1 bg__myProfile">
                      <p className="col__dx_brandname_container_Onclick_parag1">{t('NAVIGATION.ProfileLabel')}</p>
                  </Link>

                  {((userData.role[0].toLowerCase() === 'admin' || userData.role[0].toLowerCase() === 'broker') && AuthHandler.isAuth() ) && (
                    <Link to="/admin" className="col__dx_brandname_container_Onclick_parag2 bg__admin">
                      <p>{t('NAVIGATION.AdminLabel')}</p>
                    </Link>
                  )}

                  <p
                    onClick={() => {
                      setOpenPassword(!openPassword);
                    }}
                    className="bg__ChangePassword col__dx_brandname_container_Onclick_parag3">
                    {t('NAVIGATION.ChangePasswordLabel')}{' '}
                  </p>

                  <hr className="col__dx_brandname_container_Onclick_hr" />

                  <div className="bg__logout">
                    <p
                      className="col__dx_brandname_container_Onclick_parag4 "
                      onClick={() => {
                        AuthHandler.clearAuth();
                      }}>
                      <ExitToAppIcon /> {t('NAVIGATION.LogoutLabel')}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Fx />
      </div>
      <div className="breadCrumbs">
        <Breadcrumbs />
      </div>
      {
        (showResponsiveMenu) && (<ResponsiveMenu setShowResponsiveMenu={setShowResponsiveMenu} handleLanguage={handleLanguage} setOpenPassword={setOpenPassword} />)
      }
    </div>
  );
};

export default Header;
