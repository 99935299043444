import React, { useState, useEffect } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import '../scss/views/MyProfile.scss';
import { RestService } from '../utils';
import ConfigHandler from '../config';
import AsyncSelect from 'react-select/async';
import { useTranslation } from 'react-i18next';
import { setSidebarStatus } from '../store/actions/sidebar';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const MyProfile = () => {
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [userData, setUserData] = useState('');
  const [selectedValueRole, setSelectedValueRole] = useState("");
  const [roleValues, setRoleValues] = useState([]);
  const [langValue, setLangValue] = useState();
  const location = useLocation();
  const [send, setSend] = useState(false);

  useEffect(() => {
    RestService.get({
      url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getMe}`,
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data,
        }))
        .then((res) => {
          setUserData(res.data);
          setSelectedValueRole(res.data.role);
          setSelectedValueRole(res.data.roleId);
          setLangValue(res.data.language);
        }),
    );
  }, []);

  const getRoleValues = () => {
    return RestService.get({
      url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getRoles}`,
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data,
        }))
        .then((res) => {
          var roleValues = [];
          if (res.data && res.data.length > 0) {
            for (var i = 0; i <= res.data.length; i++) {
              if (res.data[i]) {
                roleValues.push(
                  <option value={res.data[i].id} key={res.data[i].id}>
                    {res.data[i].description}
                  </option>,
                );
              }
            }
          }
          setRoleValues(roleValues);
        }),
    );
  };

  useEffect(() => {
    getRoleValues();
  }, []);

  useEffect(() => {
    if (location.state != undefined) {
      setSelectedValueRole(location.state.data.roleId);
    }
  }, [location.state]);

  const HandleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    
    if (form.checkValidity()){
      RestService.put({
        url: `${ConfigHandler.getConfig().api.baseUrl}${
          ConfigHandler.getConfig().api.path.registration
        }`,
        headers: { 'Content-Type': 'application/json' },
        body: {
          id: userData.id,
          name: form.validationFirstName.value,
          surname: form.validationLastName.value,
          username: userData.username,
          enabled: userData.enabled,
          roleId: userData.roleId,
          partnerId: userData.partner.id,
          language: langValue
        }
      }).then((res) => {
        if (res.status > 199 && res.status < 300) {
          toast.success(t('MYPROFILE.NotificationModified'));
        } else {
          toast.error(t('NOTIFICATIONS.NotificationSomethingWrong'));
        }
      });
    }

    setValidated(true);
  };

  // handle selection role
  const handleChangeRole = (event, index) => {
    setSelectedValueRole(event.target.value);
  };
  /* ## I gave styles of the AsyncSelect part inside the component because the documentation shows it like this  ## */
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      border: '1px solid  rgb(168, 168, 168)',
    }),
    control: (base, state) => ({
      ...base,
      boxShadow: 'none',
      border: state.isFocused && 'none',
      '&:focus-within': {
        border: '1px solid #ed3a7f',
        boxShadow: '0 0 0 3px rgba(255, 211, 198, 0.38)',
      },
      '&:invalid': {
        border: '1px solid green',
      },
    }),
  };

  return (
    <Form noValidate validated={validated} onSubmit={HandleSubmit} className="px-3 mx-3">
      <h3 className="fadedText"> {t('MYPROFILE.PageTitle')}</h3>
      {/* ## FirstName and LastName ## */}
      <Row className="mb-3 mx-3">
        <Form.Group as={Col} md="6" controlId="validationFirstName">
          <Form.Label className="float-start MyProfile_labels ">
            {t('FORMS.NameLabel')} <span className="star_symbol">*</span>
          </Form.Label>
          <Form.Control
            required
            type="text"
            placeholder={`${t('FORMS.NamePlaceholder')}`}
            defaultValue={userData != '' ? userData.name : null}
          />
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="validationLastName">
          <Form.Label className="float-start MyProfile_labels">
            {t('FORMS.SurnameLabel')} <span className="star_symbol">*</span>
          </Form.Label>
          <Form.Control
            required
            type="text"
            placeholder={`${t('FORMS.SurnamePlaceholder')}`}
            defaultValue={userData !== '' ? userData.surname : null}
          />
        </Form.Group>
      </Row>
      <Row className="mb-3 mx-3">
        <Form.Group as={Col} sm="12" controlId="username" md="12">
          <Form.Label className="float-start MyProfile_labels">{t('FORMS.Username')}</Form.Label>
          <Form.Control type="text" placeholder={t('FORMS.UsernamePlaceholder')} defaultValue={userData !== '' ? userData.username : null} disabled />
        </Form.Group>
      </Row>
      {/* ##### Email, Phone, Your Role ####### */}
      <Row className="mb-3 mx-3">
        <Form.Group as={Col} sm="4" controlId="validationEmail" md="6">
          <Form.Label className="float-start MyProfile_labels">{t('FORMS.PreferedLanguageLabel')}</Form.Label>
          <Form.Select
            onChange={(e) => setLangValue(e.target.value)}
            value={langValue}
            className="MyProfile__AsyncSelect_role"
            >
            <option>{t('FORMS.PreferedLanguageDefaultOption')}</option>
            <option value="EN"> {t('LANGUAGES.EnglishLabel')} </option>
            <option value="IT"> {t('LANGUAGES.ItalianLabel')} </option>
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} sm="4" controlId="validationYourRole" md="6">
          <Form.Label className="float-start MyProfile_labels">{`${t('FORMS.RoleLabel')}`} </Form.Label>
          <Form.Select
            onChange={handleChangeRole}
            value={selectedValueRole}
            className="MyProfile__AsyncSelect_role"
            disabled>
            <option>{t('FORMS.RoleSelect')}</option>
            {roleValues}
          </Form.Select>
        </Form.Group>
      </Row>

      <Row className="mb-3 mx-3">
        <Form.Group as={Col} sm="4" controlId="userCompany" md="6">
          <Form.Label className="float-start MyProfile_labels">{t('FORMS.CompanyNameLabel')}</Form.Label>
          <Form.Control type="text" placeholder={t('FORMS.CompanyNameLabel')} defaultValue={userData !== '' ? userData.partner.name : null} disabled />
        </Form.Group>

        <Form.Group as={Col} sm="4" controlId="userDateOfCreation" md="6">
          <Form.Label className="float-start MyProfile_labels">{t('FORMS.CreationDateLabel')}</Form.Label>
          <Form.Control type="text" defaultValue={userData !== '' ? userData.dateInserted : null} disabled />
        </Form.Group>
      </Row>

      <div className="item-center MyProfile_Button_Container">
        <Button type="submit" variant="btn" className="item-center MyProfile_Button_Container_button">
          {`${t('BUTTONS.UPDATE')}`}
        </Button>
      </div>
    </Form>
  );
};
export default MyProfile;
