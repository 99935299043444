import React, { useState } from 'react';
import { Button, Form, Row, Col, InputGroup, Container } from 'react-bootstrap';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import '../scss/views/Login.scss';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AuthHandler } from '../utils/AuthHandler';
import { RestService } from '../utils';
import ConfigHandler from '../config';
import { Link } from 'react-router-dom';
import Admin from './Admin';
import { useTranslation } from 'react-i18next';


const NavigationTo = (varLink) => {
  const history = useHistory();
  
  function navigateTo() {
    history.push();
  }
  return navigateTo;
};

function Login(props) {
  const history = useHistory();
  const { t } = useTranslation();

  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    setVisible(!visible);
  };
  const [validated, setValidated] = useState(false);
  const [errMessage, setErrMessage] = useState();

  /**
   * It takes the username and password from the form, sends it to the backend, and if the response is
   * successful, it redirects the user to the homepage.
   * @param event - the event that is triggered when the form is submitted
   */
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    const username = event.currentTarget.validationCustomUsername.value;
    const password = event.currentTarget.formGroupPassword.value;

    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      event.preventDefault();
      let callReturn = RestService.post({
        url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.login}`,
        body: {
          username: username,
          password: password,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          var status = response.status;
          if (status > 199 && status < 300) {
            response.json().then((data) => ({
                data: data,
            })).then((res) => {
              AuthHandler.setAuth(res.data);
              window.location.reload();
              if(res.data.firstLogin == false && AuthHandler.isAuth() ) {
                window.location.replace('/firstlogin')
              } else if (AuthHandler.isAuth()) {
                window.location.replace(ConfigHandler.getConfig().defaults.homepageAfterLogin);
              } 
            });
          } else if (status === 401) {
            setErrMessage(t('LOGIN.LabelInvalidPassword'));
          } else {
            setErrMessage(t('LOGIN.LabelError'));
          }
        })
        .catch((err) => {
          setErrMessage(t('LOGIN.LabelServerError'));
          throw new Error(err);
        });

      setValidated(true);
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <h2>{props.titlepanel}</h2>
        </Col>
      </Row>
      <Row>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="p-2">
            <Form.Group as={Col} md="12" controlId="validationCustomUsername" className="mb-2">
              <Form.Label className="mail">
                <p>{props.username}</p>
              </Form.Label>
              <Form.Control type="text" placeholder="" aria-describedby="inputGroupPrepend" required />
            </Form.Group>

            <Form.Group controlId="formGroupPassword">
              <Form.Label>
                <p>{props.passwordlabel}</p>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control type={visible ? 'text' : 'password'} placeholder="" required />
                <i className="fa fa-fw fa-eye field-icon toggle-password">
                    {visible === false ? (
                      <VisibilityOffIcon onClick={() => toggleVisible()} />
                    ) : (
                      <RemoveRedEyeIcon onClick={() => toggleVisible()} />
                    )}
                  </i>
                <Form.Control.Feedback type="invalid">
                  {props.incorrect}{' '}
                  <a href="true" onClick={NavigationTo}>
                    {props.res}
                  </a>
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Row className="p-2">
              <h6 className="item-center">{errMessage}</h6>
            </Row>
          </Row>

          <Row>
            <div className="item-center">
              <button type="submit" className='btn fadedButton p-2'>{props.log}</button>
              <p className='forgotPassword p-2' onClick={() => props.setShowForgotPasswordModal(true)}>{props.forg}</p>
            </div>
          </Row>
          <Row>
            <div className="foot item-center">
              <h5>{props.NC}</h5>
              <a href="#reg" onMouseDown={() => props.setShowRegistration(true)} >{props.regist}</a>
            </div>
          </Row>
        </Form>
      </Row>
    </Container>
  );
}
export default Login;
