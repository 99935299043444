const filters = (state = [], action) => {
    switch (action.type) {
        case 'filters/setFilter':
            const indexA = state.map(object => object.value).indexOf(action.payload.value);
            if(indexA < 0){
                return [...state, action.payload] 
            }
            else{
                return [...state]
            } 
            
            
        case 'filters/removeFilter':
            const indexB = state.map(object => object.value).indexOf(action.payload.value);
            state.splice(indexB, 1)
            return [...state]
        default: return state
    }
}

export default filters
