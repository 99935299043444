import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container, Row, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DataTable from '../components/DataTable';
import ConfigHandler from '../config';
import Icon from '@mui/material/Icon';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AuthHandler } from '../utils/AuthHandler';
import { RestService } from '../utils';
import '../scss/components/DataTables.scss';
import Utils from '../utils/Utils';
import CompanyChangeStatusModal from '../components/CompanyChangeStatusModal';
import { render } from '@testing-library/react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ManageCompany() {
  const { t } = useTranslation();

  //Needed Variables & States
  const [listData, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [sortDirection, setSortDir] = useState(false);
  const [sortIcon, setSortIcon] = useState('name');
  const [sortVal, setSortVal] = useState('name:desc');
  const [numofData, setnumofData] = useState(0);
  const [inputText, setInputText] = useState('');
  const state = useSelector((state) => state);
  let dataReturn = [];
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  var dataforModal;

  //Fetch new table data when needed
  const fetchData = useCallback(({ pageSize, pageIndex }) => {
    const startRow = pageSize * pageIndex;
    const endRow = startRow + pageSize;
    dataReturn = RestService.get({
      url: `${ConfigHandler.getConfig().api.baseUrl}${
        ConfigHandler.getConfig().api.path.getPartners
      }?q=${inputText}&offset=${startRow}&limit=${pageSize}&order=${sortVal}`,
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
        }))
        .then((res) => {
          dataReturn = res.data.data;
          if (dataReturn != undefined) {
            setData(dataReturn);
          }
          setnumofData(res.data.total);
          setPageCount(Math.ceil(res.data.total / pageSize));
        }),
    );
    const userData = AuthHandler.getUserData();
  });

  //Get user search data
  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    Utils.handleInputSearch(lowerCase).then((text) => {
      setInputText(text);
    });
  };

  //Handle sorting
  const sortHandler = (val) => {
    document.getElementsByClassName(`TH-${sortIcon}`)[0].classList.remove('sortedTabAsc', 'sortedTabDesc');
    if (sortDirection) {
      setSortVal(`${val}:asc`);
      setSortDir(false);
      document.getElementsByClassName(`TH-${val}`)[0].classList.add('sortedTabAsc');
      setSortIcon(val);
    } else {
      setSortVal(`${val}:desc`);
      setSortDir(true);
      document.getElementsByClassName(`TH-${val}`)[0].classList.add('sortedTabDesc');
      setSortIcon(val);
    }
  };

  const switchStatus = (rowDatas) => {
    render(<CompanyChangeStatusModal setShowDeleteModal={setShowDeleteModal} data={rowDatas} />);
  };

  //Columns
  const columns = useMemo(
    () => [
      {
        Header: t('FORMS.CompanyNameLabel'),
        accessor: 'name',
      },
      {
        Header: t('FORMS.VatLabel'),
        accessor: 'vatNumber',
      },
      {
        Header: t('FORMS.AsnPlaceholder'),
        accessor: 'asNumber',
      },
      {
        Header: t('FORMS.ReferenceEmailLabel'),
        accessor: 'email',
      },
      {
        Header: t('FORMS.StatusLabel'),
        accessor: 'enabled',
        Cell: ({ row }) => {
          return (
            <div className="tabIcon">
              {/* <span className={value === 0 ? 'tabRedDot' : 'tabGreenDot'}>
                <Icon>fiber_manual_record</Icon> {value === 0 ? 'OFF' : 'ON'}
              </span> */}
              <Form.Check
                type="switch"
                id="custom-switch"
                checked={row.original.enabled === false ? false : true}
                onChange={() => switchStatus(row.original)}
              />
            </div>
          );
        },
      },
      {
        Header: ' ',
        accessor: 'id',
        Cell: ({ row }) => {
          return (
            <div className="tabIcon">
              <span onClick={() => {}}>
                <Link
                  to={{
                    pathname: '/admin/newcompany',
                    state: { data: row.original },
                  }}>
                  <OverlayTrigger overlay={<Tooltip>{t('TOOLTIPS.Edit')}</Tooltip>}>
                    <Icon>edit_note</Icon>
                  </OverlayTrigger>
                </Link>
              </span>
            </div>
          );
        },
      },
    ],
    [],
  );
  if (listData == '') {
    const data = [
      {
        name: t('OTHERS.NoDataMessage'),
        surname: '',
        username: '',
        email: '',
        dateInserted: '',
        status: null,
      },
    ];
    setData(data);
  }
  if (!listData)
    return (
      <Container fluid>
        <Row>{t('OTHERS.NoDataMessage')}</Row>
      </Container>
    );

  return (
    <Container fluid>
      {showDeleteModal && window.location.reload()}
      <Row className="manageCompany">
        {listData.length && (
          <DataTable
            columns={columns}
            data={listData}
            heading={true}
            searchBar={true}
            numofData={numofData}
            tableHeading={t('MANAGECOMPANY.ListingPageTitle')}
            searchMessage={t('OTHERS.Search')}
            filterButton={false}
            downloadButton={false}
            newValueButton={true}
            newValueText={t('MANAGECOMPANY.TitleLabel')}
            newValueLink="/admin/newcompany"
            inputHandler={inputHandler}
            fetchData={fetchData}
            pageCount={pageCount}
            sortHandler={sortHandler}
            sortValue={sortVal}
            inputText={inputText}
            paginationItemsStartingPoint={ConfigHandler.getConfig().datatables.defaultStartingPoint}
            paginationItemsCount={ConfigHandler.getConfig().datatables.defaultPaginationCount}
          />
        )}
      </Row>
    </Container>
  );
}

export default ManageCompany;
