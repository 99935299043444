import React from 'react'
import { Container, Form, Row, Col } from 'react-bootstrap';
import Icon from '@mui/material/Icon';
import { Link } from 'react-router-dom';
import ConfigHandler from '../../config';


import '../../scss/views/Help.scss'
import pricebook from '../../assets/AdminPage/pricebook.svg'

function HelpPage() {


    return (
        <Container className="helpPage">
            <Row className="heading text-center">
                <h2 className='fadedText'>Help Page</h2>
                <p>The purpose of this section is to help the admins to manage the portal.</p>
            </Row>
            <Row>
                <Col md={4}>
                    <Link to="/help/pricebook">
                        <Row className="cardContainer item-center m-0 p-0">
                            <Col md={3} className="cardIcon">
                                <Icon>price_change</Icon>
                            </Col>
                            <Col md={9} className="text-left">
                                <div className='cardHeading'>
                                    Pricebook
                                </div>
                                <div className='cardDescription'>
                                    Documentation about the usage of the Pricebook.
                                </div>
                            </Col>
                        </Row>
                    </Link>
                </Col>
                <Col md={4}>
                    <Link to="/help/quote">
                        <Row className="cardContainer item-center m-0 p-0">
                            <Col md={3} className="cardIcon">
                                <Icon>request_quote</Icon>
                            </Col>
                            <Col md={9} className="text-left">
                                <div className='cardHeading'>
                                    Quote
                                </div>
                                <div className='cardDescription'>
                                    Documentation about the Quote Requests.
                                </div>
                            </Col>
                        </Row>
                    </Link>
                </Col>
                <Col md={4}>
                    <Link to="/help/admin">
                        <Row className="cardContainer item-center m-0 p-0">
                            <Col md={3} className="cardIcon">
                                <Icon>admin_panel_settings</Icon>
                            </Col>
                            <Col md={9} className="text-left">
                                <div className='cardHeading'>
                                    Admin
                                </div>
                                <div className='cardDescription'>
                                    Documentation about the panel admin.
                                </div>
                            </Col>
                        </Row>
                    </Link>
                </Col>
            </Row>
        </Container>
    )
}

export default HelpPage