import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ChangePassword from "./ChangePassword";
import "../scss/components/ModalPasswordChange.scss";
import { useTranslation } from 'react-i18next';

const ModalPasswordChange = ({ show, setOpenPassword }) => {
  const [modalShow, setModalShow] = useState(show);
  const { t } = useTranslation();

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <p className="h3 ChangePasswordForm_container_subtitle">
              {t('CHANGE_PASSWORD.ModalHeading')}
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChangePassword setModalShow={setModalShow} />
        </Modal.Body>
      </Modal>
    );
  }
  return (
    <div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          setOpenPassword(false);
        }}
      />
    </div>
  );
};

export default ModalPasswordChange;
