import React, { useEffect, useState } from 'react';
import { RestService } from '../utils';
import { Row, Col, Container } from 'react-bootstrap';
import ConfigHandler from '../config';
import { useTranslation } from 'react-i18next';

import "../scss/components/ForgotPasswordLanding.scss";

function ForgotPasswordLanding() {
    const { t } = useTranslation();

    const url = new URL(window.location.href);
    const tempToken = url.searchParams.get("ac");
    const username = url.searchParams.get("u");
    const [usePage, setUsePage] = useState(1);

    useEffect(() => {
      if((tempToken != undefined)&&(tempToken != null)&&(username != undefined)&&(username != undefined)){
         return (async function() {
            try {
              const response = await fetch(ConfigHandler.getConfig().api.baseUrl + ConfigHandler.getConfig().api.path.sendForgotPassword, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${tempToken}` },
                body: JSON.stringify({ username: username }),
                }).then((response) => {
                    if(response.status > 199 && response.status < 300){
                      setUsePage(0);
                    }else{
                      setUsePage(1);
                    }
                })
            } catch (e) {
                console.error(e);
            }
        })();
      }else{
        window.location.replace(`${process.env.PUBLIC_URL}/home`)
      }
    }, [])


    switch (usePage) {
      case 0:
        return (
          <Container fluid className='ForgotPasswordLandingContainer'>
            <Row>
              <Col className='content'>
                <h2 className='mb-3'>{t('FORGOTPASSWORDLANDING.PageHeader')}</h2>
                <h4>{t('FORGOTPASSWORDLANDING.DescriptionLine1')}</h4>
                <h4 className='mb-4'>{t('FORGOTPASSWORDLANDING.DescriptionLine2')}</h4>
                <center>
                  <a href={`${process.env.PUBLIC_URL}/home`}>
                    <button className='btn fadedButton btn-lg'>{t('FORGOTPASSWORDLANDING.ButtonText')}</button>
                  </a>
                </center>
              </Col>
            </Row>
          </Container>
        )
        break;
      case 1:
        return (
          <Container fluid className='ForgotPasswordLandingContainer'>
            <Row>
              <Col className='content'>
                <h2 className='mb-3'>{t('FORGOTPASSWORDLANDINGINVALID.PageHeader')}</h2>
                <h4>{t('FORGOTPASSWORDLANDINGINVALID.DescriptionLine1')}</h4>
                <h4 className='mb-4'>{t('FORGOTPASSWORDLANDINGINVALID.DescriptionLine2')}</h4>
                <center>
                  <a href={`${process.env.PUBLIC_URL}/home`}>
                    <button className='btn fadedButton btn-lg'>{t('FORGOTPASSWORDLANDING.ButtonText')}</button>
                  </a>
                </center>
              </Col>
            </Row>
          </Container>
        )
        break;
      default:
        break;
    }
}
export default ForgotPasswordLanding