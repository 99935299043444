import '../scss/views/NewClient.scss';
import React, { useState, useEffect } from 'react';
import { Button, Form, Row, Col, Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RestService } from '../utils';
import ConfigHandler from '../config';
import { toast } from 'react-toastify';
import { useLocation, Redirect } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import DeleteClientModal from '../components/DeleteClientModal';

const NewClient = () => {
  const [validated, setValidated] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [toRedirect, setToRedirect] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (location.state != undefined) {
      setIsEditing(true);
    }
  }, [location.state]);

  const HandleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
      toast.warning(t('NOTIFICATIONS.NotificationMissingFields'));
      return;
    }
    setValidated(true);

    if (!isEditing && form.checkValidity()) {
      RestService.post({
        url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.registerClient}`,
        headers: { 'Content-Type': 'application/json' },
        body: {
          companyName: form.CompanyName.value,
          vatNumber: form.VATNumber.value,
          asNumber: form.asNumber.value,
          referenceName: form.refName.value,
          referenceEmail: form.refEmail.value,
          referencePhone: form.refPhone.value,
          city: form.City.value,
          street: form.Street.value,
          streetNumber: form.StreetNumber.value,
          country: form.Country.value,
        },
      }).then((res) => {
        if (res.status > 199 && res.status < 300) {
          toast.success(t('NOTIFICATIONS.NotificationdAdded'));
          setToRedirect(true);
        } else {
          res.json().then((statusText) => {
            toast.error(
              <>
                {t('NOTIFICATIONS.NotificationSomethingWrong')} <br />
                <small>
                  <i>{statusText.error}</i>
                </small>
              </>,
            );
          });
        }
      });
    } else if (isEditing && form.checkValidity()) {
      RestService.put({
        url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.registerClient}`,
        headers: { 'Content-Type': 'application/json' },
        body: {
          id: location.state.client.id,
          companyName: form.CompanyName.value,
          vatNumber: form.VATNumber.value,
          asNumber: form.asNumber.value,
          referenceName: form.refName.value,
          referenceEmail: form.refEmail.value,
          referencePhone: form.refPhone.value,
          city: form.City.value,
          street: form.Street.value,
          streetNumber: form.StreetNumber.value,
          country: form.Country.value,
        },
      }).then((res) => {
        if (res.status > 199 && res.status < 300) {
          toast.success(`${location.state.client.companyName} ${t('NOTIFICATIONS.NotificationEdited')}`);
          setToRedirect(true);
        } else {
          res.json().then((statusText) => {
            toast.error(
              <>
                {location.state.client.companyName} {t('NOTIFICATIONS.NotificationNotEdited')}
                <br />
                <small>
                  <i>{statusText.error}</i>
                </small>
              </>,
            );
          });
        }
      });
    }
  };

  return (
    <div>
      {showDeleteModal && (
        <DeleteClientModal
          setShowDeleteModal={setShowDeleteModal}
          data={location.state.client}
          setToRedirect={setToRedirect}
        />
      )}
      {toRedirect && <Redirect to="/clients/clientlist" />}
      <Form noValidate validated={validated} onSubmit={HandleSubmit} className="px-3 mx-3 NewClient">
        <Row className="mb-4 mx-3">
          <Col md={6}>
            <h3 className="fadedText">
              {isEditing ? `${t('NEWUSER.EditingTitleLabel')} ${location.state.client.companyName}` : t('CUSTOMER.NewCustomerPageTitle')}
            </h3>
          </Col>
          <Col md={6} className="item-right fadedText">
            {isEditing && (
              <span style={{ padding: '0 1.6rem 3rem 0' }} onClick={() => setShowDeleteModal(true)}>
                <Icon style={{ fontSize: '2rem', display: 'inline-block', verticalAlign: 'middle' }}>delete</Icon>
              </span>
            )}
          </Col>
        </Row>
        <p className="h4 mb-1 NewClient_subtitle">{t('CUSTOMER.NewCustomerPageCompanyTitle')}</p>
        <Row className="mb-3 mx-3">
          <Form.Group as={Col} md="12" controlId="CompanyName">
            <Form.Label className="float-start NewClient_labels">{t('FORMS.NameLabel')} <span className="NewClient_star_symbol">*</span></Form.Label>
            <Form.Control
              type="text"
              placeholder={`${t('FORMS.NamePlaceholder')}`}
              defaultValue={isEditing ? location.state.client.companyName : null}
              required
            />
          </Form.Group>
        </Row>

        {/* ##### Company Type, VAT number, Website ####### */}
        <Row className="mb-3 mx-3">
          {/* ##For this I am not sure whether to make it type="number" or not## */}
          <Form.Group as={Col} controlId="VATNumber">
            <Form.Label className="float-start NewClient_labels">{t('FORMS.VatOrFiscalCodeLabel')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={`${t('FORMS.VatOrFiscalCodePlaceholder')}`}
              defaultValue={isEditing ? location.state.client.vatNumber : null}
            />
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="asNumber">
            <Form.Label className="float-start NewClient_labels">{t('FORMS.AsnLabel')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={`${t('FORMS.AsnPlaceholder')}`}
              defaultValue={isEditing ? location.state.client.asNumber : null}
            />
          </Form.Group>
        </Row>

        {/* ###### Reference -->Second title ----  Email, Phone  ###### */}
        <p className="h4 mb-1 mt-5 NewClient_subtitle">{t('CUSTOMER.NewCustomerPageReferenceTitle')}</p>
        <Row className="mb-3 mx-3">
          <Form.Group as={Col} md="12" controlId="refName">
            <Form.Label className="float-start NewClient_labels">
              {t('FORMS.ReferenceNameLabel')} <span className="NewClient_star_symbol">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={`${t('FORMS.ReferenceNamePlaceholder')}`}
              defaultValue={isEditing ? location.state.client.referenceName : null}
              required
            />
            <Form.Control.Feedback type="invalid" className="Form__control_feedback">
              {t('FORMS.InvalidField')}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3 mx-3">
          <Form.Group as={Col} md="6" controlId="refEmail">
            <Form.Label className="float-start NewClient_labels">
              {t('FORMS.ReferenceEmailLabel')} <span className="NewClient_star_symbol">*</span>
            </Form.Label>
            <Form.Control
              type="email"
              placeholder={`${t('FORMS.ReferenceEmailPlaceholder')}`}
              defaultValue={isEditing ? location.state.client.referenceEmail : null}
              required
            />
            <Form.Control.Feedback type="invalid" className="Form__control_feedback">
              {t('FORMS.InvalidField')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="refPhone">
            <Form.Label className="float-start NewClient_labels">
              {t('FORMS.ReferencePhoneLabel')} <span className="NewClient_star_symbol">*</span>
            </Form.Label>
            <Form.Control
              required
              type="tel"
              defaultValue={isEditing ? location.state.client.referencePhone : null}
              placeholder={`${t('FORMS.ReferencePhonePlaceholder')}`}
            />
          </Form.Group>
        </Row>
        {/* ### Email, Phone #### */}
        <p className="h4 mb-1 mt-5 NewClient_subtitle">{t('CUSTOMER.NewCustomerPageAddressTitle')}</p>

        <Row className="mb-3 mx-3">
          <Form.Group as={Col} md="6" controlId="City">
            <Form.Label className="float-start NewClient_labels">
              {t('FORMS.City')} <span className="NewClient_star_symbol">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              defaultValue={isEditing ? location.state.client.city : null}
              placeholder={t('FORMS.CityPlaceholder')}
            />
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="Street">
            <Form.Label className="float-start NewClient_labels">
              {t('FORMS.Street')} <span className="NewClient_star_symbol">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              defaultValue={isEditing ? location.state.client.street : null}
              placeholder={t('FORMS.StreetPlaceholder')}
            />
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="StreetNumber">
            <Form.Label className="mt-3 float-start NewClient_labels">
              {t('FORMS.StreetNumLabel')} <span className="NewClient_star_symbol">*</span>
            </Form.Label>
            <Form.Control
              required
              type="number"
              defaultValue={isEditing ? location.state.client.streetNumber : null}
              placeholder={t('FORMS.StreetNumPlaceholder')}
            />
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="Country">
            <Form.Label className="mt-3 float-start NewClient_labels">
              {t('FORMS.Country')} <span className="NewClient_star_symbol">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              defaultValue={isEditing ? location.state.client.country : null}
              placeholder={t('FORMS.CountryPlaceholder')}
            />
          </Form.Group>
        </Row>

        <div className="item-center p-2">
          <button type="submit" className="btn fadedButton">
            {isEditing ? t('BUTTONS.UPDATE') : t('BUTTONS.ADD')}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default NewClient;
