import React from 'react'
import { Container, Row, Col, Accordion, Badge, ListGroup, Tab } from 'react-bootstrap';
import Icon from '@mui/material/Icon';
import { Link } from 'react-router-dom';
import ConfigHandler from '../../config';

import '../../scss/views/Help.scss'

function HelpPricebook() {
  return (
    <Container className="helpPage">
      <Row className="heading text-center">
          <h2 className='fadedText'>Pricebook</h2>
          <p>Documentation and guides about the pricebook.</p>
      </Row>

      <Row id="general" className='my-3'>
        <Col md={12}>
          <Accordion defaultActiveKey="0" flush>
            <Row className='my-3'>
              <h3 className='mb-3'><span className="fadedText">#</span> General</h3>
              <Accordion.Item eventKey="0">
                <Accordion.Header>What's the pricebook?</Accordion.Header>
                <Accordion.Body>
                  The pricebook is made to allow admins to manage the price of the coverage services.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>How does it work?</Accordion.Header>
                <Accordion.Body>
                  <p>Each pricebook item has a <b>Code</b> and a <b>Portal Code</b>.</p>
                  <p>The <b>code</b> is the service description given by <i>Comunica</i>, this <b>code</b> is used to match the coverage response of comunica with our pricebook database.</p>
                  <p>The <b>Portal Code</b> is our iternal code, this allow us to match a service of comunica with a custom pricebook of a partner.</p>
                  
                  <div className='exampleSection'>
                    <div className='title'>
                      Example
                    </div>
                    <div className='content'>
                      If we try to get the coverage for address X, Comunica response will includes a list of service. The description of the service, for example <b>"Fibra Ethernet Dedicata"</b>, is used to find a match on our database. 
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Row>
            <Row className='my-3'>
              <h3 className='mb-3'><span className="fadedText">#</span> Adding a Pricebook </h3>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Upload a CSV</Accordion.Header>
                <Accordion.Body>
                  <p>To upload a pricebook via a CSV file you should download the template that can be found on the upload page itself.</p>
                  <p>The CSV template will include the following headers:</p>
                  <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
                    <Row className='my-3'>
                      <Col sm={4}>
                        <ListGroup>
                          <ListGroup.Item action href="#link1">
                            <b>provider <Badge pill bg='danger'>*</Badge></b>
                          </ListGroup.Item>
                          <ListGroup.Item action href="#link2">
                            <b>category <Badge pill bg='danger'>*</Badge></b>
                          </ListGroup.Item>
                          <ListGroup.Item action href="#link3">
                            <b>portal_code <Badge pill bg='warning'>*</Badge></b>
                          </ListGroup.Item>
                          <ListGroup.Item action href="#link4">
                            <b>code <Badge pill bg='danger'>*</Badge></b>
                          </ListGroup.Item>
                          <ListGroup.Item action href="#link5">
                            <b>duration <Badge pill bg='danger'>*</Badge></b>
                          </ListGroup.Item>
                          <ListGroup.Item action href="#link6">
                            <b>description <Badge pill bg='danger'>*</Badge></b>
                          </ListGroup.Item>
                          <ListGroup.Item action href="#link7">
                            <b>nrc <Badge pill bg='danger'>*</Badge></b>
                          </ListGroup.Item>
                          <ListGroup.Item action href="#link8">
                            <b>mrc <Badge pill bg='danger'>*</Badge></b>
                          </ListGroup.Item>
                          <ListGroup.Item action href="#link9">
                            <b>sap_nrc_code <Badge pill bg='danger'>*</Badge></b>
                          </ListGroup.Item>
                          <ListGroup.Item action href="#link9">
                           <b>sap_rc_code <Badge pill bg='danger'>*</Badge></b>
                          </ListGroup.Item>
                          <ListGroup.Item action href="#link10">
                            <b>account_discount_threshold	 <Badge pill bg='danger'>*</Badge></b>
                          </ListGroup.Item>
                          <ListGroup.Item action href="#link10">
                            <b>threshold_discount_dir <Badge pill bg='danger'>*</Badge></b>
                          </ListGroup.Item>
                          <ListGroup.Item action href="#link11">
                            <b>fixed_nr_cost <Badge pill bg='danger'>*</Badge></b>
                          </ListGroup.Item>
                          <ListGroup.Item action href="#link11">
                            <b>mr_gg_pp_cost <Badge pill bg='danger'>*</Badge></b>
                          </ListGroup.Item>
                          <ListGroup.Item action href="#link11">
                            <b>mr_infra_cost <Badge pill bg='danger'>*</Badge></b>
                          </ListGroup.Item>
                          <ListGroup.Item action href="#link12">
                            <b>feasability <Badge pill bg='danger'>*</Badge></b><br />
                          </ListGroup.Item>
                          <ListGroup.Item action href="#link13">
                            <b>date_from  <Badge pill bg='success'>*</Badge></b>
                          </ListGroup.Item>
                        </ListGroup>
                      </Col>
                      <Col sm={8} style={{backgroundColor: '#fff', padding: '10px', borderRadius: '10px'}}>
                        <Tab.Content>
                          <Tab.Pane eventKey="#link1">
                            <h5>The name of the network provider (E.g. TIM)</h5>
                            <p>The name that is set here, to avoid not finding the image, should be set exactly as the suggested options.</p>
                            <p><Badge pill bg="success">Available Options</Badge>  TIM, OPEN FIBER, FASTWEB</p>
                            <p>
                              <b><u>If this is not set, the service will not be displayed.</u></b>
                            </p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="#link2">
                            <h5>The type of the service (E.g. ACCESSO)</h5>
                            <p>This is to identify the category of the service. On this portal, only <b>ACCESSO</b> services are visible.</p>
                            <p><Badge pill bg="success">Available Options</Badge>  ACCESSO, TRASPORTO, COLOCATION, CROSS CONNECTION, DATA CENTER INTERCONNECT, DDOS MITIGATION, HYPERLAN, IP TRANSIT, NOLEGGIO HW, REMOTE PEERING, VIRTUAL PoP</p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="#link3">
                            <h5>Internal Service Code (E.g. ACC0031)</h5>
                            <p>This field is <b>mandatory</b> if the purose of the upload is to override any existing pricebook item. For example, if we want to upload a CSV that only changes some prices without creating a new one we will have to specify the portal code of each service.</p>
                            <p>It's <b>not mandatory</b> if the service that you are uploading is a new one.</p>
                            <p>If you are uploading a <b>custom</b> pricebook for a partner, unless it's a new service custom for the partner <u>only</u>, you should always specify the portal code of the item.</p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="#link4">
                            <h5>Comunica service description (E.g. Fibra Ethernet Dedicata)</h5>
                            <p>This is the 'description' of th service given by Comunica. It's important to have this one as <b>it is used to match comunica response with our pricebook database.</b></p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="#link5">
                            <h5>Duration of the contract of the service (E.g. 12)</h5>
                            <p>This is the contract duration of the service.</p>
                            <p><Badge pill bg="success">Suggested</Badge> The number should in multiples of 12 (E.g. 12, 24, 36, 48, etc..)</p>  
                          </Tab.Pane>
                          <Tab.Pane eventKey="#link6">
                          <h5>Description of the service (E.g. IPV 4)</h5>
                          <p>This is a descriptive title of the service.</p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="#link7">
                          <h5>Non Recurring Charges (E.g. 100,00)</h5>
                          <p>This is non recurring price of the service. This should be specified in comma (<b> , </b>) separated decimal format.</p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="#link8">
                          <h5>Monthly Recurring Charges (E.g. 50,00)</h5>
                          <p>This is monthly recurring price of the service. This should be specified in comma (<b> , </b>) separated decimal format.</p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="#link9">
                          <h5>Identifiers of the service in SAP</h5>
                          <p>This two codes are provided by <b>SAP</b>. They are used to identify a service in SAP.</p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="#link10">
                          <h5>TBA</h5>
                          </Tab.Pane>
                          <Tab.Pane eventKey="#link11">
                          <h5>TBA</h5>
                          </Tab.Pane>
                          <Tab.Pane eventKey="#link12">
                          <h5>Feasability of the service (S/N)</h5>
                          <p>This parameter is used to identify if the service can be sold in a direct way or it needs a feasibility study.</p>
                          <p><Badge pill bg="success">Available Options</Badge> S <i>(Yes)</i> or N <i>(No)</i></p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="#link13">
                          <h5>Starting date of the pricebook item</h5>
                          <p>The pricebook system gives the user to upload a pricebook item that should be active in the future.</p>
                          <p>This parameter is not mandatory, it should be specified only if the purpose is to start the service in the future.</p>
                          <p>The date format that should be used is <b>yyyy-MM-dd HH:mm:ss</b>. e.g. 09th November 2022 at Midnight should be written 09-11-2022 00:00:00</p>
                          <b>There can be only <u>1</u> future item per Portal Code.</b>
                          </Tab.Pane>
                        </Tab.Content>
                        <p className='p-0 m-0'><Badge pill bg='danger'>*</Badge> = Mandatory field</p>
                        <p className='p-0 m-0'><Badge pill bg='warning'>*</Badge> = Different action if specified or not, check details.</p>
                        <p className='p-0 m-0'><Badge pill bg='success'>*</Badge> = Non Mandatory field</p>
                      </Col>
                    </Row>
                  </Tab.Container>

                  <div className='exampleSection'>
                    <div className='title'>
                      Example
                    </div>
                    <div className='content'>
                      Below an example of a pricebook item in the CSV to upload. 
                      <div style={{overflow: 'scroll', padding: '5px'}}>
                        <table className='helpPageTable'>
                          <thead>
                            <tr>
                              <th>provider</th>
                              <th>category</th>
                              <th>portal_code</th>
                              <th style={{minWidth: '100px'}}>code</th>
                              <th>duration</th>
                              <th style={{minWidth: '180px'}}>description</th>
                              <th>nrc</th>
                              <th>sap_nrc_code</th>
                              <th>mrc</th>
                              <th>sap_rc_code</th>
                              <th>account_discount_threshold</th>
                              <th>threshold_discount_dir</th>
                              <th>fixed_nr_cost</th>
                              <th>mr_gg_pp_cost</th>
                              <th>mr_infra_cost</th>
                              <th>feasability</th>
                              <th style={{minWidth: '250px'}}>date_from (yyyy-MM-dd HH:mm:ss)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>TIM</td>
                              <td>ACCESSO</td>
                              <td>ACC0031</td>
                              <td>FTTC VDSL2</td>
                              <td>12</td>
                              <td>FTTC DL 100 UL 20</td>
                              <td>100,00</td>
                              <td>V50000031</td>
                              <td>27,00</td>
                              <td>V50000227</td>
                              <td>5,00</td>
                              <td>15,00</td>
                              <td>67,00</td>
                              <td>19,00</td>
                              <td>2,12</td>
                              <td>N</td>
                              <td>2022-11-08 08:00:00</td>
                            </tr>
                          </tbody>
                        </table> 
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Addining with form</Accordion.Header>
                <Accordion.Body>
                  <p>Alternatively to the CSV, new pricebook item can be added using the form provided.</p>
                  <p>Using the form the user will be able to create only one service per form.</p>
                </Accordion.Body>
              </Accordion.Item>
            </Row>
            <Row className='my-3'>
              <h3 className='mb-3'><span className="fadedText">#</span> Disable a Pricebook Item </h3>
              <Accordion.Item eventKey="5">
                <Accordion.Header>Singular</Accordion.Header>
                <Accordion.Body>
                  <p>A pricebook item can be disabled in the edit page of the item itself.</p>
                  <p>When disabling a default pricebook item, automatically, any matching item (by portal code) of the customs pricebok will also be disabled.</p>
                  <p>The deactivation of the pricebook is immediate.</p> 
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>Multiple</Accordion.Header>
                <Accordion.Body>
                  <p>Multiple items can also be disabled at the same time. From the table view of the pricebook by clicking on the 'Multi Disable' button a modal containing a list of service currently visible in the table will be showed.</p>
                </Accordion.Body>
              </Accordion.Item>
            </Row>
          </Accordion>
        </Col>
      </Row>
    </Container>
  )
}

export default HelpPricebook