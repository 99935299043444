import React, { useState } from 'react';
import { Button, Modal, Container, Row, Col, Form, Alert } from 'react-bootstrap';
import '../scss/components/Modal.scss';
import { useTranslation } from 'react-i18next';
import { RestService } from '../utils';
import ConfigHandler from '../config';

function ForgotPasswordModal({setShowForgotPasswordModal}) {
  const [show, setShow] = useState(true);
  const [alert, setAlert] = useState({show: false, variant: null, content: null})
  const { t } = useTranslation();

  const handleSubmit = (event) =>{
    const form = event.currentTarget;
    event.preventDefault();
    
    if(!form.checkValidity()){
        setAlert({show: true, variant: "danger", content: t('FORGOTPASSWORDMODAL.AlertInvalidEmail')})
        event.stopPropagation();
    }else{
        setAlert({show: false, variant: null, content: null});
        RestService.post({
            url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.requestForgotPassword}`,
            body: {
                username: form.useremail.value,
            }
        }).then((response) => {
            setAlert({show: true, variant: "success", content: t('FORGOTPASSWORDMODAL.AlertSuccess')})
        });
    }
  } 

  return (
    <>
      <Modal show={show} fullscreen={false} centered onHide={() => { setShow(false); setShowForgotPasswordModal(false); }} className="forgotPasswordModal">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <Container fluid className='m-2'>
                <Row>
                    <Col>
                        <h2>{t('FORGOTPASSWORDMODAL.ModalHeader')}</h2>
                        <h4>{t('FORGOTPASSWORDMODAL.ModalSubHeader')}</h4>
                    </Col>
                </Row>
                <Row className="mt-2">
                    {
                        alert.show && (
                            <Alert variant={alert.variant}> {alert.content} </Alert>
                        )
                    }
                </Row>
                <Row className='my-3'>
                    <p>{t('FORGOTPASSWORDMODAL.ModalDescription')}</p>
                </Row>
                <Row>
                    <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group as={Col} md="12" controlId="useremail" className="mb-2">
                        <Form.Label className="mail"> {t('FORMS.EmailLabel')} </Form.Label>
                        <Form.Control type="email" aria-describedby="inputGroupPrepend" required className='mb-5'/>
                    </Form.Group>
                    <center>
                        <button className='btn fadedButton mb-3' type='submit'>{t('FORGOTPASSWORDMODAL.ButtonText')}</button>
                    </center>
                    </Form>
                </Row>
            </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ForgotPasswordModal;
