import React, { useState } from 'react';
import Header from '../containers/Header';
import { Form, Col, Button } from 'react-bootstrap';
import { ThreeDots } from "react-loader-spinner";
import { toast } from 'react-toastify';
import validator from 'validator';
import { RestService } from '../utils';
import ConfigHandler from '../config';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useTranslation } from 'react-i18next';
import { AuthHandler } from '../utils/AuthHandler';
import Spinner from './Spinner';

import '../scss/components/FirstLogin.scss'

function FirstLogin() {
const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [validateNewPassword, setValidateNewPassword] = useState(false);
  const [validatedConfirmPassword, setValidatedConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmPasswordMessage, setConfirmPasswordMessage] = useState(null);
  const [spinner, setSpinner] = useState(false);
  /* ## add an Eye to make password visible ## */
  const [visible, setVisible] = useState(false);
  const [visibletwo, setVisibletwo] = useState(false);

  const toggleVisible = () => {
    setVisible(!visible);
  };
  const toggleVisibletwo = () => {
    setVisibletwo(!visibletwo);
  };
  const HandleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    try{
      if (form.newPasswordField.value === form.confirmPasswordField.value && validateNewPassword && validatedConfirmPassword) {
        RestService.post({
          url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.changePassword}`,
          body: {
            oldPassword: form.OldPassword.value,
            newPassword: form.newPasswordField.value,
          },
        }).then((res) => {
          if (res.status > 199 && res.status < 300) {
            setValidateNewPassword(true);
            toast.success(t('CHANGE_PASSWORD.NotificationSuccess'));
            setSpinner(false);
            setTimeout(() => {
                AuthHandler.clearAuth();
                window.location.reload();
            }, 2000);
  
          } else {
            res.json().then((statusText) => {
              var errorMessage = t(`ERRORS.${statusText.message}`);
              
              setSpinner(false);
              errorMessage === `ERRORS.${statusText.message}` 
                ? toast.error(<>{t('NOTIFICATIONS.NotificationSomethingWrong')} <br /><small><i>{statusText.message}</i></small></>)
                : toast.error(errorMessage)
            });
          }
        });
      } else {
        setSpinner(false);
        setValidateNewPassword(false);
        toast.error(t('CHANGE_PASSWORD.NotificationIncorrectPassword'));
      }
    } catch (error){
      setSpinner(false);
      setValidateNewPassword(false);
      toast.error(t('NOTIFICATIONS.NotificationSomethingWrong'));
    }
  };

  const validatePassword = (value) => {
    var confirmPasswordValue = document.getElementById("confirmPasswordField").value;
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1
      })
    ) {
      setErrorMessage(<i className="Password__Strong">{t('CHANGE_PASSWORD.Strong')}</i>);
      setValidateNewPassword(true);
    } else {
      setErrorMessage(t('CHANGE_PASSWORD.Weak'));
      setValidateNewPassword(false);
    }

    if(confirmPasswordValue !== null || confirmPasswordValue !== ''){
      confirmPassword(confirmPasswordValue);
    }
  };

  const confirmPassword = (value) => {
    
    var validatedPassword = document.getElementById("newPasswordField").value;
    if(value !== validatedPassword){
      setValidatedConfirmPassword(false);
      setConfirmPasswordMessage(t('FIRSTLOGIN.nonMatchingPassword'))
    } else {
      setValidatedConfirmPassword(true);
      setConfirmPasswordMessage("");
    }
  }

  return (
    <div className='firstLoginContainer'>
        <Header />
        <div className='content'>
            <div className='heading'>
                <h3 className='fadedText' style={{marginBottom: '0px'}}>{t('FIRSTLOGIN.subTitle')}</h3>
                <h2 className='fadedText' style={{marginBottom: '25px'}}><b>{t('FIRSTLOGIN.title')}</b></h2>
                <h5>{t('FIRSTLOGIN.infoText')}</h5>
            </div>
            <div className='body'>
                {
                    (spinner) && (
                        <Spinner />
                    )
                }
            <Form className="ChangePasswordForm_container" validated={validated} onSubmit={(e)=>{ setSpinner(true); HandleSubmit(e); }}>
                {/* <p className="h3 ChangePasswordForm_container_subtitle">Change password</p> */}
                <Form.Group className="mb-3 " md="3" controlId="OldPassword">
                <Form.Label as={Col} className="ChangePasswordForm_container_label float-start">
                    {t('CHANGE_PASSWORD.Current')}
                </Form.Label>
                <Col>
                    <Form.Control type="password" required className="border border-top-0 border-end-0 border-start-0" />
                </Col>
                </Form.Group>
                <Form.Group className="mb-3 " md="3" controlId="NewPassword">
                <Form.Label as={Col} className="ChangePasswordForm_container_label float-start">
                    {t('CHANGE_PASSWORD.New')}
                </Form.Label>
                <Col>
                    <Form.Control
                        type={visible ? 'text' : 'password'}
                        required
                        id="newPasswordField"
                        className="border border-top-0 border-end-0 border-start-0"
                        onChange={(e) => validatePassword(e.target.value)}
                        isValid={validated}
                    />
                    <div className="Eye__Icon">
                    {visible === false ? (
                        <VisibilityOffIcon onClick={() => toggleVisible()} />
                    ) : (
                        <RemoveRedEyeIcon onClick={() => toggleVisible()} />
                    )}
                    </div>
                    <p className="ChangePasswordForm__control_feedback">{errorMessage}</p>
                </Col>
                </Form.Group>

                <Form.Group className="mb-3 " md="3" controlId="ConfirmPassword">
                <Form.Label as={Col} className="ChangePasswordForm_container_label float-start">
                    {t('CHANGE_PASSWORD.Confirm')}
                </Form.Label>
                <Col>
                    <Form.Control
                    id="confirmPasswordField"
                    type={visibletwo ? 'text' : 'password'}
                    required
                    className="border border-top-0 border-end-0 border-start-0"
                    onChange={(e) => confirmPassword(e.target.value)}
                    //isValid={validated}
                    />
                    <div className="Eye__Icon">
                    {visibletwo === false ? (
                        <VisibilityOffIcon onClick={() => toggleVisibletwo()} />
                    ) : (
                        <RemoveRedEyeIcon onClick={() => toggleVisibletwo()} />
                    )}
                    </div>
                    <p className="ChangePasswordForm__control_feedback">{confirmPasswordMessage}</p>
                </Col>
                </Form.Group>

                <div className="Button_container">
                    <Button type="submit" variant="btn fadedButton" disabled={validateNewPassword && validatedConfirmPassword ? false : true}>
                        {t('BUTTONS.UPDATE')}
                    </Button>
                </div>
            </Form>
            </div>
        </div>
    </div>
  )
}

export default FirstLogin