import React, { useState, useEffect } from 'react';
import '../scss/components/DataTables.scss';
import { Container, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DataTable from '../components/DataTable';
import { useSelector, useDispatch } from 'react-redux';
import { AuthHandler } from '../utils/AuthHandler';
import { RestService } from '../utils';
import ConfigHandler from '../config';
import Icon from '@mui/material/Icon';
import Utils from '../utils/Utils';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ClientList() {
  const state = useSelector((state) => state);
  const { t } = useTranslation();

  //Needed Variables
  const [listData, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [sortDirection, setSortDir] = useState(false);
  const [sortVal, setSortVal] = useState('id:desc');
  const [numofData, setnumofData] = useState(0);
  let dataReturn = [];

  //Search Functionalities
  const [inputText, setInputText] = useState('');
  const dispatch = useDispatch();

  //Fetch new table data when needed
  const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
    const startRow = pageSize * pageIndex;
    const endRow = startRow + pageSize;
    dataReturn = RestService.get({
      url: `${ConfigHandler.getConfig().api.baseUrl}${
        ConfigHandler.getConfig().api.path.getClientof
      }?q=${inputText}&offset=${startRow}&limit=${pageSize}&order=${sortVal}`,
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
        }))
        .then((res) => {
          dataReturn = res.data.data;
          if (dataReturn != undefined) {
            setData(dataReturn);
          }
          setnumofData(res.data.total);
          setPageCount(Math.ceil(res.data.total / pageSize));
        }),
    );

    const userData = AuthHandler.getUserData();
  });

  //Get user search data
  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    Utils.handleInputSearch(lowerCase).then((text) => {
      setInputText(text);
    });
  };

  //Handle sorting
  const sortHandler = (val) => {
    if (sortDirection) {
      setSortVal(`${val}:asc`);
      setSortDir(false);
    } else {
      setSortVal(`${val}:desc`);
      setSortDir(true);
    }
  };

  //Columns
  const columns = React.useMemo(
    () => [
      {
        Header: t('FORMS.CompanyNameLabel'),
        accessor: 'companyName',
      },
      {
        Header: t('FORMS.VatLabel'),
        accessor: 'vatNumber',
      },
      {
        Header: t('FORMS.AsnLabel'),
        accessor: 'asNumber',
      },
      {
        Header: t('FORMS.ReferenceEmailLabel'),
        accessor: 'referenceEmail',
      },
      {
        Header: t('FORMS.LocationLabel'),
        accessor: 'city',
      },
      {
        Header: ' ',
        accessor: 'id',
        Cell: ({ row }) => {
          return (
            <div className="tabIcon">
              {/* <span onClick={() => { render(<ClientDetails details={row.original}/>) }}> 
                  <Icon>edit_note</Icon>
                </span> */}
              {row.original.id != null ? (
                <Link
                  to={{
                    pathname: '/clients/newclient',
                    state: { client: row.original },
                  }}>
                  <OverlayTrigger overlay={<Tooltip>{t('TOOLTIPS.Edit')}</Tooltip>}>
                    <Icon>edit_note</Icon>
                  </OverlayTrigger>
                </Link>
              ) : null}
            </div>
          );
        },
      },
    ],
    [],
  );

  if (listData == '') {
    const data = [
      {
        companyName: t('OTHERS.NoDataMessage'),
        vatNumber: '',
        referenceEmail: '',
        city: '',
        id: null,
      },
    ];

    setData(data);
  }

  if (listData == '')
    return (
      <Container fluid>
        <Row>{t('OTHERS.NoDataMessage')}</Row>
      </Container>
    );

  return (
    <Container fluid>
      <Row className="clientList">
        {listData.length && (
          <DataTable
            columns={columns}
            data={listData}
            heading={true}
            searchBar={true}
            numofData={numofData}
            tableHeading={t('CUSTOMER.TableHeading')}
            searchMessage={t('OTHERS.Search')}
            filterButton={false}
            downloadButton={false}
            newValueButton={true}
            newValueText={t('CUSTOMER.AddLabel')}
            newValueLink="/clients/newclient"
            inputHandler={inputHandler}
            fetchData={fetchData}
            pageCount={pageCount}
            sortHandler={sortHandler}
            sortValue={sortVal}
            inputText={inputText}
            paginationItemsStartingPoint={ConfigHandler.getConfig().datatables.defaultStartingPoint}
            paginationItemsCount={ConfigHandler.getConfig().datatables.defaultPaginationCount}
          />
        )}
      </Row>
    </Container>
  );
}

export default ClientList;
