import React, { useState, useEffect } from 'react';
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { RestService } from '../utils';
import { AuthHandler } from '../utils/AuthHandler';
import Spinner from '../components/Spinner';
import ConfigHandler from '../config';
import { useTranslation } from 'react-i18next';

import '../scss/views/managepricebooks.scss'
import { toast } from 'react-toastify';

function UploadPricebook() {
    const location = useLocation();
    const { t } = useTranslation();
    const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    

    /**
     * When the user selects a file, the file is added to a FormData object, and the FormData object is
     * added to the state of the component.
     * @param event - the event that triggered the changeHandler
     */
    const changeHandler = async (event) => {
        setIsFilePicked(true);
        var form = new FormData();
        form.append("file", event.currentTarget.files[0]);
        (location.state.uploadPartnerPricebook)&&(form.append("partnerId", location.state.uploadPartnerPricebook.id))
        setSelectedFile(form);
        setValidated(true);
	};

	/**
     * It takes a file, sends it to the server, and then displays a success message if the server responds
     * with a 200 status code.
     * @param event - The event that triggered the function
     */
    
    const handleSubmission = (event) => {
        event.preventDefault();
        setIsUploading(true);
        event.stopPropagation();
    
        if(validated && isFilePicked && AuthHandler.isAuth()){
        var userToken = AuthHandler.getToken();
            fetch(`${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.pricebookUpload}`, {
                method: 'POST',
                headers: {
                'Authorization': userToken
                },
                body: selectedFile,
            }).then(response =>
                response.json().then(data => ({
                    data: data,
                    status: response.status
                })
            ).then(res => {
                setTimeout(() => {
                    if(res.status == 200){
                        toast.success("Pricebook Uploaded");
                        document.getElementById("uploadCsv").reset()
                    } else {
                        (res.data?.reason) ? (toast.error(res.data.reason)) : (toast.error(t('NOTIFICATIONS.NotificationSomethingWrong')));
                        document.getElementById("uploadCsv").reset()
                    }
                    setIsUploading(false)
                }, 1000);
            })).catch((error) => {
                console.error(error);
            })
        }
        
	};


    return(
        <Container fluid>
            {
                (isUploading)&&(<Spinner />)
            }
            <Row className='mb-5'>
                <Col md={8}>
                    {
                        location.state && location.state.uploadPartnerPricebook != null
                            ? <h3 className='fadedText'>  {t('PRICEBOOK.COMPANY.UploadPricebookOf', {companyName: location.state.uploadPartnerPricebook.name})}</h3>
                            : <h3 className='fadedText'> {t('PRICEBOOK.UploadMessage')}</h3>
                    }      
                </Col>
                <Col md={4}>
                    <a href={`${process.env.PUBLIC_URL}/FiberTelecom_Pricebook_Template.csv`} download="FT_Pricebook_Template.csv">
                        <button className='fadedBorderButton p-2 float-end'> Download Template </button>
                    </a>
                </Col>
            </Row>
            <Row className="uploadZone">
                <Col md={12}>
                    <Form noValidate validated={validated} onSubmit={handleSubmission} id="uploadCsv" className="px-3 mx-3 CompanyProfile">
                        <Form.Group controlId="formFileLg" className="mb-3">
                            <Form.Label>{t('PRICEBOOK.PricebookUploadLabel')}</Form.Label>
                            <Form.Control type="file" size="lg" name='file' onChange={changeHandler} accept=".csv"/>
                        </Form.Group>
                        <Row>
                            <Col md={6}>
                                <p>{t('PRICEBOOK.UploadMessage')}</p>
                            </Col>
                            <Col md={6}>
                                {(isFilePicked) && (
                                    <button type="submit" className='btn fadedButton float-end'>{t('PRICEBOOK.UploadButton')}</button>
                                )}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
  }

export default UploadPricebook