import { Modal, Row, Col, Badge } from 'react-bootstrap';
import React, { useState } from 'react';
import '../scss/components/Modal.scss';
import { RestService } from '../utils';
import ConfigHandler from '../config';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function ResetPasswordConfirmation({ setShowPwdModal, data, setToRedirect }) {
  const [show, setShow] = useState(true);
  const { t } = useTranslation();

  const resetPwd = () => {
    RestService.post({
      url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.resetPassword}`,
      body: {
        id: data.id
      }
    }).then((res) => {
      if (res.status > 199 && res.status < 300) {
        toast.success(`${t('RESETPASSWORD.NotificationSuccess')} ${data.username}`);
        setToRedirect(true);
        setShowPwdModal(false);
      } else {
        res.json().then((statusText) => {
          toast.error(
            <>
              {t('NOTIFICATIONS.NotificationSomethingWrong')} <br />
              <small>
                <i>{statusText.error}</i>
              </small>
            </>
          );
        });
      }
    });
  };

  return (
    <>
      <Modal
        show={show}
        fullscreen={false}
        onHide={() => {
          setShow(false);
          setShowPwdModal(false);
        }}
        className="NewQuoteConfirmModal">
        <Modal.Header closeButton>
          <span>{t('MODALS.ConfirmationHeading')}</span>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <h5 style={{ textAlign: 'center' }} className="pb-3">
                {t('RESETPASSWORD.ModalBody')} {data.name} {data.surname} ({data.username})?
              </h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <button
                className="fadedBorderButton p-2"
                onClick={() => {
                  setShow(false);
                  setShowPwdModal(false);
                }}>
                {t('MODALS.BackButton')}
              </button>
            </Col>
            <Col>
              <button className="fadedButton p-2" onClick={() => resetPwd()}>
                {t('MODALS.ProceedButton')}
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ResetPasswordConfirmation;
