import React, { useState, useEffect } from 'react';
import '../scss/views/CompanyProfile.scss';
import { Button, Form, Row, Col, InputGroup, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { RestService } from '../utils';
import ConfigHandler from '../config';
import { Redirect, useLocation, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Icon from '@mui/material/Icon';
import CompanyUsers from '../components/CompanyUsers';
import DeletePartnerModal from '../components/DeletePartnerModal';
import Utils from '../utils/Utils';
import Select from 'react-select';
import Countries from '../components/countries';
import regioni from '../assets/Regioni.json'


const CompanyProfile = (props) => {
  const [validated, setValidated] = useState(false);
  const [companyStatus, setCompanyStatus] = useState(false);
  const [isEditing, setisEditing] = useState(false);
  const [toRedirect, setToRedirect] = useState(false);
  const [accountsValues, setAccountsValues] = useState([]);
  const [selectedAccounts, setselectedAccounts] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [vatPrefix, setVatPrefix] = useState(null);
  const [vatNum, setVatNum] = useState(null);
  const [zendeskUser, setZendeskUser] = useState(null);
  const [vatChecker, setVatChecker] = useState(true);
  const [selectedRegion, setSelectedRegion] = useState(null);
  var lang = localStorage.getItem("i18nextLng");

  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (location.state != undefined) {
      (location.state.data) && (setisEditing(true));
      if (location.state.data.enabled === true) {
        setCompanyStatus(true);
      }

      if (location.state.data.salesAccountId != null) {
        setselectedAccounts(location.state.data.salesAccountId);
      }

      if(location.state.data.regionCode != null){
        var regionToSet = regioni.map(e => e.id).indexOf(location.state.data.regionCode.toString())
        setSelectedRegion(regioni[regionToSet]);
      }

      var vatPrefix = location.state.data.vatNumber.slice(0, 2);
      var vatNumber = location.state.data.vatNumber.slice(2);

      if(vatPrefix.length > 1){
        setVatPrefix({"value": vatPrefix, "label": vatPrefix});
        setVatNum(vatNumber.toString());
      }else(
        setVatNum(vatNumber)
      )
    }
  }, [location.state]);

  const onSwitchAction = () => {
    setCompanyStatus(!companyStatus);
  };

  const HandleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);

    if(selectedRegion == null){
        toast.error("Invalid Region")
    }

    try{
      Utils.checkVat(vatPrefix.value, form.vatNumber.value).then((response) => {
        var vatValidity = vatChecker ? response : true;
        var salesAccountIdValid = (form.consultant.value !== null)&&(form.consultant.value !== t('FORMS.ConsultantPlaceholder')) ? true : false;
        console.log("salesAccountIdValid: ", salesAccountIdValid);

        if (!isEditing && form.checkValidity() && vatValidity && salesAccountIdValid) {
          RestService.post({
            url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getPartners}`,
            body: {
              name: form.companyName.value,
              vatNumber: `${vatPrefix.value}${form.vatNumber.value}`,
              asNumber: form.asNumber.value,
              email: form.email.value,
              phone: form.phone.value,
              enabled: companyStatus,
              salesAccountId: form.consultant.value,
              zendeskUserId: zendeskUser,
              city: form.City.value,
              state: form.State.value,
              region: selectedRegion.it,
              country: form.Country.value,
              countryCode: form.CountryCode.value,
              street: form.Street.value,
              postalCode: form.PostalCode.value,
              referentName: form.referentName.value,
              contractNumber: form.contractNumber.value,
              regionCode: selectedRegion.id
            },
            headers: {
              'Content-Type': 'application/json',
            },
          }).then((res) => {
            if (res.status > 199 && res.status < 300) {
              toast.success(t('NOTIFICATIONS.NotificationdAdded'));
              setToRedirect(true);
            } else {
              res.json().then((statusText) => {
                toast.error(
                  <>
                    {t('NOTIFICATIONS.NotificationSomethingWrong')} <br />
                    <small>
                      <i>{statusText.error}</i>
                    </small>
                  </>,
                );
              });
            }
          });
        } else if (isEditing && form.checkValidity() && vatValidity && salesAccountIdValid) {
          RestService.put({
            url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getPartners}`,
            body: {
              id: location.state.data.id,
              name: form.companyName.value,
              vatNumber: `${vatPrefix.value}${form.vatNumber.value}`,
              asNumber: form.asNumber.value,
              email: form.email.value,
              phone: form.phone.value,
              enabled: companyStatus,
              createdBy: location.state.data.createdBy,
              editedBy: location.state.data.editedBy,
              dateInserted: location.state.data.dateInserted,
              dateEdited: location.state.data.dateEdited,
              dateDeleted: location.state.data.dateDeleted,
              deleted: location.state.data.deleted,
              salesAccountId: form.consultant.value,
              zendeskUserId: zendeskUser,
              city: form.City.value,
              state: form.State.value,
              region: selectedRegion.it,
              country: form.Country.value,
              countryCode: form.CountryCode.value,
              street: form.Street.value,
              postalCode: form.PostalCode.value,
              referentName: form.referentName.value,
              contractNumber: form.contractNumber.value,
              regionCode: selectedRegion.id
            },
            headers: {
              'Content-Type': 'application/json',
            },
          }).then((res) => {
            if (res.status > 199 && res.status < 300) {
              toast.success(`${form.companyName.value} ${t('NOTIFICATIONS.NotificationEdited')}`);
              setToRedirect(true);
            } else {
              toast.error(`${form.companyName.value} ${t('NOTIFICATIONS.NotificationNotEdited')} (${res.status})`);
            }
          });
        } else {

          if (!vatValidity){
            toast.warning(`${t('OTHERS.InvalidVATLabel')}`)
          }

          if(!salesAccountIdValid){
            toast.warning(`${t('FORMS.ConsultantPlaceholder')}`)
          }
        } 
      })
    } catch(e){
      console.error(e);
      toast.error(
        <>
          {t('NOTIFICATIONS.NotificationSomethingWrong')}
        </>,
      );
    }
  };

  const downloadPricebook = () => {
    RestService.get({ 
        url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.pricebookDownload}?partnerId=${location.state.data.id}`
      }).then((response) => {
            if(response.ok){
              return response.blob();
            } else {
              throw new Error(t('PRICEBOOK.NoCustomPricebook'))
            }
        })
        .then((res) => {
            const blobURL = window.URL.createObjectURL(res);

            var today = new Date().toISOString().slice(0, 10);
            const tempLink = document.createElement('a');
                tempLink.style.display = 'none';
                tempLink.href = blobURL;
                tempLink.setAttribute('download', `pricebook_${today}.csv`);


            if (typeof tempLink.download === 'undefined') {
                tempLink.setAttribute('target', '_blank');
            }

            tempLink.click();

            document.body.appendChild(tempLink);
            document.body.removeChild(tempLink);

            setTimeout(() => {
                window.URL.revokeObjectURL(blobURL);
            }, 100);
        }).catch(error => {
          toast.error(error.message);
        })
  };

  const getAccountsValues = () => {
    RestService.get({
      url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getAccounts}`,
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data,
        }))
        .then((res) => {
          var getAccountsValues = [];

          for (var i = 0; i < res.data.length; i++) {
            getAccountsValues.push(
              <option value={res.data[i].id} data-zendesk={res.data[i].zendeskUserId}>
                {res.data[i].name}
                {res.data[i].email != undefined && `(${res.data[i].email})`}
              </option>,
            );
          }
          setAccountsValues(getAccountsValues);
        }),
    );
  };
  useEffect(() => {
    getAccountsValues();
  }, []);

  const handleAccounts = (event) => {
    setselectedAccounts(event.target.value);
    setZendeskUser(event.target.options[event.target.selectedIndex].dataset.zendesk);
  };

  return (
    <div>
      {showDeleteModal && isEditing && (
        <DeletePartnerModal
          setShowDeleteModal={setShowDeleteModal}
          data={location.state.data}
          setToRedirect={setToRedirect}
        />
      )}
      {toRedirect && <Redirect to="/admin/managecompany" />}
      <Form noValidate validated={validated} onSubmit={HandleSubmit} className="px-3 mx-3 CompanyProfile">
      <Row>
          <Col md={6}>
            <p className="h4 CompanyProfile__MainTitle">
              {`${isEditing ? `${t('MANAGECOMPANY.EditingTitleLabel')} ${location.state.data.name}` : t('MANAGECOMPANY.TitleLabel')}`}
            </p>
          </Col>
          <Col md={6} className="item-right fadedText">
            {isEditing && (
              <Row>
                <Col>
                <span style={{ padding: '0 1.6rem 3rem 0' }}>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>{t('PRICEBOOK.COMPANY.DownloadPricebookOf', {companyName: location.state.data.name})}</Tooltip>}
                  >
                    <Icon
                      style={{
                        fontSize: '2rem',
                        display: 'inline-block',
                        verticalAlign: 'middle',
                      }}
                      onClick={() => downloadPricebook()}
                      >
                      sim_card_download
                    </Icon>
                  </OverlayTrigger>
                </span>
                  <span style={{ padding: '0 1.6rem 3rem 0' }}>
                    <Link to={{
                      pathname: '/admin/pricebookupload',
                      state: { uploadPartnerPricebook: location.state.data }
                    }}>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip>{t('PRICEBOOK.COMPANY.UploadPricebookOf', {companyName: location.state.data.name})}</Tooltip>}
                    >
                        <Icon
                          style={{
                            fontSize: '2rem',
                            display: 'inline-block',
                            verticalAlign: 'middle',
                          }}>
                          upload_file
                        </Icon>
                    </OverlayTrigger>
                      </Link>
                  </span>
                <span style={{ padding: '0 1.6rem 3rem 0' }} onClick={() => setShowDeleteModal(true)}>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>{t('MANAGECOMPANY.DeleteCompany', {companyName: location.state.data.name})}</Tooltip>}
                  >
                    <Icon
                      style={{
                        fontSize: '2rem',
                        display: 'inline-block',
                        verticalAlign: 'middle',
                      }}>
                      delete
                    </Icon>
                  </OverlayTrigger>
                </span>
                </Col>
              </Row>

            )}
          </Col>
        </Row>

        <Row className="mb-3 mx-3">
          <Form.Group as={Col} md="6" controlId="companyName">
            <Form.Label className="float-start CompanyProfile_labels">
              {t('FORMS.CompanyNameLabel')} <span className="CompanyProfile_star_symbol">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              placeholder={`${t('FORMS.CompanyNamePlaceholder')}`}
              defaultValue={isEditing ? location.state.data.name : null}
            />
            <Form.Control.Feedback type="invalid">{t('FORMS.InvalidFieldLabel')}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="contractNumber">
            <Form.Label className="float-start CompanyProfile_labels">
              {t('FORMS.ContractNumberLabel')} <span className="CompanyProfile_star_symbol">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              placeholder={`${t('FORMS.ContractNumberPlaceHolder')}`}
              defaultValue={isEditing ? location.state.data.contractNumber : null}
            />
            <Form.Control.Feedback type="invalid">{t('FORMS.InvalidFieldLabel')}</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3 mx-3">

          <Form.Group as={Col} md="6" controlId="vatNumber">
            <Row>
              <Col>
                <Form.Label className="float-start CompanyProfile_labels">
                  {t('FORMS.VatLabel')} { (vatChecker)&&(<span className="CompanyProfile_star_symbol">*</span>) }
                </Form.Label>
              </Col>
              <Col>
                <Form.Check 
                  type="switch"
                  id="custom-switch"
                  onChange={() => setVatChecker(!vatChecker)}
                  label={vatChecker ? "VAT CHECKER ON" : "VAT CHECKER OFF"}
                  className='float-end'
                  checked={vatChecker}
                />
              </Col>
            </Row>
            <InputGroup>
            <Select
              options={Countries}
              className="col-md-3"
              placeholder={'CODE'}
              value={vatPrefix}
              onChange={(e) => setVatPrefix(e)}
            />
            <Form.Control
              required={vatChecker}
              type="text"
              placeholder={`${t('FORMS.VatPlaceholder')}`}
              defaultValue={isEditing ? vatNum : null}
            />
            <Form.Control.Feedback type="invalid">
              {t('FORMS.InvalidFieldLabel')}
            </Form.Control.Feedback>
          </InputGroup>
            
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="asNumber">
            <Form.Label className="float-start CompanyProfile_labels">
              {t('FORMS.AsnLabel')}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={t('FORMS.AsnPlaceholder')}
              defaultValue={isEditing ? location.state.data.asNumber : null}
            />
            <Form.Control.Feedback type="invalid">
              {t('FORMS.InvalidFieldLabel')}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3 mx-3">
          <Form.Group as={Col} md="12" controlId="referentName">
            <Form.Label className="float-start CompanyProfile_labels">
              {t('FORMS.ReferenceNameLabel')} <span className="CompanyProfile_star_symbol">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              placeholder={t('FORMS.ReferenceNamePlaceholder')}
              defaultValue={isEditing ? location.state.data.referentName : null}
            />
            <Form.Control.Feedback type="invalid">
              {t('FORMS.InvalidFieldLabel')}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3 mx-3">
          <Form.Group as={Col} md="6" controlId="phone">
            <Form.Label className="float-start CompanyProfile_labels">
              {t('FORMS.ReferencePhoneLabel')} <span className="CompanyProfile_star_symbol">*</span>
            </Form.Label>
            <Form.Control
              required
              type="tel"
              placeholder={t('FORMS.ReferencePhonePlaceholder')}
              defaultValue={isEditing ? location.state.data.phone : null}
            />
            <Form.Control.Feedback type="invalid">
              {t('FORMS.InvalidFieldLabel')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="email">
            <Form.Label className="float-start CompanyProfile_labels">
              {t('FORMS.ReferenceEmailLabel')} <span className="CompanyProfile_star_symbol">*</span>
            </Form.Label>
            <Form.Control
              required
              type="email"
              placeholder={t('FORMS.ReferenceEmailPlaceholder')}
              defaultValue={isEditing ? location.state.data.email : null}
            />
            <Form.Control.Feedback type="invalid">
              {t('FORMS.InvalidFieldLabel')} 
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mb-3 mx-3">
          <Form.Group as={Col} controlId="consultant">
            <Form.Label className="float-start CompanyProfile_labels">
              {t('FORMS.ConsultantLabel')} <span className="CompanyProfile_star_symbol">*</span>
            </Form.Label>
            <Form.Select onChange={handleAccounts} value={selectedAccounts} required>
              <option>{t('FORMS.ConsultantPlaceholder')}</option>
              {accountsValues}
            </Form.Select>
          </Form.Group>
        </Row>

        <Row className="mb-3 mx-3">
          <Form.Group as={Col} md="5" controlId="Street">
            <Form.Label className="float-start CompanyProfile_labels">{t('FORMS.Street')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('FORMS.StreetPlaceholder')}
              defaultValue={isEditing ? location.state.data.street : null}
            />
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="City">
            <Form.Label className="float-start CompanyProfile_labels">{t('FORMS.City')}</Form.Label>
            <Form.Control type="text" placeholder={t('FORMS.CityPlaceholder')} defaultValue={isEditing ? location.state.data.city : null} />
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="PostalCode">
            <Form.Label className="float-start CompanyProfile_labels">{t('FORMS.PostalCode')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('FORMS.PostalCodePlaceholder')}
              defaultValue={isEditing ? location.state.data.postalCode : null}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3 mx-3">
          <Form.Group as={Col} md="4" controlId="Region">
            <Row>
              <Form.Label className="float-start CompanyProfile_labels">{t('FORMS.Region')} <span className="CompanyProfile_star_symbol">*</span></Form.Label> 
            </Row>
            <Row>
              <Select
                name="selectSubTag"
                id="selectSubTag"
                value={selectedRegion}
                options={regioni}
                getOptionLabel ={(option)=> option?.[`${lang}`]}
                getOptionValue ={(option)=> option.id}
                onChange={(e) => setSelectedRegion(e)}
                placeholder="Select a region"
                selectedValue={selectedRegion}
              />  
            </Row>
            {/* <Form.Control
              type="text"
              placeholder={t('FORMS.RegionPlaceholder')}
              defaultValue={isEditing ? location.state.data.region : null}
            /> */}
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="State">
            <Form.Label className="float-start CompanyProfile_labels">{t('FORMS.State')}</Form.Label>
            <Form.Control type="text" placeholder={t('FORMS.StatePlaceholder')} defaultValue={isEditing ? location.state.data.state : null} />
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="Country">
            <Form.Label className="float-start CompanyProfile_labels">{t('FORMS.Country')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('FORMS.CountryPlaceholder')}
              defaultValue={isEditing ? location.state.data.country : null}
            />
          </Form.Group>
          <Form.Group as={Col} md="2" controlId="CountryCode">
            <Form.Label className="float-start CompanyProfile_labels">{t('FORMS.CountryCode')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('FORMS.CountryCodePlaceholder')}
              defaultValue={isEditing ? location.state.data.countryCode : null}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3 mx-3">
          <Form.Label className="float-start CompanyProfile_labels">{t('FORMS.StatusLabel')}</Form.Label>
          <Form.Group as={Col} controlId="companyStatus">
            <Form.Switch
              onChange={onSwitchAction}
              label={`${companyStatus ? t('OTHERS.StatusActive') : t('OTHERS.StatusInactive')}`}
              checked={companyStatus}
            />
          </Form.Group>
        </Row>

        <div className="CompanyProfile_Button_Container">
          <Button type="submit" className="CompanyProfile_Button_Container_button">
            {t('BUTTONS.SUBMIT')}
          </Button>
        </div>
      </Form>
      {toRedirect ? <Redirect to="/admin/managecompany" /> : null}

      {isEditing && (
        <Row className="mx-3 p-5" style={{ border: 'solid 1px rgba(0, 0, 0, 0.12)', borderRadius: '20px' }}>
          <CompanyUsers companyId={location.state.data.id} />
        </Row>
      )}
    </div>
  );
};

export default CompanyProfile;
