import { createStore, applyMiddleware, combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import rootReducer from './reducers/index'
import storageSession from 'redux-persist/lib/storage/session'
import indexSession from './reducers/indexSession'

  const persistConfig = { key: 'root', storage };
  const persistSessionConfig = { key: 'loading', storage:storageSession }

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const combinedReducers = combineReducers({
    loading: persistReducer(persistSessionConfig, indexSession),
    root: persistedReducer
  });

  const Store = () => {
    let store = createStore(combinedReducers, applyMiddleware(thunk))
    let persistor = persistStore(store)
    return { store, persistor }
  }

export default Store