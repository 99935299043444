import { Button, Modal, Row, Col, Badge } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import '../scss/components/Modal.scss';
import { RestService } from '../utils';
import ConfigHandler from '../config';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function DeleteClientModal({ setShowDeleteModal, data, setToRedirect }) {
  const [show, setShow] = useState(true);
  const { t } = useTranslation();

  const deleteRecord = () => {
    RestService.delete({
      url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.registerClient}/${data.id}`
    }).then((res) => {
      if (res.status > 199 && res.status < 300) {
        toast.success(`${t('NOTIFICATIONS.NotificationDeleted')}`);
        setToRedirect(true);
        setShowDeleteModal(false);
      } else {
        res.json().then((statusText) => {
          toast.error(
            <>
              {t('NOTIFICATIONS.NotificationSomethingWrong')} <br />
              <small>
                <i>{statusText.error}</i>
              </small>
            </>
          );
        });
      }
    });
  };

  return (
    <>
      <Modal
        show={show}
        fullscreen={false}
        onHide={() => {
          setShow(false);
          setShowDeleteModal(false);
        }}
        className="NewQuoteConfirmModal">
        <Modal.Header closeButton>
          <span>{t('MODALS.ConfirmationHeading')}</span>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <p>
                {t('MODALS.DeleteLabel')} <b>{data.companyName}</b> (VAT: {data.vatNumber})?
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <button
                className="fadedBorderButton p-2"
                onClick={() => {
                  setShow(false);
                  setShowDeleteModal(false);
                }}>
                {t('MODALS.BackButton')}
              </button>
            </Col>
            <Col>
              <button className="fadedButton p-2" onClick={() => deleteRecord()}>
                {t('MODALS.ProceedButton')}
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeleteClientModal;
