import { combineReducers } from "redux";
import testReducer from './test_reducer'
import sidebar from './sidebar'
import configDataReducer from './configurations_reducer';
import filters from './filter';
import isLoading from './isLoading';

export default combineReducers({
    test: testReducer,
    sidebar: sidebar,
    filters: filters,
})