import React from 'react';
import '../scss/components/DataTables.scss';
import { Link } from 'react-router-dom';
import ConfigHandler from '../config';
import { useTranslation } from 'react-i18next';

function NotFound() {
  const { t } = useTranslation();
  
  return (
    <div className="item-center">
      <h2 className="fadedText pb-3">{t('PAGE_404.Heading')}</h2>
      <h4 className="pb-3">{t('PAGE_404.InfoText')}</h4>
      <Link to={ConfigHandler.getConfig().defaults.homepageLink}>
        <button type="button" className="fadedButton btn mb-3">
        {t('PAGE_404.ButtonLabel')}
        </button>
      </Link>
    </div>
  );
}

export default NotFound;
