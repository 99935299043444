const Test = (state = {
    count: 0
}, action) => {
    switch (action.type) {
        case 'test/increment':
            return {
                ...state,
                count: state.count + action.payload
            }
        case 'test/decrement':
            return {
                ...state,
                count: state.count - action.payload
            }
        default: return state
    }
}

export default Test